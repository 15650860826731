import * as React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddTaskIcon from "@mui/icons-material/AddTask";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Badge, Collapse, IconButton, ListSubheader, Toolbar, Typography,Avatar } from "@mui/material";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import BadgeIcon from '@mui/icons-material/Badge';
import { AuthContext } from '../../AuthContext.js';
import fds_logo from "../../Images/fdc6.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import "../AdditionalPages/Styles/Sidebar.css"
function Sidebar() {
  const { isAuthenticated, userData,userDetails,setUserDetails, setAuthData, clearAuthData } = React.useContext(AuthContext);

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickVehcle = () => {
    navigate("/VehicleModels")
    setOpen(false);
  };
  const handleClickuser = () => {
    navigate("/Users") 
    setOpen(false);
  };


  const logout = async () => {
    localStorage.clear();
    await clearAuthData()
    navigate("/");
  };

  const currentURL = window.location.href;
  const parts = currentURL.split("/");
  const lastPart = parts[parts.length - 1];
  const [profileImageLink, setprofileImageLink] = React.useState("https://mui.com/static/images/avatar/2.jpg")
  const [profileUserName, setProfileUserName] = React.useState("")

  const [time, setTime] = React.useState(new Date());



  const [stockAlert, setStockAlert] = React.useState(0)
  const [cleanStockList,setCleanStockList]=React.useState([])




// --------- Code begins
const extractModels = (stockData) => {
  const resultArray = [];

  for (const item of stockData) {
    if (item.models.trim() !== "") {
      const words = item.models.split(" ");
      resultArray.push(...words.map((word) => word.toLowerCase()));
    }
  }

  return resultArray;
};

const getAllCurrentStockList = async () => {
  const response = await axios.get(
    `https://script.googleusercontent.com/macros/echo?user_content_key=R27rhGtYzdIGon5oxkzY-rppLXWA9_159JDmu2kZSXM-_nnveJhFBIZ2Z9o_310FN1mXQf3aIRUAauyHyUwmP0yXnwaM5CGYm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnHOOD1tvdUBNp_hOhF1i9gbxoLRateUaSIABpS2ZmbVwxz-4ciWij4xw-OYEfsdrcMC9HcUnVa3XaNSXizU3TnqZ1BAInFy88Nz9Jw9Md8uu&lib=MdKhVHcarN4bRqwHHuf7rfO_p5hnRjXr_`
  );
  const models = extractModels(response.data.data);
  return models;
};

const fetchClientList = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    const models = await getAllCurrentStockList();
    const salesExecutive = localStorage.getItem('fullName');

    const result = await axios.post("/clientWaitList", {roleId:localStorage.getItem('roleId'),Name:salesExecutive ,models }, config);

    // Rest of your code
    setStockAlert(result.data.findClientWaitingDataCount)
    localStorage.setItem('StockAlert',result.data.findClientWaitingDataCount)
    setCleanStockList(result)
  } catch (error) {
    console.error(error);
  }
};




React.useEffect(() => {
  const fetchData = async () => {
    try {
      await fetchClientList();
    } catch (error) {
      console.error(error);
    }
  };

  // Only fetch data if StockAlert is empty
  if (!localStorage.getItem("StockAlert")) {
    fetchData();
  }
}, [fetchClientList]); // Pass fetchClientList as a dependency





  const goToHome=async()=>{
    navigate('/')
    }

  React.useEffect(() => {
   setprofileImageLink(localStorage.getItem('profileImg'))
   setProfileUserName(localStorage.getItem('username'))
  }, [])
  return (



    <Box
      flex={1}
      className="sideBar-main"
      p={2}
      minHeight={"95%"}
      position={"fixed"}
      mt={3}
      ml={2}
    >
      <Box sx={{ width: "100%", color: "white" }}>
        <nav aria-label="main mailbox folders">
          <List sx={{ minWidth: "15vw" }}>

{/* Profile and Account Section */}
<Box sx={{display:"flex" ,justifyContent:"space-between" }}>

          <img  className="sidebar-logo-main" src={fds_logo} onClick={goToHome} ></img>
          <IconButton
              size="large"
              aria-label="show new notifications"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
</Box>
          <Box className="sidebar-accountName">

            <Avatar
              sx={{ width:"30px",height:"30px"}}
              alt="Remy Sharp"
              src={profileImageLink}
            />
            <Typography style={{marginLeft:"20px"}}>
              Welcome, {profileUserName?profileUserName:''}
            </Typography>
          </Box>


            <Divider className="sidebar-divider" />

            <ListItem disablePadding onClick={() => { navigate("/Dashboard") }} style={
              {
                background:
                  lastPart === "Dashboard"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                width: "96%",
                margin:"5px 0px"

              }
            }
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                  <DashboardIcon sx={{ fontSize: "1.6rem" }} />
                </ListItemIcon>
                <Typography variant="body1" className="sidebar-headings">
                  Feed
                </Typography>
              </ListItemButton>

            </ListItem>

            <ListItem disablePadding onClick={() => { navigate("/Leads") }} style={
              {
                background:
                  lastPart === "Leads"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                margin:"10px 0px",
                width: "96%"

              }
            }>
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                  <PeopleAltIcon sx={{ fontSize: "1.6rem" }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1" className="sidebar-headings">
                    Leads
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding onClick={() => { navigate("/Customers") }} style={
              {
                background:
                  lastPart === "Customers"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                margin:"10px 0px",
                width: "96%"
              }
            }>
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                  <SupervisedUserCircleIcon sx={{ fontSize: "1.6rem" }} />
                </ListItemIcon>
                <Typography variant="body1" className="sidebar-headings">
                  Customers
                </Typography>              </ListItemButton>
            </ListItem>



            <ListItem disablePadding onClick={() => { navigate("/stockList") }} style={
              {
                background:
                  lastPart === "stockList"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                margin:"10px 0px",
                width: "96%"

              }
            }>
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                  <DirectionsCarIcon sx={{ fontSize: "1.6rem" }} />
                </ListItemIcon>
                <Typography variant="body1" className="sidebar-headings">
                  Stock List
                </Typography>              </ListItemButton>
            </ListItem>


            <ListItem disablePadding onClick={() => { navigate("/callLogs") }} style={
              {
                background:
                  lastPart === "callLogs"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                margin:"10px 0px",
                width: "96%"

              }
            }>
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                  <ContactPhoneIcon sx={{ fontSize: "1.6rem" }} />
                </ListItemIcon>
                <Typography variant="body1" className="sidebar-headings">
                  Call Logs
                </Typography>              </ListItemButton>
            </ListItem>

            <ListItem disablePadding onClick={() => { navigate("/taskReminder") }} style={
              {
                background:
                  lastPart === "taskReminder"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                margin:"10px 0px",
                width: "96%"

              }
            }>
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                {/* <Badge badgeContent={stockAlert||localStorage.getItem("StockAlert")} color="error"> */}
                <AssignmentTurnedInIcon sx={{ fontSize: "1.6rem" }} />
              {/* </Badge> */}
                </ListItemIcon>
                <Typography variant="body1" className="sidebar-headings">
                  Tasks Tracker
                </Typography>              </ListItemButton>
            </ListItem>

            <ListItem disablePadding onClick={() => { navigate("/clientWaitlist") }} style={
              {
                background:
                  lastPart === "clientWaitlist"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                margin:"10px 0px",
                width: "96%"

              }
            }>
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                <Badge badgeContent={stockAlert||localStorage.getItem("StockAlert")} color="error">
                <PersonSearchIcon sx={{ fontSize: "1.6rem" }} />
              </Badge>
                </ListItemIcon>
                <Typography variant="body1" style={{ fontSize: '1rem', fontWeight: "400" }}>
                  Client Waitlist
                </Typography>              </ListItemButton>
            </ListItem>

            
            


            {/* Nested list Item */}
            {/* If Not Admin ID then not showing the column */}
            {localStorage.getItem('roleId') === "af7c1fe6-d669-414e-b066-e9733f0de7a8" ? <>
                          
            <ListItem disablePadding onClick={() => { navigate("/adminPanel") }} style={
              {
                background:
                  lastPart === "adminPanel"
                    ? "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)"
                    : "transparent",
                borderRadius: "10px",
                margin:"10px 0px",
                width: "96%"

              }
            }>
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>

                <SettingsApplicationsIcon sx={{ fontSize: "1.6rem" }} />
                </ListItemIcon>
                <Typography variant="body1" style={{ fontSize: '1rem', fontWeight: "400" }}>
                  Admin
                </Typography>              </ListItemButton>
            </ListItem>
            </> : <></>}

        



          </List>
        </nav>

        <nav aria-label="secondary mailbox folders">
          <List>
            {/* Log Out */}
            <ListItem
              disablePadding
              onClick={() => {
                logout();
              }}
              sx={{ marginTop: "2vh" ,}}
              style={
                {
                  background: "#BA0021",
                  borderRadius: "10px",
                }
              }
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white" }}>
                  <LogoutIcon />
                </ListItemIcon>
                <Typography variant="body1" style={{ fontSize: '1rem', fontWeight: "400" }}>
                  Logout
                </Typography>
              </ListItemButton>
            </ListItem>


            {/* App Version */}
            <ListItem
              disablePadding
              style={{ color: "grey", marginTop: "" }}
            >
              <Typography variant="p" onClick={() => { navigate("/changeLogs") }} sx={{cursor:"pointer", "&:hover": {
      color: "white",
      // Add any other hover styles you want here
    },}}>
                 <div style={{display:"flex",gap:7,marginTop:"15px"}}>
                 <AutoAwesomeIcon size={2}/><span>Beta 3.0.0  </span>
                 </div>
              </Typography>
            </ListItem>
          </List>
        </nav>
      </Box>
    </Box>
  );
}

export default Sidebar;
