module.exports = [
    
        "Maruti",
        "Hyundai",
        "Mahindra",
        "Citroen",
        "Tata",
        "Ford",
        "Honda",
        "Kia",
        "Toyota",
        "Renaults",
        "MG",
        "Datsun",
        "Skoda",
        "Jeep",
        "Nissan",
        "BMW",
        "Volkswagen",
        "Mercedes Benz",
        "Land Rover",
        "Lamborghini",
        "Jaguar",
        "Lexus",
        "Rolls Royce",
        "Volvo",
        "Isuzu",
        "Audi",
        "Bugatti",
        "Porsche",
        "Mini",
        "Bentley",
        "Force",
        "Aston Martin",
        "Mitsubishi",
        "Maserati",
        "Ferrari",
        "Others"
      
]