import { Box, height } from "@mui/system";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DashNav from "./DashNav";
import Sidebar from "./Sidebar";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import '../../fonts/fonts.css';
import progressFunnel from "../../Images/funnel-icon.svg";
import openLeadIcon from "../../Images/open-lead-icon.svg";
import dueFollowupIcon from "../../Images/miss-call-icon.svg";
import customerIcon from "../../Images/customers.svg";
import "../AdditionalPages/Styles/Feed.css"
import { Bar, Bubble, Doughnut, Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { CircularProgress, Divider } from "@mui/material";

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Feed() {

  const [LeadsCountDayWise_RawData, setLeadsCountDayWise_RawData] = React.useState([])
const [LeadsCountDayWise_DATA, setLeadsCountDayWise_DATA] = React.useState([])
const [LeadsCountDayWise_DATALabels, setLeadsCountDayWise_DATALabels] = React.useState([])
const [leadsAverage, setleadsAverage] = React.useState(0)


const [callsCountDayWise_RawData, setCallsCountDayWise_RawData] = React.useState([])
const [callsCountDayWise_DATA, setCallsCountDayWise_DATA] = React.useState([])
const [CallsCountDayWise_DATALabels, setCallsCountDayWise_DATALabels] = React.useState([])
const [officeVisit15DaysRaw, setofficeVisit15DaysRaw] = React.useState([])
const [officeVisitCountDayWise_DATA, setofficeVisitCountDayWise_DATA] = React.useState([])
const [officeVisitCountDayWise_Labels, setofficeVisitCountDayWise_Labels] = React.useState([])
const [officeVisitAverage, setofficeVisitAverage] = React.useState(0)



const [CallsAverage, setCallsAverage] = React.useState(0)
const [openLeadsCount, setOpenLeadsCount] = React.useState(0)
const [openLeadsCountMonthWise, setOpenLeadsCountMonthWise] = React.useState(0)
const [onProgressLeadsTotalCount, setonProgressLeadsTotalCount] = React.useState(0)
const [onProgressLeadsCountThisMonth, setonProgressLeadsCountThisMonth] = React.useState(0)
const [leadsOnProgressLoader, setleadsOnProgressLoader] = React.useState(false)
const [leadsOpenLoader, setleadsOpenLoader] = React.useState(false)
const [leadsDueFollowUpsLoader, setleadsDueFollowUpsLoader] = React.useState(false)
const [totalClientsLoader, settotalClientsLoader] = React.useState(false)

const [carFacts, setcarFacts] = React.  useState([])
const [totalDueFollowupsCount, settotalDueFollowupsCount] = React.useState(0)
const [totalCustomerCumminityCount, settotalCustomerCumminityCount] = React.useState(0)

const isCarFactsSet = React.useRef(false);

function formatDates(dateStrings) {
  return dateStrings.map((dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  });
}

const fetchLeadsCountDayWise = async () => {

  function calculateAverage(numbers) {
    if (numbers.length === 0) {
      return 0; // Return 0 for an empty array to avoid division by zero
    }
  
    const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const average = sum / numbers.length;
    
    // Round the average to two decimal places
    const roundedAverage = parseFloat(average.toFixed(2));
    
    return roundedAverage;
  }

  try {
    const roleID = localStorage.getItem('roleId');
    if(roleID!="af7c1fe6-d669-414e-b066-e9733f0de7a8"){ // Secrect ID for accessing data
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/LeadsCountDayWiseSales/${salesExecutive}`, config);

      // Check if response.data.data is an array
      if (Array.isArray(response.data.data)) {
        setLeadsCountDayWise_RawData(response.data.data);
  
        // Process the data and set the state variables
        const CountLabels = response.data.data.map((item) => item.DateAdded);
        const CountValues = response.data.data.map((item) => item.DataCount);
        setLeadsCountDayWise_DATALabels(CountLabels);
        setLeadsCountDayWise_DATA(CountValues);
        setleadsAverage(calculateAverage(CountValues))
      } else {
        console.error('Response data.data is not an array:', response.data.data);
      }
    }else{
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        auth: token,
      },
    };

    const response = await axios.get('/LeadsCountDayWise', config);

    // Check if response.data.data is an array
    if (Array.isArray(response.data.data)) {
      setLeadsCountDayWise_RawData(response.data.data);
      // Process the data and set the state variables
      const CountLabels = response.data.data.map((item) => item.DateAdded);
      const CountValues = response.data.data.map((item) => item.DataCount);
      setLeadsCountDayWise_DATALabels(CountLabels);
      setLeadsCountDayWise_DATA(CountValues);
      setleadsAverage(calculateAverage(CountValues))
    } else {
      console.error('Response data.data is not an array:', response.data.data);
    }
  }
 } catch (error) {
    console.error('Error fetching data:', error);
  }
};


// ---- Get IVR CALLS GRAPH DATA 7 DAYS
const fetchCallsCountDayWise = async () => {

  function calculateAverage(numbers) {
    if (numbers.length === 0) {
      return 0; // Return 0 for an empty array to avoid division by zero
    }
  
    const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const average = sum / numbers.length;
    
    // Round the average to two decimal places
    const roundedAverage = parseFloat(average.toFixed(2));
    
    return roundedAverage;
  }

  try {
    const roleID = localStorage.getItem('roleId');
    if(roleID!="af7c1fe6-d669-414e-b066-e9733f0de7a8"){ // Secrect ID for accessing data
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/CallsCountDayWise/${salesExecutive}`, config);

      // Check if response.data.data is an array
      if (Array.isArray(response.data.data)) {
        setCallsCountDayWise_RawData(response.data.data);
  
        // Process the data and set the state variables
        const CountLabels = response.data.data.map((item) => item.DateAdded);
        const CountValues = response.data.data.map((item) => item.DataCount);
        setCallsCountDayWise_DATALabels(CountLabels);
        setCallsCountDayWise_DATA(CountValues);
        setCallsAverage(calculateAverage(CountValues))
      } else {
        console.error('Response data.data is not an array:', response.data.data);
      }
    }else{
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        auth: token,
      },
    };

    const response = await axios.get('/CallsCountDayWise', config);

    // Check if response.data.data is an array
    if (Array.isArray(response.data.data)) {
      setCallsCountDayWise_RawData(response.data.data);
      // Process the data and set the state variables
      const CountLabels = response.data.data.map((item) => item.DateAdded);
      const CountValues = response.data.data.map((item) => item.DataCount);
      setCallsCountDayWise_DATALabels(CountLabels);
      setCallsCountDayWise_DATA(CountValues);
      setCallsAverage(calculateAverage(CountValues))
    } else {
      console.error('Response data.data is not an array:', response.data.data);
    }
  }
 } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const fetchOfficeVisit15Days = async () => {
  const roleID = localStorage.getItem('roleId');
  const token = localStorage.getItem("accessToken");
  var salesExecutive = localStorage.getItem('fullName');

  function calculateAverage(numbers) {
    if (numbers.length === 0) {
      return 0; // Return 0 for an empty array to avoid division by zero
    }
  
    const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const average = sum / numbers.length;
    
    // Round the average to two decimal places
    const roundedAverage = parseFloat(average.toFixed(2));
    
    return roundedAverage;
  }


 if(roleID=="af7c1fe6-d669-414e-b066-e9733f0de7a8"){
  const config = {
    headers: {
      auth: token,
    },
  };
  const response = await axios.get(`/officeVisit/admin`, config);
  setofficeVisit15DaysRaw(response.data);
  const CountLabels = response.data.data.map((item) => item.DateAdded);
      const CountValues = response.data.data.map((item) => item.DataCount);
      setofficeVisitCountDayWise_Labels(CountLabels);
      setofficeVisitCountDayWise_DATA(CountValues);
      setofficeVisitAverage(calculateAverage(CountValues));
 }else{
  const config = {
    headers: {
      auth: token,
    },
  };
  const response = await axios.get(`/officeVisit/${salesExecutive}`, config);

  setofficeVisit15DaysRaw(response.data);
  const CountLabels = response.data.data.map((item) => item.DateAdded);
      const CountValues = response.data.data.map((item) => item.DataCount);
      setofficeVisitCountDayWise_Labels(CountLabels);
      setofficeVisitCountDayWise_DATA(CountValues);
      setofficeVisitAverage(calculateAverage(CountValues));
 }
  
   
  }


const fetchOpenLeads = async () => {


    const roleID = localStorage.getItem('roleId');

    const token = localStorage.getItem("accessToken");
    var salesExecutive = localStorage.getItem('fullName');

   if(roleID=="af7c1fe6-d669-414e-b066-e9733f0de7a8"){
    const config = {
      headers: {
        auth: token,
      },
    };
    const response = await axios.get(`/openLeads/admin`, config);
    setOpenLeadsCount(response.data.count)
   }else{
    const config = {
      headers: {
        auth: token,
      },
    };
    const response = await axios.get(`/openLeads/${salesExecutive}`, config);

    setOpenLeadsCount(response.data.count)
   }
    
     
    }
  

    const fetchOpenLeadsMonthWise = async () => {
setleadsOpenLoader(true)

      const roleID = localStorage.getItem('roleId');
  
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');
  
     if(roleID=="af7c1fe6-d669-414e-b066-e9733f0de7a8"){
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/openLeadsThisMonth/admin`, config);
      setOpenLeadsCountMonthWise(response.data.count)
      setleadsOpenLoader(false)

     }else{
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/openLeadsThisMonth/${salesExecutive}`, config);
  
      setOpenLeadsCountMonthWise(response.data.count)
      setleadsOpenLoader(false)

     }
      
       
      }
    

  const fetchOnProgressLeadsMonthWise = async () => {


      const roleID = localStorage.getItem('roleId');
  
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');
  
     if(roleID=="af7c1fe6-d669-414e-b066-e9733f0de7a8"){
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/progressLeads/admin`, config);
      setonProgressLeadsCountThisMonth(response.data.count)
     }else{
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/progressLeads/${salesExecutive}`, config);
  
      setonProgressLeadsCountThisMonth(response.data.count)
     }
      
       
      }

  const fetchOnProgressTotalLeadsFunc = async () => {
setleadsOnProgressLoader(true)

      const roleID = localStorage.getItem('roleId');
  
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');
  
     if(roleID=="af7c1fe6-d669-414e-b066-e9733f0de7a8"){
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/progressTotalLeads/admin`, config);
      setonProgressLeadsTotalCount(response.data.count)
      setleadsOnProgressLoader(false)
     }else{
      const config = {
        headers: {
          auth: token,
        },
      };
      const response = await axios.get(`/progressTotalLeads/${salesExecutive}`, config);
  
      setonProgressLeadsTotalCount(response.data.count)
      setleadsOnProgressLoader(false)
     }
      
       
      }




      const fetchCarFacts = async () => {
        if (!isCarFactsSet.current) { // Check if car facts have not been set yet
          function generateRandomNumber() {
            return Math.floor(Math.random() * 120) + 1;
          }
    
          const token = localStorage.getItem("accessToken");
          const config = {
            headers: {
              auth: token,
            },
          };
          const response = await axios.get(`/carFacts`, config);
    
          setcarFacts(response.data.data[generateRandomNumber()]);
          isCarFactsSet.current = true; // Set the flag to indicate that car facts have been set
        }
      }

      const fetchAllDueFollowUps = async () => {
        setleadsDueFollowUpsLoader(true)
        const roleID = localStorage.getItem('roleId');
        const token = localStorage.getItem("accessToken");
        var salesExecutive = localStorage.getItem('fullName');
    
       if(roleID=="af7c1fe6-d669-414e-b066-e9733f0de7a8"){
        const config = {
          headers: {
            auth: token,
          },
        };
        const response = await axios.post(`/totalDueFollowUps/admin`,[], config);
        settotalDueFollowupsCount(response.data.totalDueFollowups)
        setleadsDueFollowUpsLoader(false)

       }else{
        const config = {
          headers: {
            auth: token,
          },
        };
        const response = await axios.post(`/totalDueFollowUps/${salesExecutive}`,[], config);
    
        settotalDueFollowupsCount(response.data.totalDueFollowups)
        setleadsDueFollowUpsLoader(false)

       }
        }

        const fetchAllCustomerCount = async () => {
          settotalClientsLoader(true)
          const roleID = localStorage.getItem('roleId');
          const token = localStorage.getItem("accessToken");
          var salesExecutive = localStorage.getItem('fullName');
      
         if(roleID=="af7c1fe6-d669-414e-b066-e9733f0de7a8"){
          const config = {
            headers: {
              auth: token,
            },
          };
          const response = await axios.post(`/totalCustomersCount/admin`,[], config);
          settotalCustomerCumminityCount(response.data.totalCustomersCount)
          settotalClientsLoader(false)
         }else{
          const config = {
            headers: {
              auth: token,
            },
          };
          const response = await axios.post(`/totalCustomersCount/${salesExecutive}`,[], config);
      
          settotalCustomerCumminityCount(response.data.totalCustomersCount)
          settotalClientsLoader(false)
         }
          }

React.useEffect(() => {
  fetchLeadsCountDayWise();
  fetchCallsCountDayWise();
  fetchOfficeVisit15Days();
  fetchOpenLeads();
  fetchOpenLeadsMonthWise();
  fetchCarFacts();
  fetchOnProgressLeadsMonthWise();
  fetchOnProgressTotalLeadsFunc();
  fetchAllDueFollowUps();
  fetchAllCustomerCount();
}, []);

// This will fetch data when the component mounts
  const leadLabels = LeadsCountDayWise_DATALabels;
  const lineChartData = {
    labels: formatDates(leadLabels),
    datasets: [{
      label: "Leads Count",
      data: LeadsCountDayWise_DATA,
      fill: "false",
      borderColor: 'white',
      tension: 0,
      pointRadius:6,
      backgroundColor:"white"
     
    }],
    maxBarThickness: 100,

  };
  
// IVR Calls Chart Data
const Callslabels = CallsCountDayWise_DATALabels;
  const lineChartCallsData = {
    labels: formatDates(Callslabels),
    datasets: [{
      label: "Calls Count",
      data: callsCountDayWise_DATA,
      fill: "false",
      borderColor: 'white',
      tension: 0,
      pointRadius:5,
      backgroundColor:"white"
     
    }],
    maxBarThickness: 6,

  };



  const OfficeVisitLineChartData = {
    labels: formatDates(officeVisitCountDayWise_Labels),
    datasets: [{
      label: "OfficeVisit Count",
      data: officeVisitCountDayWise_DATA,
      fill: "false",
      borderColor: 'white',
      tension: 0,
      pointRadius:6,
      backgroundColor:"white"
     
    }],
    maxBarThickness: 100,

  };

  


  


  return (
    <>
      <Box flex={4} bgcolor="transparent" p={2} ml={"18vw"} mt={1.5}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>

          <Grid xs={12}>
              {/* <Item style={{display:"flex",alignItems:"flex-start",fontSize:"5vh"
              ,background:"linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))"
              ,color:"white"}}>Feed</Item> */}
              <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik"}} >Dashboard</div>
              <div style={{fontSize:"20px",color:"black"}} >Welcome, {localStorage.getItem('fullName')}!</div>

            </Grid>

 


 {/* ------------------- INFO CARDS ------------------- */}
<div className="feed-info-grid">
 <Grid className="feed-info-cards feed-info-card-blue"  ml={1.2}>
  <div style={{ display: "flex", alignItems: "center", padding: "10px",justifyContent:"space-between"}}>
    <img style={{ height: "7vh" }} src={progressFunnel} alt="Progress Leads Icon" />
    <div style={{ color: "white", marginLeft: "10px" }}>
      <h3>Leads On Progress</h3>
      <h1>{leadsOnProgressLoader ? <CircularProgress size="1.5rem" style={{color:"white"}} /> : (onProgressLeadsTotalCount || 0)}</h1>
    </div>
  </div>
</Grid>



<Grid   className="feed-info-cards feed-info-card-green" ml={1}>
  <div style={{ display: "flex", alignItems: "center", padding: "10px",justifyContent:"space-between" }}>
    <img style={{ height: "7vh" }} src={openLeadIcon} alt="Progress Leads Icon" />
    <div style={{ color: "white", marginLeft: "10px" }}>
      <h3>Open Leads</h3>
      <h1>{leadsOpenLoader ? <CircularProgress size="1.5rem" style={{color:"white"}} /> : (openLeadsCount || 0)}</h1>
    </div>
  </div>
</Grid>


<Grid  className="feed-info-cards feed-info-card-red" ml={1}>
  <div style={{ display: "flex", alignItems: "center", padding: "10px",justifyContent:"space-between" }}>
    <img style={{ height: "7vh" }} src={dueFollowupIcon} alt="Progress Leads Icon" />
    <div style={{ color: "white", marginLeft: "10px" }}>
      <h3>Due FollowUps</h3>
      {/* <h1>{totalDueFollowupsCount||0}</h1> */}
      <h1>{leadsDueFollowUpsLoader ? <CircularProgress size="1.5rem" style={{color:"white"}} /> : (totalDueFollowupsCount || 0)}</h1>

    </div>
  </div>
</Grid>



<Grid  className="feed-info-cards feed-info-card-grey" ml={1}>
  <div style={{ display: "flex", alignItems: "center", padding: "10px",justifyContent:"space-between" }}>
    <img style={{ height: "7vh" }} src={customerIcon} alt="Progress Leads Icon" />
    <div style={{ color: "white", marginLeft: "10px" }}>
      <h3>Clients</h3>
      <h1>{totalClientsLoader ? <CircularProgress size="1.5rem" style={{color:"white"}} /> : (totalCustomerCumminityCount || 0)}</h1>
    </div>
  </div>
</Grid>

</div>



            {/* <Grid xs={7}>
  <Item sx={{ height: "400px", overflow: "hidden" }}>
    <Card sx={{ display: "flex", flexDirection: "column",maxHeight:"100%",width:"100%" }}>
      <CardContent sx={{maxHeight:"100%"}}>
        <Line style={{ boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",borderRadius:"0.5vh" }} data={lineChartData} />

       
      </CardContent>
      <Typography variant="body2">
Sales Report         
        </Typography>
    </Card>
  </Item>
</Grid> */}
<Grid xs={4}>
<Item sx={{flexGrow:1,boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    <Box sx={{height:"100%",
    "background": "linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))",
    "boxShadow":"rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem",
    "borderRadius":"0.5rem",
    "padding":"10px"
    }}>
    <Line style={{ borderRadius:"0.5vh",padding:"3px" }} data={lineChartData} options={{
          //   plugins: {
          //     title: {
          //         display: true,
          //         text: 'Custom Chart Title'
          //     }
          // },
          plugins: {
            legend: {
              display: false
            }
          },
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)',
          },
          
          scales: {
            x: {
              ticks: {
                color: 'white' // add this line
              },
              grid: {
                color: 'transparent',
              }
            },
            y: {
              ticks: {
                color: 'white' // add this line
              },
              grid: {
                color: '#D3D3D3',
                lineWidth: 0.3 // add this line
              }

            },
          }
    
          }}
    />

    </Box>
    <Box>
      <Typography ml={1} mt={2} sx={{display:"flex",alignItems:"flex-start",color:"#202022",fontFamily: 'Poppins, sans-serif',fontWeight:"500"}} >Lead Count - 15 Days</Typography>
    <Typography ml={1} className="feed-graph-desc">Track your leads' performance over the last 15 Days
</Typography>
    </Box>
    <Divider sx={{margin:"10px 20px"}} />
    <Box ml={1}sx={{alignItems:"center",display:"flex"  }}><span style={{color:"green",fontSize:"20px",fontFamily: 'Poppins, sans-serif',marginRight:"10  px"}}>{leadsAverage}</span>
    <span style={{ fontWeight: "300" }}>&nbsp; Lead Average: 15 Days</span>
    </Box>
  </Item>
</Grid>


<Grid xs={4}>
<Item sx={{flexGrow:1,boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    <Box sx={{height:"100%",
  "background": "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
    "boxShadow":"rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem",
    "borderRadius":"0.5rem",
    "padding":"10px"
  }}>
    <Line style={{ borderRadius:"0.5vh",padding:"3px" }} data={lineChartCallsData}  options={{
          //   plugins: {
          //     title: {
          //         display: true,
          //         text: 'Custom Chart Title'
          //     }
          // },
          plugins: {
            legend: {
              display: false
            }
          },
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)',
          },
          
          scales: {
            x: {
              ticks: {
                color: 'white' // add this line
              },
              grid: {
                color: 'transparent',
              }
            },
            y: {
              ticks: {
                color: 'white' // add this line
              },
              grid: {
                color: '#D3D3D3',
                lineWidth: 0.3 // add this line
              }

            },
          }
    
          }}
    />

    </Box>
    <Box>
      <Typography ml={1} mt={2} sx={{display:"flex",alignItems:"flex-start",color:"#202022",fontFamily: 'Poppins, sans-serif',fontWeight:"500"}} >Calls Metrics - 15 Days</Typography>
    <Typography ml={1} className="feed-graph-desc">Track your Calls' performance over the last 15 Days
</Typography>
    </Box>
    <Divider sx={{margin:"10px 20px"}} />
    <Box ml={1}sx={{alignItems:"center",display:"flex"  }}><span style={{color:"green",fontSize:"20px",fontFamily: 'Poppins, sans-serif',marginRight:"10  px"}}>{CallsAverage}</span>
    <span style={{ fontWeight: "300" }}>&nbsp; Calls Average: 15 Days</span>
    </Box>
  </Item>
</Grid>



<Grid xs={4} >

  <Item sx={{flexGrow:1,boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
    <Box sx={{height:"100%",
    "background": "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
    "boxShadow":"rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem",
    "borderRadius":"0.5rem",
    "padding":"10px"
    }}>
    <Line style={{ borderRadius:"0.5vh",padding:"3px" }} data={OfficeVisitLineChartData} options={{
          //   plugins: {
          //     title: {
          //         display: true,
          //         text: 'Custom Chart Title'
          //     }
          // },
          plugins: {
            legend: {
              display: false
            }
          },
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)',
          },
          
          scales: {
            x: {
              ticks: {
                color: 'white' // add this line
              },
              grid: {
                color: 'transparent',
              }
            },
            y: {
              ticks: {
                color: 'white' // add this line
              },
              grid: {
                color: '#D3D3D3',
                lineWidth: 0.3 // add this line
              }

            },
          }
    
          }}
    />

    </Box>
    <Box>
      <Typography ml={1} mt={2} sx={{display:"flex",alignItems:"flex-start",color:"#202022",fontFamily: 'Poppins, sans-serif',fontWeight:"500"}} >Customer Visit - 15 Days</Typography>
    <Typography ml={1} className="feed-graph-desc">Track your visits' performance over the last 15 Days
</Typography>
    </Box>
    <Divider sx={{margin:"10px 20px"}} />
    <Box ml={1}sx={{alignItems:"center",display:"flex"  }}><span style={{color:"green",fontSize:"20px",fontFamily: 'Poppins, sans-serif',marginRight:"10  px"}}>{officeVisitAverage}</span>
    <span style={{ fontWeight: "300" }}>&nbsp; Customers Visit Average: 15 Days</span>
    </Box>
  </Item>
</Grid>








            <Grid xs={12} mt={4}>
              <Item sx={{
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", margin: "0", padding: "0", minHeight: "108px", backgroundColor: ""
              }}>
                <div style={{
                  borderRadius: "5px",
                }}>
                  <div style={{ padding: "20px", borderLeft: "10px solid #267CEA", textAlign: "start" }}>
                  <h4 style={{ fontSize: "25px", color: "#4a4a4a" }}>
  {carFacts && carFacts.title && carFacts.title.length > 0 ? carFacts.title : ""}
</h4>
                    <p style={{ color: "grey" }}>{carFacts && carFacts.desc && carFacts.desc.length > 0 ? carFacts.desc : ""}</p>
                  </div>
                </div>
              </Item>
            </Grid>

           







            {/* <Grid xs={4}>
              <Item>      Item
</Item>
            </Grid>
            <Grid xs={8}>
              <Item>xs=8</Item>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
export function BarChart({ chartData }) {
  return <Bar data={chartData} />;
}
export default Feed;
