import { Box, Stack } from "@mui/system";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DashNav from "./DashNav";
import Sidebar from "./Sidebar";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { DataGrid, GridRowsProp, GridColDef,GridToolbar } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import Modal from '@mui/material/Modal';
import { Chip, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import axios from "axios";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { toast,ToastContainer } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



const carBrandNames = require("../AdditionalPages/VehicleBrands.js")

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: "black",
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '59%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'white',
  border: '1px solid grey',
  color:"white",
  boxShadow: 24,
  borderRadius:"2vh",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  p: 3,
};

const customerModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '59%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'white',
  border: '1px solid grey',
  color:"white",
  boxShadow: 24,
  borderRadius:"2vh",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  p: 3,
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius:"10px",
    '& .wrap-text': {
      whiteSpace: 'normal',
    },
    '& .css-1rtnrqa':{
    color:"white"
  },
  '& .css-68pk0f':{
    border: '1px solid white !important',
    borderRadius: '5px',
    padding:'2px 5px',
  },
  '& .css-zrqpq1':{
    color:'white'
  },
  '& .css-zrqpq1::after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
    borderBottom:"none"
  },

  
  border: 0,
  '& .MuiDataGrid-cell:focus': {
   outline:"none"
  },
  '& .css-1j9kmqg-MuiDataGrid-toolbarContainer':{
    gap:15,
    padding :"8px 8px 8px",
    background:"linear-gradient(to right, #434343 0%, black 100%)",
    borderRadius:"10px 10px 0px 0px",
  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
    color:"white"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
  },
  '& .css-1w6wuh5':{
    padding: '8px !important',
      background: 'linear-gradient(to right, rgb(67, 67, 67) 0%, black 100%) !important',
      borderRadius: '10px 10px 0px 0px !important'
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
    border:"1px solid white !important",
    borderRadius:"5px",
    padding:"2px 5px",
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus":{
    outline:"none"
  },
  "& .MuiDataGrid-cell:focus-within":{
    outline:"none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
    outline:"none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
    fontSize:"17px"
  }
  
 
}));



const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));




function Customers() {
  return (
    <>
      {/* <DashNav /> */}
      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <CustomerComponent />
      </Stack>
    </>
  );
}




//---- customers Dynamic Component --------

const CustomerComponent = () => {

  const columns = [
//     {
//       field: "col0",
//       headerName: "Actions",
//       width:250,
//       sortable: false,
//       renderCell: (cellValues) => {
//         return (
//           <div style={{display:"flex",gap:10}}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={(event) => {
//               handleEdit(event, cellValues);
//               // alert("Edit Pressed")
//             }}
//           >
//             <EditIcon/>
//           </Button>
          
//           <Button
//           variant="contained"
//           color="error"
//           onClick={(event) => {
//             // handleClick(event, cellValues);
//             alert("Delete  Pressed")
//           }}
//         >
//           <DeleteIcon/>
//         </Button>
//         <Button
//           variant="contained"
//           color="success"
//           onClick={(event) => {
//             handleCustomerModal(event, cellValues);
//             const stringWithBackslashes = cellValues.row.phone;
// const normalStringID = stringWithBackslashes.substring(1, stringWithBackslashes.length - 1);


// setCustomerPhone(stringWithBackslashes)
//           }}
//         >
//           <GroupAddIcon/>
//         </Button>
//           </div>
//         );
//       }
//     },
    // { field: "lead_id", headerName: "Lead Id",sortable:false, width: 250 },
    { field: "customerName", headerName: "Customer Name",sortable:false, width: 150 },
    { field: "phone", headerName: "Contact Number",sortable:false, width: 150 },
    { field: "purchasedModel", headerName: "Purchased Model",sortable:false, width: 250, renderCell: (params) => {
      const valueFormatted = JSON.parse(params.value);
       return (
        <>
        {valueFormatted.map((value) => (
        <Chip key={value} label={value} sx={{backgroundColor:"#1a5ea3",color:"white",marginRight:"3px"}} />
      ))}
        </>
       )
      
    }, },

    { field: "salesExecutive", headerName: "Sales Executive",sortable:false, width: 150 },
    { field: "purchasedDate", headerName: "Purchased Date",sortable:false, width: 150 ,valueFormatter: (params) => {
      const milis = parseInt(params.value);
      const valueFormatted = new Date(milis).toDateString();
      return `${valueFormatted}`;
    },},
    { field: "insuranceRenewalDate", headerName: "Insurance Renewal",sortable:false, width: 150 ,valueFormatter: (params) => {
      const milis = parseInt(params.value);
      const valueFormatted = new Date(milis).toDateString();
      return `${valueFormatted}`;
    },},
    { field: "insuranceStatus", headerName: "Insurance Status",sortable:false, width: 150 },
    { field: "taxStatus", headerName: "Tax Status",sortable:false, width: 150 },
    { field: "taxRenewalDate", headerName: "Tax Renewal Date",sortable:false, width: 150 ,valueFormatter: (params) => {
      const milis = parseInt(params.value);
      const valueFormatted = new Date(milis).toDateString();
      return `${valueFormatted}`;
    },},
    
    { field: "rcTransferStatus", headerName: "RC Transfer Status",sortable:false, width: 150 },
    { field: "fullPaymentRecievedDate", headerName: "Full Payment Revcieved Date",sortable:false, width: 150 ,valueFormatter: (params) => {
      const milis = parseInt(params.value);
      const valueFormatted = new Date(milis).toDateString();
      return `${valueFormatted}`;
    },},
    { field: "customerProfile", headerName: "customerProfile",sortable:false, width: 150 },


    { field: "gender", headerName: "Gender",sortable:false, width: 150 },

    { field: "vehicleModels", headerName: "Vehicle Models",sortable:false, width: 250 ,cellClassName: 'wrap-text', renderCell: (params) => {
      const valueFormatted = JSON.parse(params.value);
       return (
        <>
        {valueFormatted.map((value) => (
        <Chip key={value} label={value} sx={{backgroundColor:"#1a5ea3",color:"white"  ,marginRight:"3px"}} />
      ))}
        </>
       )
      
    },},
    
   
    { field: "email", headerName: "Email",sortable:false, width: 250 },
    { field: "state", headerName: "State",sortable:false, width: 200 },
    { field: "city", headerName: "City",sortable:false, width: 200 },
    { field: "address", headerName: "Address",sortable:false, width: 250 },
    { field: "pincode", headerName: "Pincode",sortable:false, width: 200 },
    { field: "leadType", headerName: "Lead Type",sortable:false, width: 200 },
    { field: "leadSource", headerName: "Lead Source",sortable:false, width: 200 },
    { field: "carAvailable", headerName: "Vehicle Avaliable",sortable:false, width: 250 },
    { field: "budget", headerName: "Budget",sortable:false, width: 250 },
    { field: "dueFollowUp", headerName: "Due Follow Up",sortable:false, width: 200 },
    { field: "testDrive", headerName: "Test Drive Status",sortable:false, width: 200 },
    { field: "purchaseType", headerName: "Purchase Type",sortable:false, width: 200 },
    { field: "tradeIn", headerName: "Trade In",sortable:false, width: 150 },
    { field: "activityLog", headerName: "Activity Log",sortable:false, width: 350 },
    { field: "createdAt", headerName: "CreatedAt",sortable:false, width: 250  ,valueFormatter: (params) => {
      const valueFormatted = new Date(params.value).toDateString();
      return `${valueFormatted}`;
    },},
  
  
  
  
  
  ];



  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [customers, setCustomers] = React.useState([])
  const [isLoading, setisLoading] = React.useState(false)



const getAllCustomers = async () => {
  const roleID = localStorage.getItem('roleId');
if(roleID!="af7c1fe6-d669-414e-b066-e9733f0de7a8"){ // Secrect ID for accessing data
  setisLoading(true)

  const token = localStorage.getItem("accessToken");
  var salesExecutive = localStorage.getItem('fullName');

  const config = {
    headers: {
      auth: token,
    },
  };
   axios
    .get(`/salesCustomer/${salesExecutive}`, config)
    .then((response) => {
      setCustomers(response.data.data);
      setisLoading(false)

    });
}else{
  setisLoading(true)
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: {
      auth: token,
    },
  };
   axios
    .get("/customers", config)
    .then((response) => {
      setCustomers(response.data.data);
      setisLoading(false)

    });
};
}

React.useEffect(() => {
getAllCustomers()
}, []);

  
  return (
    <>
      <Box flex={4} color="#0F1319" p={2} ml={"18vw"} mt={0}>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={0}>
            {/* customers Top Bar */}
            <div>
              <Stack direction="row" spacing={2} justifyContent="space-between">
              <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Converted Customers</div>

                <div></div>
               
              </Stack>
            </div>

            <Stack direction="row" spacing={2} justifyContent="space-between">
             {/* <div style={{display:"flex",gap:10}}><CallMissedOutgoingIcon sx={{color:"yellowgreen"}}/>Today Due FollowUp : 10</div> */}

      </Stack>



            <div>
              <div style={{ height: "79vh", width: "100%" }}>
                <StyledDataGrid
                  rows={customers}
                  columns={columns}
                  checkboxSelection
                  disableSelectionOnClick
                  loading={isLoading}
                  components={{
                    Toolbar: GridToolbar,
                  }} 
                  style={{
                    background: "white",
                    color:"black",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"                  }} 
                  rowSpacingType="border"
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 }
                    },
                  }}
                />
              </div>
            </div>
          </Stack>
        </Box>
      </Box>

      <ToastContainer />
    </>
  );
};



export default Customers;
