import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
    Button,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import {
    DataGrid, GridToolbar, GridToolbarQuickFilter,
    GridLogicOperator,
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";

const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    borderRadius: "10px",
    '& .wrap-text': {
        whiteSpace: 'normal',
    },


    border: 0,
    '& .MuiDataGrid-cell:focus': {
        outline: "none"
    },
    '& .MuiDataGrid-toolbarContainer': {
        gap: 15,
        padding: "8px 8px 8px !important",
        background: "linear-gradient(to right, #434343 0%, black 100%) !important",
        borderRadius: "10px 10px 0px 0px !important",
    },
    '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root': {
        color: "white"
    },
    '& .css-1rtnrqa': {
        color: "white"
    },
    '& .css-68pk0f': {
        border: '1px solid white !important',
        borderRadius: '5px',
        padding: '2px 5px',
    },
    '& .css-zrqpq1': {
        color: 'white'
    },
    '& .css-zrqpq1::after': {
        borderBottom: "none"
    },
    '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after': {
        borderBottom: "none"
    },
    '& .css-v4u5dn-MuiInputBase-root-MuiInput-root': {
        color: "white"
    },
    '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter': {
        border: "1px solid white !important",
        borderRadius: "5px",
        padding: "2px 5px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
    },
    "& .MuiDataGrid-cell:focus": {
        outline: "none"
    },
    "& .MuiDataGrid-cell:focus-within": {
        outline: "none"
    },
    "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within": {
        outline: "none",
    },
    "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle": {
        fontSize: "17px"
    }


}));


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    // border: '2px solid #000',
    boxShadow: 24,

    borderRadius: "10px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"

};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};




function CarFacts(props) {
    const navigate = useNavigate();

    return (
        <>
            {/* <DashNav /> */}

            <Stack direction="row" justifyContent="space-between">
                <Sidebar />
                <CarFactsList />
                {/* <Leads/> */}
            </Stack>
        </>
    );
}
const CarFactsList = () => {

    // Datagrid Columns
    const columns = [
        {
            field: "col0",
            headerName: "Actions",
            flex: 0.5,
            sortable: false,
            filterable: false,
            renderCell: (cellValues) => {
                return (
                    <div style={{ display: "flex", gap: 10 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                handleDelete(cellValues.row.uuid)
                            }}

                        >
                            Delete
                        </Button>
                    </div>
                );
            },
        },
        {
            field: "uuid",
            sortable: false,
            headerName: "UUID",
            flex: 1,
        },
        {
            field: "title",
            sortable: false,
            headerName: "Title",
            flex: 1,
        },
        {
            field: "desc",
            sortable: false,
            headerName: "DESC ",
            flex: 3,
        }

    ];






    const [allCarFacts, setAllCarFacts] = React.useState([]);
    //isUpdating State is used for checking if the modal open is for updating or adding a new one hence changing the button name 
    const [isLoading, setisLoading] = React.useState(false)
    const [addNewFact, setAddNewFact] = React.useState({
        title: "",
        desc: "",
    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);









    function handleChange(evt) {
        const value = evt.target.value;
        setAddNewFact({
            ...addNewFact,
            [evt.target.name]: value,
        });
    }

    //------- ADD FACT FUNCTION STARTED -------

    const addNewFactFunc = async () => {
        const token = localStorage.getItem("accessToken");
        const config = {
            headers: {
                auth: token,
            },
        };

        function isInvalid(value) {
            return value === undefined || value.length === 0;
        }


        if (
            isInvalid(addNewFact.title) ||
            isInvalid(addNewFact.desc)

        ) {
            toast.error("All fields are mandatory!")
        } else {
            await axios
                .post("/carFacts", addNewFact, config)
                .then((response) => {
                    toast.success("Fact Added Successfully!");
                    getAllCarFacts()
                    setOpen(false)
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.data.error == "Fact Already Exists") {
                        toast.error("Fact Already Exists");
                    } else {
                        toast.error("Fact Cannot Be Added");
                    }
                });
        }
    };


    //------- DELETE FACT FUNCTION STARTED -------
    async function handleDelete(uuid) {

        if (window.confirm("You surely want to delete this fact?")) {
            await deleteTask(uuid)
        }      // await deleteUser(Value.row.uid) 
        else {
            await getAllCarFacts()
        }
    }

    const deleteTask = async (uuid) => {
        const token = localStorage.getItem("accessToken");
        const config = {
            headers: {
                auth: token,
            },
        };

        await axios
            .delete(`/deleteCarFact/${uuid}`, config)
            .then((response) => {

                toast.success("Fact Deleted Successfully!");
                getAllCarFacts()
                setOpen(false)
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data.error == "Fact Already Exists") {
                    toast.error("Fact Already Exists");
                } else {
                    toast.error("Fact Cannot Be Deleted");
                }
            });

    };


    //------- GET ALL FACT FUNCTION STARTED -------

    const getAllCarFacts = async () => {
        setisLoading(true)
        const token = localStorage.getItem("accessToken");
        const config = {
            headers: {
                auth: token,
            },
        };
        axios
            .get("/carFacts", config)
            .then((response) => {
                setAllCarFacts(response.data.data);
                setisLoading(false)

            });
    };

    React.useEffect(() => {
        getAllCarFacts();
    }, []);



    return (
        <>
            <Box
                sx={{ width: "80%" }}
                p={2}
                height={"95vh"}
                ml={"18vw"}
                mt={0}
            >
        <div style={{ display: "flex", justifyContent: "space-between",alignItems:"baseline" }}>
                <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Manage Car Facts</div>


                    <div style={{display:"flex",alignItems:"baseline",flexDirection:"row"}}>
                        <div></div>
                    <Button variant="contained" sx={{ width: "140px", height: "4vh",minHeight:"30px",alignItems:"center" }}
                        onClick={() => { handleOpen(true) }}
                    >Add Fact</Button>
                    </div>
                </div>
                <StyledDataGrid
                    rows={allCarFacts || []}
                    columns={columns}
                    pageSize={100}
                    disableSelectionOnClick
                    loading={isLoading}
                    components={{
                        Toolbar: GridToolbar,
                    }}

                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 }
                        },
                    }}
                    rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
                    sx={{ height: "88vh", bgcolor: "white" }}
                    style={{
                        background: "white",
                        color: "black",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                    }}
                />
            </Box>



            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{
                        color: "white",
                        background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
                        padding: "15px",
                        borderRadius: "10px 10px 0px 0px",
                        fontSize: "25px"
                    }}>
                        Add New Fact
                    </Typography>


                    <div style={{ padding: "20px", background: "#f4f4f4", borderRadius: "10px", maxWidth: "400px", margin: "0 auto" }}>
                        <TextField
                            id="outlined-basic"
                            label="Title"
                            variant="filled"
                            sx={{ width: "100%", marginBottom: "20px" }}
                            onChange={handleChange}
                            name="title"
                        />

                        <TextField
                            id="outlined-basic"
                            label="Description"
                            multiline
                            rows={4}
                            variant="filled"
                            sx={{ width: "100%", marginBottom: "20px" }}
                            onChange={handleChange}
                            name="desc"
                        />
                        <Button variant="contained" onClick={addNewFactFunc}>Add Fact</Button>
                    </div>


                </Box>
            </Modal>




            <ToastContainer />
        </>
    );
};
export default CarFacts;
