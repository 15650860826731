import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import axios from 'axios';
import { AuthContextProvider } from './AuthContext.js';

axios.defaults.baseURL="https://flywheelcars.cloud/"
// For Testing in Local Host
// axios.defaults.baseURL="http://localhost:8080"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
);


