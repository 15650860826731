import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar,GridToolbarQuickFilter,
  GridLogicOperator,
 } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";

const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius:"10px",
    '& .wrap-text': {
      whiteSpace: 'normal',
    },

  
  border: 0,
  '& .MuiDataGrid-cell:focus': {
   outline:"none"
  },
  '& .MuiDataGrid-toolbarContainer':{
    gap:15,
    padding :"8px 8px 8px !important",
    background:"linear-gradient(to right, #434343 0%, black 100%) !important",
    borderRadius:"10px 10px 0px 0px !important",
  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
    color:"white"
  },
  '& .css-1rtnrqa':{
    color:"white"
  },
  '& .css-68pk0f':{
    border: '1px solid white !important',
    borderRadius: '5px',
    padding:'2px 5px',
  },
  '& .css-zrqpq1':{
    color:'white'
  },
  '& .css-zrqpq1::after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
    border:"1px solid white !important",
    borderRadius:"5px",
    padding:"2px 5px",
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus":{
    outline:"none"
  },
  "& .MuiDataGrid-cell:focus-within":{
    outline:"none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
    outline:"none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
    fontSize:"17px"
  }
  
 
}));



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid ",
  boxShadow: 24,
  p: 4,
};



function StockList(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <CarModels />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}
const CarModels = () => {

    // Datagrid Columns
    const columns = [
        {
          field: "models",
          sortable: false,
          headerName: "MODEL",
          flex:2,
        },
        {
            field: "year",
            sortable: false,
            headerName: "YEAR",
            flex:1,
          },
          {
            field: "regNo",
            sortable: false,
            headerName: "REGISTRATION ",
            flex:1,
          },
          {
            field: "kms",
            sortable: false,
            headerName: "DRIVEN",
            flex:1,
          },
          {
            field: "color",
            sortable: false,
            headerName: "COLOR",
            flex:1,
          },
          {
            field: "price",
            sortable: false,
            headerName: "ASKING PRICE",
            flex:1,
          },
          {
            field: "fuel_type",
            sortable: false,
            headerName: "FUEL TYPE",
            flex:1,
          },

      ];
      





  const [stockList, setStockList] = React.useState([]);
  //isUpdating State is used for checking if the modal open is for updating or adding a new one hence changing the button name 
  const [IsUpdating, setIsUpdating] = React.useState(false)
  const [isLoading, setisLoading] = React.useState(false)
  const [newModel, setNewModel] = React.useState({
    brand: "",
    modelname: "",
    year:"",
    regNum:"",
    color:"",
    askingPrice:"",
    status:""

  });
  const [vehicleBrandName, setvehicleBrandName] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);







  
  const handleBrandChange = (event) => {
    const {
      target: { value },
    } = event;
    setvehicleBrandName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    handleChange(event);
  };


  function handleChange(evt) {
    const value = evt.target.value;
    setNewModel({
      ...newModel,
      [evt.target.name]: value,
    });
  }


   const getAllStockList = async () => {
            setisLoading(true)
            
             axios
              .get("https://script.googleusercontent.com/macros/echo?user_content_key=R27rhGtYzdIGon5oxkzY-rppLXWA9_159JDmu2kZSXM-_nnveJhFBIZ2Z9o_310FN1mXQf3aIRUAauyHyUwmP0yXnwaM5CGYm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnHOOD1tvdUBNp_hOhF1i9gbxoLRateUaSIABpS2ZmbVwxz-4ciWij4xw-OYEfsdrcMC9HcUnVa3XaNSXizU3TnqZ1BAInFy88Nz9Jw9Md8uu&lib=MdKhVHcarN4bRqwHHuf7rfO_p5hnRjXr_")
              .then((response) => {
                setStockList(response.data.data);
                setisLoading(false)

              });
  };

  React.useEffect(() => {
    getAllStockList();
  }, []);


const filteredStockRmovedTopData = stockList.slice(9)



function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const filteredStockList = filteredStockRmovedTopData.map(item => ({
  ...item,
  id: uuid() // Generate a unique ID using uuidv4()
}));

  return (
    <>
      <Box
        sx={{ width: "80%" }}
        p={2}
        height={"95vh"}
        ml={"18vw"}
        mt={0}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Stock List</div>

         
        </div>
        <StyledDataGrid
          rows={filteredStockList||[]}
          columns={columns}
          pageSize={100}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
          }}
          getRowClassName={(params) =>
            // ((params.row.status).trim()=='BOOKED') ? 'highlighted-row' : ''
           {
            if((params.row.status).trim()=='BOOKED'){
              return 'highlighted-row-booked'
             }else if((params.row.status).trim()=='SOLD'){
              return 'highlighted-row-sold'
             }else{
              return ''
             }
           }
        
          }
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
          rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
          sx={{ height: "92%", bgcolor: "white" }}
          style={{
            background: "white",
            color:"black",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"                  }} 
        />
      </Box>
      






      <ToastContainer />
    </>
  );
};
export default StockList;
