import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import fds from "../../Images/fdc6.png";

function Appbar() {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ backgroundColor: "#21252B" }}>
          <Toolbar sx={{ m: 1 }}>
            <img height={70} width={"auto"} src={fds}></img>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}

export default Appbar;
