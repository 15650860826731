import React,{useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import { AuthContext } from '../AuthContext.js';


function Protected(props) {
  const { isAuthenticated, userData, setAuthData, clearAuthData } = React.useContext(AuthContext);

    const {Component} = props
    const navigate = useNavigate()
    useEffect(() => {
      if(!userData){
            navigate('/')
      }
    }, [])
    



  return (
    <div>
        <Component/>
    </div>
  )
}

export default Protected