import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar,GridToolbarQuickFilter,
  GridLogicOperator,
  GridOverlay,
 } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";

const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius:"10px",
    '& .wrap-text': {
      whiteSpace: 'normal',
    },

  
  border: 0,
  '& .MuiDataGrid-cell:focus': {
   outline:"none"
  },
  '& .MuiDataGrid-toolbarContainer':{
    gap:15,
    padding :"8px 8px 8px !important",
    background:"linear-gradient(to right, #434343 0%, black 100%) !important",
    borderRadius:"10px 10px 0px 0px !important",
  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
    color:"white"
  },
  '& .css-1rtnrqa':{
    color:"white"
  },
  '& .css-68pk0f':{
    border: '1px solid white !important',
    borderRadius: '5px',
    padding:'2px 5px',
  },
  '& .css-zrqpq1':{
    color:'white'
  },
  '& .css-zrqpq1::after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
    border:"1px solid white !important",
    borderRadius:"5px",
    padding:"2px 5px",
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus":{
    outline:"none"
  },
  "& .MuiDataGrid-cell:focus-within":{
    outline:"none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
    outline:"none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
    fontSize:"17px"
  }
  
 
}));


function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid ",
  boxShadow: 24,
  p: 4,
};



function Users(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <UsersTable />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}


// function CustomLoadingOverlay() {
//   return (
//     <GridOverlay>
//       {/* <Skeleton variant="rect" width="100%" height="100%" /> */}
//     </GridOverlay>
//   );
// }

const UsersTable = () => {

    

  const renderCell = (params) => {
    const { field, value } = params;
    if (isLoading) {
      return <Skeleton variant="rec" width={50} height={20} />;
    } else {
      return value;
    }
  };
  // Datagrid Columns 
    const columns = [
        {
          field: "col0",
          headerName: "Actions",
          width: 220,
          sortable: false,
          filterable: false,
          renderCell: (cellValues) => {
            return (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    handleEdit(event, cellValues);
                    // alert("Edit Pressed");
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={(event) => {
                    handleDelete(event, cellValues);
                    // alert("Delete  Pressed");
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          },
        },
        // { field: "id", sortable: false, headerName: "id", width: 300,          sortable: false,
        // filterable: false, },
        {
          field: "username",
          sortable: false,
          headerName: "Username",
          width: 150,
        },
        {
          field: "role",
          sortable: false,
          headerName: "Role",
          width: 300,
          valueFormatter: (params) => {
            const valueFormatted = params.value==="af7c1fe6-d669-414e-b066-e9733f0de7a8"?"Admin":"Leads Manager";
            return `${valueFormatted}`;
          },
        },
        {
            field: "fullname",
            sortable: false,
            headerName: "Fullname",
            width: 210,
          },
          {
            field: "email",
            sortable: false,
            headerName: "Email",
            width: 210,
          },
          {
            field: "profileImg",
            sortable: false,
            headerName: "Profile Image",
            width: 270,
          },
          
        { field: "createdAt", sortable: false, headerName: "CreatedAt", width: 210,
        valueFormatter: (params) => {
          const valueFormatted = new Date(params.value).toDateString();
          return `${valueFormatted}`;
        }, },
        { field: "updatedAt", sortable: false, headerName: "UpdatedAt", width: 210 ,valueFormatter: (params) => {
          const valueFormatted = new Date(params.value).toDateString();
          return `${valueFormatted}`;
        },},
      ];
      




  const [selectedImage, setSelectedImage] = React.useState(null);
const [base64Image, setBase64Image] = React.useState(null)
  const [existingUsers, setExistingUsers] = React.useState([]);
  //isUpdating State is used for checking if the modal open is for updating or adding a new one hence changing the button name 
  const [IsUpdating, setIsUpdating] = React.useState(false)
  const [isLoading, setisLoading] = React.useState(false)
  const [newUser, setNewUser] = React.useState({
    username: "",
    password: "",
    role:"",
    fullname:"",
    email:"",
    profileImg:""
  });
  const [userRoleName, setUserRoleName] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openPwdUpdate, setOpenPwdUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
const [forgetPass, setforgetPass] = React.useState({
  username:"",
  password:""
})







  const handleOpen = () => {
    setNewUser([])
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
    setIsUpdating(false)
  };

const handleForgetPwdOpen = (evnt) => {
  setforgetPass([])
    setOpenPwdUpdate(true)
}

const handlePwdUpdateClose =() =>{
  setOpenPwdUpdate(false)

}

function handlePwdChange(evt) {
  const value = evt.target.value;
  setforgetPass({
    ...forgetPass,
    [evt.target.name]: value,
  });
}

const  updatePasswordFunc= async () => {
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: {
      auth: token,
    },
  };
  await axios
    .put("/user-pwd-update", forgetPass, config)
    .then((response) => {

      toast.success("Password Updated Successfully!");
      setOpenPwdUpdate(false)
      setforgetPass([])
    })
    .catch((err) => {
      console.log(err);
      if (err.response.data.error == "User Does Not Exists") {
        toast.error("User Does Not Exists");
      } else {
        toast.error("Password Cannot Be Updated");
      }
    }); 
}


  const handleBrandChange = (event) => {
    const {
      target: { value },
    } = event;
    setUserRoleName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    handleChange(event);
  };


  function handleChange(evt) {
    const value = evt.target.value;
    setNewUser({
      ...newUser,
      [evt.target.name]: value,
    });
  }

  function handleEdit(Event,Value){

    setOpen(true)
    setNewUser({
        username:Value.row.username,
        role:Value.row.role,
        fullname:Value.row.fullname,
        email:Value.row.email,
        profileImg:Value.row.profileImg,
    })
    setIsUpdating(true)
  }

     async function handleDelete(Event,Value){

        if (window.confirm("You surely want to delete this User?")) {
          await deleteUser(Value.row.username) 
        }      // await deleteUser(Value.row.uid) 
        else{
          await getAllUsers()
        }
     }


   const getAllUsers = async () => {
            setisLoading(true)
            const token = localStorage.getItem("accessToken");
            const config = {
              headers: {
                auth: token,
              },
            };
             axios
              .get("/users", config)
              .then((response) => {
                  setExistingUsers(response.data.data);
                setisLoading(false)
              });
  };
  React.useEffect(() => {
    getAllUsers();
  }, []);

  const addNewUser = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .post("/user-register", newUser, config)
      .then((response) => {

        toast.success("User Registered Successfully!");
        setOpen(false)
        setUserRoleName([])
        getAllUsers()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "User Already Exists") {
          toast.error("User Already Exists");
        } else {
          toast.error("User Cannot Be Added");
        }
      });
  };
  const updateUser = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .put("/user-update", newUser, config)
      .then((response) => {
        toast.success("User Updated Successfully!");
        setOpen(false)
        setUserRoleName([])
        getAllUsers()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "User Already Exists") {
          toast.error("User Already Exists");
        } else {
          toast.error("User Cannot Be Updated");
        }
      }); 
  }

  const deleteUser = async (username) => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .delete(`/user-delete/${username}`,  config)
      .then((response) => {
        toast.success("User Deleted Successfully!");
        setOpen(false)
        setUserRoleName([])
        getAllUsers()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "User Already Exists") {
          toast.error("User Already Exists");
        } else {
          toast.error("User Cannot Be Deleted");
        }
      }); 
  }
  return (
    <>
      <Box
        sx={{ width: "80%" }}
        p={2}
        height={"85vh"}
        ml={"18vw"}
        mt={0}
      >
        <div style={{ display: "flex", justifyContent: "space-between",alignItems:"baseline" }}>
        <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Manage Users</div>

<div>
<Button
            variant="contained"
            sx={{ height: "40px", width: "10vw", marginRight:"10px",background:"rgb(186, 0, 33)",
            '&:hover': {
              background: "rgb(160, 0, 28)", // Change this to the desired hover color
            },
          }}
            onClick={handleForgetPwdOpen}
        
          >
            Forget Password?
          </Button>
          <Button
            variant="contained"
            sx={{ height: "40px", width: "10vw" }}
            onClick={handleOpen}
          >
            Add User
          </Button>
</div>
        </div>
        <StyledDataGrid
          rows={existingUsers}
          columns={columns}
          pageSize={50}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
        
          // initialState={{
          //   filter: {
          //     filterModel: {
          //       items: [],
          //       quickFilterValues: [filterSearchInput],
          //     },
          //   },
          // }}
          rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
          sx={{ height: "90vh", bgcolor: "white" }}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ color: "black", marginBottom: "10px" }}>
           {IsUpdating?"Update New User":"Add New User"}
          </h2>

{IsUpdating?<>
    <TextField
            id="filled-basic"
            label="Username"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="username"
            onChange={handleChange}
            value={newUser.username||""}
            disabled
          />
</>:<>
<TextField
            id="filled-basic"
            label="Username"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="username"
            onChange={handleChange}
            // value={newUser.username||""}
          />
</>}
          
{IsUpdating?<>
   </>:<><TextField
            id="filled-basic"
            label="Password"
            type="password"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="password"
            onChange={handleChange}
            // value={newUser.modelname||""}
          /></>}


{IsUpdating?<>
   </>:
          <>
                      <FormControl sx={{ width: "100%", bgcolor: "#F2F8FC" ,marginTop:"20px"}}>
            <InputLabel variant="filled" id="demo-multiple-chip-label">
              User Roles
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              onChange={handleBrandChange}
              name="role"
              input={<OutlinedInput id="select-multiple-chip" />}
              MenuProps={MenuProps}
            >
              <MenuItem value={'af7c1fe6-d669-414e-b066-e9733f0de7a8'}>Admin</MenuItem>
              <MenuItem value={'bg2f2ty7-as82-jhs2-3h43-h5h324ou42u9'}>Leads Manager</MenuItem>
            </Select>
          </FormControl></>}


          <TextField
            id="filled-basic"
            label="Fullname"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="fullname"
            onChange={handleChange}
            value={newUser.fullname||""}
          />
          <TextField
            id="filled-basic"
            label="Email"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="email"
            onChange={handleChange}
            value={newUser.email||""}
          />
          <TextField
            id="filled-basic"
            label="Profile Image"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="profileImg"
            onChange={handleChange}
            value={newUser.profileImg||""}
          />


          <Box>
          {IsUpdating?<><Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={updateUser}
            >
              Update User
            </Button></>:<><Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={addNewUser}
            >
              Add User
            </Button></>}
            
          </Box>
        </Box>
      </Modal>



      <Modal
        open={openPwdUpdate}
        onClose={handlePwdUpdateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ color: "black", marginBottom: "10px" }}>
           Update Password
          </h2>

    <TextField
            id="filled-basic"
            label="Username"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="username"
            onChange={handlePwdChange}
            value={forgetPass.username||""}
          />

<TextField
            id="filled-basic"
            label="Password"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="password"
            onChange={handlePwdChange}
            value={forgetPass.password||""}
          />
<Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={updatePasswordFunc}
            >
              Update Password
            </Button>
            
        </Box>
      </Modal>



      <ToastContainer />
    </>
  );
};
export default Users;
