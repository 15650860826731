import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { Avatar, Typography } from "@mui/material";
import fds_logo from "../../Images/fdc6.png";



export default function DashNav() {
  const navigate = useNavigate();
const [profileImageLink, setprofileImageLink] = React.useState("https://mui.com/static/images/avatar/2.jpg")

React.useEffect(() => {
 setprofileImageLink(localStorage.getItem('profileImg'))
}, [])
const [time, setTime] = React.useState(new Date());

const goToHome=async()=>{
navigate('/')
}
React.useEffect(() => {
  const interval = setInterval(() => {
    setTime(new Date());
  }, 1000);
  return () => clearInterval(interval);
}, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "#21252B", position: "fixed  !important" }}
      >
        <Toolbar>
          <img height={50} src={fds_logo} style={{cursor:"pointer"}} onClick={goToHome} ></img>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton
              size="small"
              aria-label="show new notifications"
              color="inherit"
            >
              {time.toLocaleTimeString('en-US', { hour12: true })}

            </IconButton>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>



            <IconButton
              size="large"
              aria-label="show new notifications"
              color="inherit"
            >
              <Badge badgeContent={1} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Avatar
              sx={{ marginLeft: "15px" }}
              alt="Remy Sharp"
              src={profileImageLink}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
