import { Box, Stack } from "@mui/system";
import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DashNav from "./DashNav";
import Sidebar from "./Sidebar";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import Modal from '@mui/material/Modal';
import { Autocomplete, Backdrop, Chip, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import axios from "axios";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { toast, ToastContainer } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddchartIcon from '@mui/icons-material/Addchart';
import { AuthContext } from '../../AuthContext.js';
import { ContactPhone, EditAttributes, Menu, PhoneCallback } from "@mui/icons-material";
const { DateTime } = require('luxon');


const carBrandNames = require("../AdditionalPages/VehicleBrands.js")

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  color: "black",
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '59%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'white',
  border: '1px solid grey',
  color: "white",
  boxShadow: 24,
  borderRadius: "2vh",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  p: 3,
};

const customerModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '59%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'white',
  border: '1px solid grey',
  color: "white",
  boxShadow: 24,
  borderRadius: "2vh",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
  p: 3,
};


const customStyleTask = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  // border: '2px solid #000',
  boxShadow: 24,

  borderRadius: "10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"

};
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius: "10px",
  '& .wrap-text': {
    whiteSpace: 'normal',
  },
  '& .highlighted-row': {
    background: '#2BC0E4',  /* fallback for old browsers */
    background: '-webkit-linear-gradient(to right, #EAECC6, #2BC0E4)',  /* Chrome 10-25, Safari 5.1-6 */
    background: 'linear-gradient(to right, #EAECC6, #2BC0E4)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  },


  border: 0,
  '& .MuiDataGrid-cell:focus': {
    outline: "none"
  },
  '& .css-1j9kmqg-MuiDataGrid-toolbarContainer': {
    gap: 15,
    padding: "8px 8px 8px",
    background: "linear-gradient(to right, #434343 0%, black 100%)",
    borderRadius: "10px 10px 0px 0px",

  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root': {
    color: "white"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root': {
    color: "white"
  },
  '& .css-1rtnrqa': {
    color: "white"
  },
  '& .css-68pk0f': {
    border: '1px solid white !important',
    borderRadius: '5px',
    padding: '2px 5px',
  },
  '& .css-1w6wuh5': {
    padding: '8px !important',
    background: 'linear-gradient(to right, rgb(67, 67, 67) 0%, black 100%) !important',
    borderRadius: '10px 10px 0px 0px !important'
  },
  '& .css-zrqpq1': {
    color: 'white'
  },
  '& .css-zrqpq1::after': {
    borderBottom: "none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after': {
    borderBottom: "none"
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter': {
    border: "1px solid white !important",
    borderRadius: "5px",
    padding: "2px 5px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none"
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle": {
    fontSize: "17px"
  }


}));


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#a5a5a5",
  "&:hover": {
    backgroundColor: "gray",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));


const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  color: "inherit",
 "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
  borderColor:"grey"
 }
}));

const AdvReportStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "white",
  boxShadow: 24,
  color: "Black !important",
  borderRadius: "10px",
  padding: "0px 0px 10px 0px"
};

function Leads() {
  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <LeadComponent />
      </Stack>
    </>
  );
}




//---- Leads Dynamic Component --------

const LeadComponent = () => {

  const columns = [
    {
      field: "col0",
      headerName: "Actions",
      width: 250,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => {
                handleEdit(event, cellValues);
              }}
            >
              <EditIcon />
            </Button>

            {localStorage.getItem("roleId") ==
              "af7c1fe6-d669-414e-b066-e9733f0de7a8" ? (
              <Button
                variant="contained"
                color="error"
                onClick={(event) => {
                  handleDelete(event, cellValues);
                }}
              >
                <DeleteIcon />
              </Button>
            ) : (
              ``
            )}
            {cellValues.row.isCustomer == "true" ? (
              <Button
                variant="contained"
                color="warning"
                onClick={(event) => {
                  setIsCustomerUpdating(true);
                  handleCustomerModal(event, cellValues);
                  const stringWithBackslashes = cellValues.row.phone;
                  setCustomerPhone(stringWithBackslashes);
                  setUpdateExistingCustomer({
                    lead_id: cellValues.row.lead_id,
                    phone: cellValues.row.phone,
                    purchasedBrand: JSON.parse(cellValues.row.purchasedBrand),
                    purchasedModel: JSON.parse(cellValues.row.purchasedModel),
                    insuranceRenewalDate: cellValues.row.insuranceRenewalDate,
                    insuranceStatus: cellValues.row.insuranceStatus,
                    taxStatus: cellValues.row.taxStatus,
                    taxRenewalDate: cellValues.row.taxRenewalDate,
                    rcTransferStatus: cellValues.row.rcTransferStatus,
                    purchasedDate: cellValues.row.purchasedDate,
                    fullPaymentRecievedDate:
                      cellValues.row.fullPaymentRecievedDate,
                    customerProfile: cellValues.row.customerProfile,
                  });
                }}
              >
                <EditAttributes />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={(event) => {
                  handleCustomerModal(event, cellValues);
                  const stringWithBackslashes = cellValues.row.phone;
                  setCustomerPhone(stringWithBackslashes);
                }}
              >
                <GroupAddIcon />
              </Button>
            )}
          </div>
        );
      }
    },
    // { field: "lead_id", headerName: "Lead Id",sortable:false, width: 250 },
    { field: "customerName", headerName: "Customer Name", sortable: false, width: 200 },
    { field: "phone", headerName: "Contact Number", sortable: false, width: 200 },

    {
      field: "vehicleModels", headerName: "Vehicle Models", sortable: false, width: 250, cellClassName: 'wrap-text', renderCell: (params) => {
        const valueFormatted = JSON.parse(params.value);
        return (
          <>
            {valueFormatted.map((value) => (
              <Chip key={value} label={value} sx={{ backgroundColor: "#1a5ea3", color: "white", marginRight: "3px" }} />
            ))}
          </>
        )

      },
    },
    { field: "leadType", headerName: "Lead Status", sortable: false, width: 200 },
    { field: "dueFollowUp", headerName: "Due Follow Up", sortable: false, width: 200 },
    { field: "gender", headerName: "Gender", sortable: false, width: 150 },
    { field: "email", headerName: "Email", sortable: false, width: 250 },
    { field: "state", headerName: "State", sortable: false, width: 200 },
    { field: "city", headerName: "City", sortable: false, width: 200 },
    { field: "address", headerName: "Address", sortable: false, width: 250 },
    { field: "pincode", headerName: "Pincode", sortable: false, width: 200 },
    {
      field: "vehicleBrands", headerName: "Vehicle Brand", sortable: false, width: 250, renderCell: (params) => {
        const valueFormatted = JSON.parse(params.value);
        return (
          <>
            {valueFormatted.map((value) => (
              <Chip key={value} label={value} sx={{ backgroundColor: "#1a5ea3", color: "white", marginRight: "3px" }} />
            ))}
          </>
        )

      },
    },
    { field: "salesExecutive", headerName: "Sales Executive", sortable: false, width: 220 },

    { field: "leadSource", headerName: "Lead Source", sortable: false, width: 200 },
    { field: "carAvailable", headerName: "Vehicle Avaliable", sortable: false, width: 250 },
    { field: "budget", headerName: "Budget", sortable: false, width: 250 },
    { field: "testDrive", headerName: "Test Drive Status", sortable: false, width: 200 },
    { field: "purchaseType", headerName: "Purchase Type", sortable: false, width: 200 },
    { field: "tradeIn", headerName: "Trade In", sortable: false, width: 150 },
    { field: "tradeInCar", headerName: "Trade In Car", sortable: false, width: 150 },
    { field: "activityLog", headerName: "Activity Log", sortable: false, width: 350 },
    {
      field: "createdAt", headerName: "CreatedAt", sortable: false, width: 250, valueFormatter: (params) => {
        const valueFormatted = new Date(params.value).toDateString();
        return `${valueFormatted}`;
      },
    },

  ];



  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [customerModalOpen, setcustomerModalOpen] = React.useState(false);
  const [purchasedDate, setPurchasedDate] = React.useState(null);
  const [insuranceRenewalDate, setInsuranceRenewalDate] = React.useState(null);
  const [taxRenewalDate, setTaxRenewalDate] = React.useState(null);
  const [fullPaymentRecievedDate, setFullPaymentRecievedDate] = React.useState(null);
  const { isAuthenticated, userData, userDetails, setUserDetails, setAuthData, clearAuthData } = React.useContext(AuthContext);
  const [isCustomerUpdating, setIsCustomerUpdating] = React.useState(false);
  const [openAddTaskModal, setOpenAddTaskModal] = React.useState(false);
  const [leadClosingReasons, setleadClosingReasons] = React.useState([]);








  const handleAddTaskModalOpen = () => setOpenAddTaskModal(true);
  const handleAddTaskModalClose = () => {
    setOpenAddTaskModal(false)
    setNewDate("")
    setNewTasks([])
  };
  const [newDate, setNewDate] = React.useState("");

  const [newTasks, setNewTasks] = React.useState(
    [
      {
        title: "",
        remindDate: "",
        desc: "",
        salesExecutive: ""
      }
    ]
  );
  function handleTaskChange(evt) {
    const value = evt.target.value;
    setNewTasks({
      ...newTasks,
      [evt.target.name]: value,
      remindDate: newDate ? JSON.stringify(newDate.ts) : "",
      salesExecutive: localStorage.getItem('username')
    });
  }
  const addNewTask = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }


    if (
      isInvalid(newTasks.title) ||
      isInvalid(newTasks.remindDate) ||
      isInvalid(newTasks.desc)

    ) {
      toast.error("All fields are mandatory!")
    } else {
      await axios
        .post("/addTask", newTasks, config)
        .then((response) => {

          toast.success("Task Added Successfully!");
          setOpenAddTaskModal(false)
          setNewDate([])
          setNewTasks([])
        })
        .catch((err) => {
          if (err.response.data.error == "Task Already Exists") {
            toast.error("Task Already Exists");
          } else {
            toast.error("Task Cannot Be Added");
          }
        });
    }
  };



  const handleClose = () => {
    setOpen(false);
    setcustomerModalOpen(false)
    setvehicleModelName([])
    setvehicleBrandName([])
    setNewLead([])
    setFullPaymentRecievedDate("")
    setInsuranceRenewalDate("")
    setPurchasedDate("")
    setTaxRenewalDate("")
    setIsUpdating(false)
    setNewCustomer([])
    setIsCustomerUpdating(false)
    setUpdateExistingCustomer([])
    setIsLeadSaving(false)
  };
  const [vehicleBrandName, setvehicleBrandName] = React.useState([]);
  const [vehicleModelName, setvehicleModelName] = React.useState([]);
  const [carModelNames, setcarModelNames] = React.useState([])
  const [leads, setleads] = React.useState([])
  const [isLoading, setisLoading] = React.useState(false)
  const [IsUpdating, setIsUpdating] = React.useState(false)
  const [TodayDueFollowUpCount, setTodayDueFollowUpCount] = React.useState(0)
  const [leadsAddedTodayCount, setLeadsAddedTodayCount] = React.useState(0)
  const [findLead, setfindLead] = React.useState("")
  const [newLead, setNewLead] = React.useState({
    customerName: "",
    phone: "",
    gender: "",
    email: "",
    salesExecutive: "",
    state: "",
    city: "",
    pincode: "",
    address: "",
    leadType: "",
    leadSource: "",
    vehicleBrands: vehicleBrandName,
    vehicleModels: vehicleModelName,
    budget: "",
    testDrive: "",
    purchaseType: "",
    tradeIn: "",
    tradeInCar: "",
    dueFollowUp: "",
    officeVisit: "",
    carAvailable: "",
    premiumCustomer: "",
    activityLog: ""
  })
  const [customerPhone, setCustomerPhone] = React.useState("")
  const [newCustomer, setNewCustomer] = React.useState({
    lead_id: "",
    purchasedBrand: vehicleBrandName,
    purchasedModel: vehicleModelName,
    purchasedDate: "",
    insuranceRenewalDate: "",
    insuranceStatus: "",
    taxStatus: "",
    taxRenewalDate: "",
    rcTransferStatus: "",
    fullPaymentRecievedDate: "",
    isCustomer: 'true',
    customerProfile: "",
  })

  const [updateExistingCustomer, setUpdateExistingCustomer] = React.useState({
    lead_id: "",
    purchasedBrand: vehicleBrandName,
    purchasedModel: vehicleModelName,
    purchasedDate: "",
    insuranceRenewalDate: "",
    insuranceStatus: "",
    taxStatus: "",
    taxRenewalDate: "",
    rcTransferStatus: "",
    fullPaymentRecievedDate: "",
    isCustomer: 'true',
    customerProfile: "",
  })

  const [salesExecutiveName, setSalesExecutiveName] = React.useState('');
  const [salesExecutiveleadsReport, setSalesExecutiveLeadsReport] = React.useState('');
  const [salesExecutiveDueFollowUpleadsReport, setsalesExecutiveDueFollowUpleadsReport] = React.useState('');

  const [openAvanceLeadReportModal, setopenAvanceLeadReportModal] = React.useState(false);
  const [isLeadSaving, setIsLeadSaving] = React.useState(false);



  const handleAdvanceReportModalOpen = () => setopenAvanceLeadReportModal(true);
  const handleAdvanceReportModalClose = () => {
    setopenAvanceLeadReportModal(false);
    setSalesExecutiveName('')
    setSalesExecutiveLeadsReport('')
  };

  const handleLeadsSalesExecutiveReport = (event) => {
    setSalesExecutiveName(event.target.value);
  };

  const getSalesExecutiveReportFunc = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          auth: token,
        },
      };

      const response1 = await axios.get(`/leadsAddedTodaySalesExecutive/${salesExecutiveName}`, config);
      const totalLeadCount = response1.data.count.totalLeadCount;
      setSalesExecutiveLeadsReport(totalLeadCount);

      const response2 = await axios.get(`/todayDueFollowUpsSalesExecutive/${salesExecutiveName}`, config);
      const dueFollowUpCount = response2.data.todayDueFollowUp.count;
      setsalesExecutiveDueFollowUpleadsReport(dueFollowUpCount);
    } catch (error) {
      // Handle error here
      console.error("Error fetching data:", error);
    }
  };



  const handleBrandChange = (event, newValue) => {
    const updatedBrandName = typeof newValue === "string" ? newValue.split(",") : newValue
    setvehicleBrandName(updatedBrandName);
    handleChange({ target: { name: "vehicleBrands", value: updatedBrandName } })
    isCustomerUpdating ?
      handleUpdateCustomer({ target: { name: "purchasedBrand", value: updatedBrandName } })
      : handleNewCustomer({ target: { name: "purchasedBrand", value: updatedBrandName } })
  };



  const handleModelChange = (event, newValue) => {
    const {
      target: { value },
    } = event;
    const updatedModelName = typeof newValue === "string" ? newValue.split(",") : newValue
    setvehicleModelName(updatedModelName);
    handleChange({ target: { name: "vehicleModels", value: updatedModelName } })
    // 
    // handleUpdateCustomer({target: {name: "purchasedModel", value: updatedModelName}})
    isCustomerUpdating ?
      handleUpdateCustomer({ target: { name: "purchasedModel", value: updatedModelName } })
      : handleNewCustomer({ target: { name: "purchasedModel", value: updatedModelName } })
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setNewLead({
      ...newLead,
      [evt.target.name]: value,
    });
  }
  function handleFindLead(evnt) {
    const value = evnt.target.value;
    setfindLead(value)
  }
  function findOneLead() {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    try {
      axios
        .get(`/findLead/${findLead}`, config)
        .then((response) => {
          const salesExecutiveExists = response.data.data.salesExecutive
          setfindLead("")
          if (salesExecutiveExists.length > 0) {
            toast.success(`This Lead Belongs to ${salesExecutiveExists}!`);
          }
        }).catch(() => {
          setfindLead("")
          toast.success(`New Lead!`);
        })
    } catch (error) {
    }
  }
  function handleNewCustomer(evt) {
    const value = evt.target.value;
    setNewCustomer({
      ...newCustomer,
      [evt.target.name]: value,
      purchasedDate: purchasedDate ? JSON.stringify(purchasedDate.ts) : "",
      fullPaymentRecievedDate: fullPaymentRecievedDate ? JSON.stringify(fullPaymentRecievedDate.ts) : "",
      insuranceRenewalDate: insuranceRenewalDate ? JSON.stringify(insuranceRenewalDate.ts) : "",
      taxRenewalDate: taxRenewalDate ? JSON.stringify(taxRenewalDate.ts) : "",
      phone: customerPhone
    });
  }


  async function handleUpdateCustomer(evt) {
    const value = evt.target.value;

    const getCustomDatePuchase = async () => {
      if (purchasedDate) {
        return JSON.stringify(purchasedDate.ts);
      } else if (isCustomerUpdating) {
        return updateExistingCustomer.purchasedDate
      } else {
        return "";
      }
    }
    const getCustomDateRoadTax = async () => {
      if (purchasedDate) {
        return JSON.stringify(insuranceRenewalDate.ts);
      } else if (isCustomerUpdating) {
        return updateExistingCustomer.insuranceRenewalDate
      } else {
        return "";
      }
    }
    const getCustomDateTaxRenewal = async () => {
      if (purchasedDate) {
        return JSON.stringify(taxRenewalDate.ts);
      } else if (isCustomerUpdating) {
        return updateExistingCustomer.taxRenewalDate
      } else {
        return "";
      }
    }
    const getCustomDateFullPaymentReceived = async () => {
      if (purchasedDate) {
        return JSON.stringify(fullPaymentRecievedDate.ts);
      } else if (isCustomerUpdating) {
        return updateExistingCustomer.taxRenewalDate
      } else {
        return "";
      }
    }
    setUpdateExistingCustomer({
      ...updateExistingCustomer,
      [evt.target.name]: value,

      purchasedDate: await getCustomDatePuchase(),
      fullPaymentRecievedDate: await getCustomDateFullPaymentReceived(),
      insuranceRenewalDate: await getCustomDateRoadTax(),
      taxRenewalDate: await getCustomDateTaxRenewal(),
      phone: customerPhone
    });
  }
  const handleCustomerModal = () => {
    setcustomerModalOpen(true)
  }
  function handleEdit(Event, Value) {
    setOpen(true)
    setNewLead({
      lead_id: Value.row.lead_id,
      customerName: Value.row.customerName,
      phone: Value.row.phone,
      gender: Value.row.gender,
      email: Value.row.email,
      salesExecutive: Value.row.salesExecutive,
      state: Value.row.state,
      city: Value.row.city,
      pincode: Value.row.pincode,
      address: Value.row.address,
      leadType: Value.row.leadType,
      leadSource: Value.row.leadSource,
      vehicleBrands: JSON.parse(Value.row.vehicleBrands),
      vehicleModels: JSON.parse(Value.row.vehicleModels),
      budget: Value.row.budget,
      testDrive: Value.row.testDrive,
      purchaseType: Value.row.purchaseType,
      tradeIn: Value.row.tradeIn,
      tradeInCar: Value.row.tradeInCar,
      dueFollowUp: Value.row.dueFollowUp,
      officeVisit: Value.row.officeVisit,
      carAvailable: Value.row.carAvailable,
      premiumCustomer: Value.row.premiumCustomer,
      activityLog: Value.row.activityLog,
      closingReason: Value.row.closingReason
        })
    setIsUpdating(true)
  }

  async function handleDelete(Event, Value) {

    if (window.confirm("You surely want to delete this Lead?")) {
      await deleteLead(Value.row.phone)
    }
    else {
      await getAllLeads()
    }
    await getAllLeads()
  }

  const deleteLead = async (uid) => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .delete(`/deleteLead/${uid}`, config)
      .then((response) => {
        toast.success("Lead Deleted Successfully!");
        setOpen(false)
        setvehicleBrandName([])
        getAllVehicleModels()
      })
      .catch((err) => {
        if (err.response.data.error == "Model Already Exists") {
          toast.error("Model Already Exists");
        } else {
          toast.error("Model Cannot Be Deleted");
        }
      });
  }

  const addNewLead = async () => {
    setIsLeadSaving(true)

    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }



    if (isInvalid(newLead.phone)) {
      toast.warn("Phone Number Missing")
      setIsLeadSaving(false)

    }
    else if ((newLead.phone).length < 10 && (newLead.phone).length > 0) {
      toast.warn("Phone Less Than 10 Digits")
      setIsLeadSaving(false)

    } else if ((newLead.phone).length > 10) {
      toast.warn("Phone cannot be greater than 10 Digits")
      setIsLeadSaving(false)

    }
    else if ((newLead.phone).length == 10) {



      if (
        isInvalid(newLead.customerName) ||
        isInvalid(newLead.leadType) ||
        isInvalid(newLead.dueFollowUp) ||
        isInvalid(newLead.gender) ||
        isInvalid(newLead.state) ||
        isInvalid(newLead.salesExecutive) ||
        isInvalid(newLead.leadSource) ||
        isInvalid(newLead.vehicleBrands) ||
        isInvalid(newLead.vehicleModels) ||
        isInvalid(newLead.carAvailable) ||
        isInvalid(newLead.budget) ||
        isInvalid(newLead.purchaseType) ||
        isInvalid(newLead.tradeIn) ||
        isInvalid(newLead.testDrive) ||
        isInvalid(newLead.activityLog)

      ) {
        setIsLeadSaving(false)

        toast.error("Please fill all required fields!");
      } else {
        const token = localStorage.getItem("accessToken");
        const config = {
          headers: {
            auth: token,
          },
        };
        await axios
          .post("/addlead", { ...newLead, lead_id: JSON.stringify(uuid()) }, config)
          .then((response) => {
            toast.success("Lead Added Successfully!");
            setIsLeadSaving(false)
            setNewCustomer([])
            setUpdateExistingCustomer([])
            setFullPaymentRecievedDate("")
            setInsuranceRenewalDate("")
            setPurchasedDate("")
            setTaxRenewalDate("")
            setOpen(false);
            try {
              axios
                .post("/newLeadNotification", {
                  "phone": "9163670431",
                  "name": newLead.customerName
                }, config)
                .then((response) => {
                })
            } catch (error) {
            }
            setvehicleModelName([])
            setvehicleBrandName([])
            setNewLead([])
            getAllLeads()
            getTodayDueFollowUps()
            getTodayLeadsAdded()
          })
          .catch((err) => {
            if (err.response.data.error == "Lead Already Exists") {
              toast.error("Lead Already Exists");
              setIsLeadSaving(false)
            } else {
              toast.error("Lead Cannot Be Added");
              setIsLeadSaving(false)
            }
          });
      }

    } else {
      toast.error("Phone is manadatory")
    }

  };

  const updateLead = async () => {
    setIsLeadSaving(true)
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }
    
    if (
      isInvalid(newLead.customerName) ||
      isInvalid(newLead.leadType) ||
      isInvalid(newLead.dueFollowUp) ||
      isInvalid(newLead.gender) ||
      isInvalid(newLead.state) ||
      isInvalid(newLead.salesExecutive) ||
      isInvalid(newLead.leadSource) ||
      isInvalid(newLead.vehicleBrands) ||
      isInvalid(newLead.vehicleModels) ||
      isInvalid(newLead.carAvailable) ||
      isInvalid(newLead.budget) ||
      isInvalid(newLead.purchaseType) ||
      isInvalid(newLead.testDrive) ||
      isInvalid(newLead.tradeIn) ||
      isInvalid(newLead.activityLog) ||
      newLead.leadType=="Closed"? newLead.closingReason==null:``

    ) {
      toast.error("Please fill all required fields!");
      setIsLeadSaving(false)

    } else {
      await axios
        .put("/updateLead", newLead, config)
        .then((response) => {
          toast.success("Leads Updated Successfully!");
          setIsLeadSaving(false)
          setOpen(false);
          setvehicleModelName([])
          setvehicleBrandName([])
          setNewLead([])
          setIsUpdating(false)
          getAllLeads()
          getTodayDueFollowUps()
          getTodayLeadsAdded()
        })
        .catch((err) => {
          if (err.response.data.error == "Lead Already Exists") {
            toast.error("Lead Already Exists");
            setIsLeadSaving(false)
          } else {
            toast.error("Lead Cannot Be Updated");
            setIsLeadSaving(false)
          }
        });
    }
  };

  const addNewCustomer = async () => {
    setIsLeadSaving(true)
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }
    if (
      isInvalid(newCustomer.purchasedBrand) ||
      isInvalid(newCustomer.purchasedModel) ||
      isInvalid(newCustomer.insuranceStatus) ||
      isInvalid(newCustomer.taxStatus) ||
      isInvalid(newCustomer.purchasedDate) ||
      isInvalid(newCustomer.insuranceRenewalDate) ||
      isInvalid(newCustomer.taxRenewalDate) ||
      isInvalid(newCustomer.fullPaymentRecievedDate) ||
      isInvalid(newCustomer.rcTransferStatus) ||
      isInvalid(newCustomer.customerProfile)

    ) {
      toast.error("Please fill all required fields!");
      setIsLeadSaving(false)
    } else {
      await axios
        .put("/addCustomer", newCustomer.lead_id && newCustomer.lead_id.length > 0 ? newCustomer : { ...newCustomer, lead_id: "", isCustomer: "true" }, config)
        .then((response) => {
          toast.success("Customer Added Successfully!");
          setIsLeadSaving(false)
          setcustomerModalOpen(false);
          setvehicleModelName([])
          setvehicleBrandName([])
          setNewLead([])
          setIsUpdating(false)
          setNewCustomer([])
          getAllLeads()
        })
        .catch((err) => {
          if (err.response.data.error == "Customer Already Exists") {
            setIsLeadSaving(true)
            toast.error("Customer Already Exists");
          } else {
            toast.error("Customer Cannot Be Updated");
            setIsLeadSaving(true)
          }
        });

    }
  };

  const UpdateCustomer = async () => {
    setIsLeadSaving(true)
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }

    if (
      isCustomerUpdating ?
        (
          isInvalid(updateExistingCustomer.purchasedBrand) ||
          isInvalid(updateExistingCustomer.purchasedModel) ||
          isInvalid(updateExistingCustomer.insuranceStatus) ||
          isInvalid(updateExistingCustomer.taxStatus) ||
          isInvalid(updateExistingCustomer.purchasedDate) ||
          isInvalid(updateExistingCustomer.insuranceRenewalDate) ||
          isInvalid(updateExistingCustomer.taxRenewalDate) ||
          isInvalid(updateExistingCustomer.fullPaymentRecievedDate) ||
          isInvalid(updateExistingCustomer.rcTransferStatus) ||
          isInvalid(updateExistingCustomer.customerProfile)
        )
        :
        (
          isInvalid(newCustomer.purchasedBrand) ||
          isInvalid(newCustomer.purchasedModel) ||
          isInvalid(newCustomer.insuranceStatus) ||
          isInvalid(newCustomer.taxStatus) ||
          isInvalid(newCustomer.purchasedDate) ||
          isInvalid(newCustomer.insuranceRenewalDate) ||
          isInvalid(newCustomer.taxRenewalDate) ||
          isInvalid(newCustomer.fullPaymentRecievedDate) ||
          isInvalid(newCustomer.rcTransferStatus) ||
          isInvalid(newCustomer.customerProfile)
        )

    ) {
      toast.error("Please fill all required fields!");
      setIsLeadSaving(false)

    } else {
      await axios
        .put("/updateCustomer", isCustomerUpdating ? updateExistingCustomer : newCustomer, config)
        .then((response) => {
          toast.success("Customer Updated Successfully!");
          setIsLeadSaving(false)
          setcustomerModalOpen(false);
          setvehicleModelName([])
          setvehicleBrandName([])
          setNewLead([])
          setIsUpdating(false)
          getAllLeads()
        })
        .catch((err) => {
          if (err.response.data.error == "Customer Does Not Exists") {
            toast.error("Customer Does Not Exists");
            setIsLeadSaving(false)
          } else {
            toast.error("Customer Cannot Be Updated");
            setIsLeadSaving(false)
          }
        });

    }
  }



  const getAllVehicleModels = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    axios
      .get("/vehiclemodels", config)
      .then((response) => {
        setcarModelNames(response.data.data);
      });
  };


  function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  const getAllLeads = async () => {
    setisLoading(true)
    const roleID = localStorage.getItem('roleId');
    if (roleID != "af7c1fe6-d669-414e-b066-e9733f0de7a8") { // Secrect ID for accessing data
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');

      const config = {
        headers: {
          auth: token,
        },
      };
      axios
        .get(`/salesLeads/${salesExecutive}`, config)
        .then((response) => {
          setleads(response.data.data);
          setisLoading(false)

        });
    } else {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          auth: token,
        },
      };
      axios
        .get("/leads", config)
        .then((response) => {
          setleads(response.data.data);
          setisLoading(false)

        });
    }



  };

  const getTodayDueFollowUps = async () => {
    const roleID = localStorage.getItem('roleId');
    if (roleID != "af7c1fe6-d669-414e-b066-e9733f0de7a8") { // Secrect ID for accessing data
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');
      const config = {
        headers: {
          auth: token,
        },
      };
      axios
        .get(`/todayDueFollowUpsSalesExecutive/${salesExecutive}`, config)
        .then((response) => {
          setTodayDueFollowUpCount(response.data.todayDueFollowUp.count);

        });
    } else {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          auth: token,
        },
      };
      await axios
        .get("/todayDueFollowUps", config)
        .then((response) => {
          setTodayDueFollowUpCount(response.data.todayDueFollowUp.count);
        });
    }
  }
  const getTodayLeadsAdded = async () => {
    const roleID = localStorage.getItem('roleId');
    if (roleID != "af7c1fe6-d669-414e-b066-e9733f0de7a8") { // Secrect ID for accessing data
      const token = localStorage.getItem("accessToken");
      var salesExecutive = localStorage.getItem('fullName');
      const config = {
        headers: {
          auth: token,
        },
      };
      axios
        .get(`/leadsAddedTodaySalesExecutive/${salesExecutive}`, config)
        .then((response) => {

          setLeadsAddedTodayCount(response.data.count.totalLeadCount);

        });
    } else {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          auth: token,
        },
      };
      await axios
        .get("/leadsAddedToday", config)
        .then((response) => {
          setLeadsAddedTodayCount(response.data.count.count);
        });
    }
  }


  function getStrToDate(timestampString) {
    const timestampMs = parseInt(timestampString);
    const dateTimeObject = DateTime.fromMillis(timestampMs);
    return dateTimeObject
  }


  const getAllReasons = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
     axios
      .get("/closingReasons", config)
      .then((response) => {
        setleadClosingReasons(response.data.data);
      });
};



  React.useEffect(() => {
    getAllVehicleModels();
    getAllLeads()
    getTodayDueFollowUps()
    getTodayLeadsAdded();
    getAllReasons()
  }, []);



  return (
    <>
      <Box flex={4} bgcolor="" p={2} ml={"18vw"} mt={0}>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={0}>
            {/* Leads Top Bar */}
            <div>
              <Stack direction="row" spacing={2} justifyContent="space-between">
              <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Leads Community</div>

                <div></div>
                <div>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Lead…"
                      inputProps={{ "aria-label": "search" }}
                      onChange={handleFindLead}
                      value={findLead}
                    />
                    <Button
                      sx={{
                        marginTop: "-2px",
                        background:
                          "linear-gradient(90deg, rgba(158,28,41,1) 0%, rgba(235,29,39,1) 100%)",
                      }}
                      variant="contained"
                      onClick={findOneLead}
                    >
                      Search
                    </Button>
                  </Search>
                </div>
              </Stack>
            </div>

            {/* Shortcut Filter Section */}
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <div style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                <div style={{ display: "flex", gap: 10, color: "#0F1319" }}>
                  <CallMissedOutgoingIcon sx={{ color: "#6d9a13" }} />
                  Due FollowUps Today: {TodayDueFollowUpCount}
                </div>
                <div style={{ display: "flex", gap: 10, color: "#0F1319" }}>
                  <AddchartIcon sx={{ color: "#8B008B" }} />
                  Leads Added Today: {leadsAddedTodayCount}
                </div>
              </div>


              <div>
                {localStorage.getItem('roleId') != "af7c1fe6-d669-414e-b066-e9733f0de7a8" ? '' : <><Button
                  variant="contained"
                  color="warning"
                  onClick={handleAdvanceReportModalOpen}
                  sx={{ marginRight: "10px" }}
                >
                  Advance Report
                </Button></>}
                <Button variant="contained" color="primary" sx={{ marginRight: "10px" }}
                  onClick={handleAddTaskModalOpen}
                >
                  <AddIcon /> Add Task
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={setOpen}
                  startIcon={<AddIcon />}
                >
                  Add New Lead
                </Button>
              </div>
            </Stack>

            <div>
              <div style={{ height: "78.5vh", width: "100%" }}>
                <StyledDataGrid
                  rows={leads}
                  columns={columns}
                  checkboxSelection
                  disableSelectionOnClick
                  loading={isLoading}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  style={{
                    background: "white",
                    color: "black",
                    border: "1px solid lightgrey",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    height:"82.5vh"
                  }}
                  rowSpacingType="border"
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  getRowClassName={(params) =>
                    (params.row.isCustomer) ? 'highlighted-row' : ''
                  }
                  sortModel={[
                    {
                      field: 'createdAt', // Replace 'timestamp' with the actual field you want to sort by
                      sort: 'desc',       // 'desc' for descending order, 'asc' for ascending order
                    }
                  ]}
                />
              </div>
            </div>
          </Stack>
        </Box>
      </Box>

      {/* New lEAD Adding Section */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            style={{
              color: "black",
              fontFamily: "poppins",
              display: "flex",
              gap: 0,
              marginBottom: "2vh",
            }}
          >
            <PersonAddAlt1Icon
              sx={{ fontSize: "4vh", color: "black", marginRight: "0.5vw" }}
            />
            {IsUpdating ? "Update Lead" : "Add New Lead"}
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={7} sx={{ margin: "1vh" }}>
              <Item>
                <h2 style={{ color: "#1a5ea3" }}>Personal Details</h2>
                <Divider
                  sx={{
                    borderBottomWidth: 2,
                    backgroundColor: "#1a5ea3",
                    marginBottom: "1vh",
                  }}
                />

                <TextField
                  sx={{
                    margin: "0.8vh",
                    width: "40%",
                    bgcolor: "#F2F8FC",
                  }}
                  name="customerName"
                  id="filled-basic"
                  label="Customer Name"
                  variant="filled"
                  onChange={handleChange}
                  value={newLead.customerName || ""}
                />
                {IsUpdating ? (
                  <TextField
                    sx={{
                      margin: "0.8vh",
                      width: "33%",
                      bgcolor: "#F2F8FC",
                    }}
                    id="filled-basic"
                    label="Contact Number"
                    variant="filled"
                    name="phone"
                    onChange={handleChange}
                    value={newLead.phone || ""}
                    disabled
                  />
                ) : (
                  <TextField
                    sx={{
                      margin: "0.8vh",
                      width: "33%",
                      bgcolor: "#F2F8FC",
                    }}
                    id="filled-basic"
                    label="Contact Number"
                    variant="filled"
                    name="phone"
                    onChange={handleChange}
                    value={newLead.phone || ""}
                  />
                )}
                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "20%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Gender
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="gender"
                    onChange={handleChange}
                    value={newLead.gender || ""}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  sx={{
                    margin: "0.8vh",
                    width: "47%",
                    bgcolor: "#F2F8FC",
                  }}
                  id="filled-basic"
                  label="Email"
                  variant="filled"
                  name="email"
                  onChange={handleChange}
                  value={newLead.email || ""}
                />

                <FormControl
                  variant="filled"
                  sx={{ m: "0.8vh", width: "48%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Sales Executive
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="salesExecutive"
                    onChange={handleChange}
                    value={newLead.salesExecutive || ""}
                  >
                    <MenuItem key={0} value={"Riyaz Ahmed"}>
                      Riyaz Ahmed
                    </MenuItem>
                    <MenuItem key={1} value={"Adil Kamal"}>
                    Adil Kamal
                    </MenuItem>
                    <MenuItem key={1} value={"Biswadeep Roy"}>
                    Biswadeep Roy
                    </MenuItem>
                    <MenuItem key={0} value={"Syed Kamran Hassan"}>
                      Syed Kamran Hassan
                    </MenuItem>
                    <MenuItem key={2} value={"Saif Ahmed"}>
                      Saif Ahmed
                    </MenuItem>
                    <MenuItem key={2} value={"Jaspreetraj Singh"}>
                    Jaspreetraj Singh
                    </MenuItem>
                    <MenuItem key={3} value={"Uzair Nadeem"}>
                      Uzair Nadeem
                    </MenuItem>
                    <MenuItem key={4} value={"Saad Ahmed"}>
                      Saad Ahmed
                    </MenuItem>
                    <MenuItem key={5} value={"Subham Das"}>
                      Subham Das
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ m: 1, width: "30%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="state"
                    onChange={handleChange}
                    value={newLead.state || ""}
                  >
                    <MenuItem key={1} value={"Andaman and Nicobar Islands"}>
                      Andaman and Nicobar Islands
                    </MenuItem>
                    <MenuItem key={2} value={"Andhra Pradesh"}>
                      Andhra Pradesh
                    </MenuItem>
                    <MenuItem key={3} value={"Arunachal Pradesh"}>
                      Arunachal Pradesh
                    </MenuItem>
                    <MenuItem key={4} value={"Assam"}>
                      Assam
                    </MenuItem>
                    <MenuItem key={5} value={"Bihar"}>
                      Bihar
                    </MenuItem>
                    <MenuItem key={6} value={"Chandigarh"}>
                      Chandigarh
                    </MenuItem>
                    <MenuItem key={7} value={"Chhattisgarh"}>
                      Chhattisgarh
                    </MenuItem>
                    <MenuItem key={8} value={"Dadra and Nagar Haveli and Daman and Diu"}>
                      Dadra and Nagar Haveli and Daman and Diu
                    </MenuItem>
                    <MenuItem key={9} value={"Delhi"}>
                      Delhi
                    </MenuItem>
                    <MenuItem key={10} value={"Goa"}>
                      Goa
                    </MenuItem>
                    <MenuItem key={11} value={"Gujarat"}>
                      Gujarat
                    </MenuItem>
                    <MenuItem key={12} value={"Haryana"}>
                      Haryana
                    </MenuItem>
                    <MenuItem key={13} value={"Himachal Pradesh"}>
                      Himachal Pradesh
                    </MenuItem>
                    <MenuItem key={14} value={"Jharkhand"}>
                      Jharkhand
                    </MenuItem>
                    <MenuItem key={15} value={"Karnataka"}>
                      Karnataka
                    </MenuItem>
                    <MenuItem key={16} value={"Kerala"}>
                      Kerala
                    </MenuItem>
                    <MenuItem key={17} value={"Lakshadweep"}>
                      Lakshadweep
                    </MenuItem>
                    <MenuItem key={18} value={"Madhya Pradesh"}>
                      Madhya Pradesh
                    </MenuItem>
                    <MenuItem key={19} value={"Maharashtra"}>
                      Maharashtra
                    </MenuItem>
                    <MenuItem key={20} value={"Manipur"}>
                      Manipur
                    </MenuItem>
                    <MenuItem key={21} value={"Meghalaya"}>
                      Meghalaya
                    </MenuItem>
                    <MenuItem key={22} value={"Mizoram"}>
                      Mizoram
                    </MenuItem>
                    <MenuItem key={23} value={"Nagaland"}>
                      Nagaland
                    </MenuItem>
                    <MenuItem key={24} value={"Odisha"}>
                      Odisha
                    </MenuItem>
                    <MenuItem key={25} value={"Punjab"}>
                      Punjab
                    </MenuItem>
                    <MenuItem key={26} value={"Rajasthan"}>
                      Rajasthan
                    </MenuItem>
                    <MenuItem key={27} value={"Sikkim"}>
                      Sikkim
                    </MenuItem>
                    <MenuItem key={28} value={"Tamil Nadu"}>
                      Tamil Nadu
                    </MenuItem>
                    <MenuItem key={29} value={"Telangana"}>
                      Telangana
                    </MenuItem>
                    <MenuItem key={30} value={"Tripura"}>
                      Tripura
                    </MenuItem>
                    <MenuItem key={31} value={"Uttarakhand"}>
                      Uttarakhand
                    </MenuItem>
                    <MenuItem key={32} value={"Puducherry"}>
                      Puducherry
                    </MenuItem>
                    <MenuItem key={33} value={"Ladakh"}>
                      Ladakh
                    </MenuItem>
                    <MenuItem key={34} value={"Jammu and Kashmir"}>
                      Jammu and Kashmir
                    </MenuItem>
                    <MenuItem key={35} value={"Uttar Pradesh"}>
                      Uttar Pradesh
                    </MenuItem>
                    <MenuItem key={36} value={"West Bengal"}>
                      West Bengal
                    </MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  sx={{
                    margin: "0.8vh",
                    bgcolor: "#F2F8FC",
                    width: "40%",
                  }}
                  id="filled-basic"
                  label="City"
                  variant="filled"
                  name="city"
                  onChange={handleChange}
                  value={newLead.city || ""}
                />

                <TextField
                  sx={{
                    margin: "0.8vh",
                    bgcolor: "#F2F8FC",
                    width: "23%",
                  }}
                  id="filled-basic"
                  label="Pin Code"
                  variant="filled"
                  name="pincode"
                  onChange={handleChange}
                  value={newLead.pincode || ""}
                />

                <TextField
                  sx={{
                    margin: "0.8vh",
                    width: "96.5%",
                    bgcolor: "#F2F8FC",
                    borderRadius: "5px",
                  }}
                  id="filled-basic"
                  label="Address"
                  variant="filled"
                  name="address"
                  onChange={handleChange}
                  value={newLead.address || ""}
                />

                <TextField
                  sx={{
                    margin: "0.8vh",
                    width: "96.5%",
                    bgcolor: "#F2F8FC",
                    borderRadius: "5px",
                  }}
                  id="filled-basic"
                  label="Activity Log"
                  variant="filled"
                  multiline
                  rows={8}
                  name="activityLog"
                  onChange={handleChange}
                  value={newLead.activityLog || ""}
                />
              </Item>
            </Grid>
            <Grid xs={4.2} sx={{ margin: "1vh" }}>
              <Item>
                <h2 style={{ color: "#1a5ea3" }}>Additional Information</h2>
                <Divider
                  sx={{
                    borderBottomWidth: 2,
                    backgroundColor: "#1a5ea3",
                    marginBottom: "1vh",
                  }}
                />

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "40%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Lead Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="leadType"
                    onChange={handleChange}
                    value={newLead.leadType || ""}
                  >
                    <MenuItem value={"Open"}>Open</MenuItem>
                    <MenuItem value={"On Progress"}>On Progress</MenuItem>
                    <MenuItem value={"Closed"}>Closed</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "52%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Lead Source
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="leadSource"
                    onChange={handleChange}
                    value={newLead.leadSource || ""}
                  >
                    <MenuItem value={"OLX"}>OLX</MenuItem>
                    <MenuItem value={"Car Trade"}>Car Trade</MenuItem>
                    <MenuItem value={"Direct Call"}>Direct Call</MenuItem>
                    <MenuItem value={"Social Media"}>Social Media</MenuItem>
                    <MenuItem value={"Referrals"}>Referrals</MenuItem>
                    <MenuItem value={"Personal Relation"}>Personal Relation</MenuItem>
                    <MenuItem value={"Facebook"}>Facebook</MenuItem>
                    <MenuItem value={"Whatsapp"}>Whatsapp</MenuItem>
                    <MenuItem value={"Instagram"}>Instagram</MenuItem>
                    <MenuItem value={"Website"}>Website</MenuItem>
                    <MenuItem value={"Events"}>Events</MenuItem>
                    <MenuItem value={"Campaign"}>Campaign</MenuItem>
                    <MenuItem value={"Showroom Visit"}>Showroom Visit</MenuItem>
                  </Select>
                </FormControl>

                {
  IsUpdating && newLead.leadType=="Closed"?
  <>
  <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "95%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Closing Reason
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="closingReason"
                    onChange={handleChange}
                    value={newLead.closingReason || ""}
                  >
                    {leadClosingReasons.map((value,index)=>{
                      return(
                        <MenuItem key={`key${index}`}  value={`${value.reason}`}>{`${value.reason}`}</MenuItem>)
                        })}
                   
                  </Select>
                </FormControl>

  </>:<></>
}




                <FormControl
                  sx={{ margin: "0.8vh", width: "96%", bgcolor: "#F2F8FC" }}
                >
                  <Autocomplete
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={newLead.vehicleBrands || vehicleBrandName}
                    // value={
                    //   (() => {
                    //     if (!newLead.vehicleBrands?.length > 0) {
                    //       return newLead.vehicleBrands;
                    //     } else if (!vehicleBrandName?.length > 0) {
                    //       return vehicleBrandName;
                    //     } else if (!updateExistingCustomer.purchasedBrand?.length > 0) {
                    //       return updateExistingCustomer.purchasedBrand;
                    //     } else {
                    //       return [];
                    //     }
                    //   })()
                    // }

                    options={carBrandNames || []}
                    onChange={handleBrandChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vehicle Brands"
                        placeholder="Search brands..."
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <div
                          key={index}
                          {...getTagProps({ index })}
                          style={{
                            backgroundColor: "#1665c0",
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px 10px",
                          }} // Apply the custom style inline
                        >
                          {option}
                        </div>
                      ))
                    }
                  />
                </FormControl>

                <FormControl
                  sx={{ margin: "0.8vh", width: "96%", bgcolor: "#F2F8FC" }}
                >
                  <Autocomplete
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={newLead.vehicleModels || vehicleModelName}
                    // value={
                    //   (() => {
                    //     if (!newLead.vehicleModels?.length > 0) {
                    //       return newLead.vehicleModels;
                    //     } else if (!vehicleModelName?.length > 0) {
                    //       return vehicleModelName;
                    //     } else if (!updateExistingCustomer.purchasedModel?.length > 0) {
                    //       return updateExistingCustomer.purchasedModel;
                    //     } else {
                    //       return [];
                    //     }
                    //   })()
                    // }

                    // options={carModelNames.map(value=>value.ModelName) || []}
                    options={
                      carModelNames
                        .filter((obj) =>
                          (newLead.vehicleBrands
                            ? newLead.vehicleBrands
                            : vehicleBrandName
                          ).includes(obj.brandName)
                        )
                        .map((obj) => obj.ModelName) || []
                    }
                    onChange={handleModelChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vehicle model"
                        placeholder="Search Model..."
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <div
                          key={index}
                          {...getTagProps({ index })}
                          style={{
                            backgroundColor: "#1665c0",
                            color: "white",
                            borderRadius: "20px",
                            padding: "3px 10px",
                          }} // Apply the custom style inline
                        >
                          {option}
                        </div>
                      ))
                    }
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "55%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Budget
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="budget"
                    onChange={handleChange}
                    value={newLead.budget || ""}
                  >
                    <MenuItem value={"Under 5 Lakhs"}>Under 5 Lakhs</MenuItem>
                    <MenuItem value={"Under 10 Lakhs"}>Under 10 Lakhs</MenuItem>
                    <MenuItem value={"Under 20 Lakhs"}>Under 20 Lakhs</MenuItem>
                    <MenuItem value={"Under 30 Lakhs"}>Under 30 Lakhs</MenuItem>
                    <MenuItem value={"Under 50 Lakhs"}>Under 50 Lakhs</MenuItem>
                    <MenuItem value={"Under 70 Lakhs"}>Under 70 Lakhs</MenuItem>
                    <MenuItem value={"Under 1 Crore"}>Under 1 Crore</MenuItem>
                    <MenuItem value={"Above 1 Crore"}>Above 1 Crore</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "37%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Test Drive
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="testDrive"
                    onChange={handleChange}
                    value={newLead.testDrive || ""}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "46%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Purchase Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="purchaseType"
                    onChange={handleChange}
                    value={newLead.purchaseType || ""}
                  >
                    <MenuItem value={"Self Bought"}>Outright</MenuItem>
                    <MenuItem value={"Finance"}>Finance</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "46%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Trade In
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="tradeIn"
                    onChange={handleChange}
                    value={newLead.tradeIn || ""}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>

                {newLead.tradeIn == "Yes" ? (
                  <TextField
                    sx={{
                      margin: "0.8vh",
                      bgcolor: "#F2F8FC",
                      width: "45%",
                    }}
                    id="filled-basic"
                    label="Trade In Car"
                    variant="filled"
                    name="tradeInCar"
                    onChange={handleChange}
                    value={newLead.tradeInCar || ""}
                  />
                ) : (
                  ""
                )}

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "46%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Due Followup?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="dueFollowUp"
                    onChange={handleChange}
                    value={newLead.dueFollowUp || ""}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "46%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Office Visit?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="officeVisit"
                    onChange={handleChange}
                    value={newLead.officeVisit || ""}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "46%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Car Available?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="carAvailable"
                    onChange={handleChange}
                    value={newLead.carAvailable || ""}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="filled"
                  sx={{ margin: "0.8vh", width: "46%", bgcolor: "#F2F8FC" }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Premium Customer?
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="premiumCustomer"
                    onChange={handleChange}
                    value={newLead.premiumCustomer || ""}
                  >
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Item>
            </Grid>

            <Grid xs={7} sx={{ margin: "0vh 1vh" }}>
              <Item>
                {IsUpdating ? (
                  <>
                    <Button
                      variant="contained"
                      sx={{ width: "100%", marginTop: "20px" }}
                      onClick={updateLead}
                      disabled={isLeadSaving ? true : false}
                    >
                      {isLeadSaving ? <CircularProgress size="1rem" sx={{ marginRight: "10px" }} color="inherit" /> : <></>}

                      Update Lead
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => addNewLead()}
                      style={{ width: "100%" }}
                      disabled={isLeadSaving ? true : false}
                    >
                      {isLeadSaving ? <CircularProgress size="1rem" sx={{ marginRight: "10px" }} color="inherit" /> : <></>}

                      SAVE LEAD
                    </Button>
                  </>
                )}
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* ------ New Customer Adding Section ---------- */}
      <Modal
        open={customerModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customerModalStyle}>
          <h2 style={{ color: "black", marginBottom: "10px" }}>
            {isCustomerUpdating ? <>Edit Customer</> : <>Add New Customer</>}
          </h2>

          <FormControl
            sx={{ margin: "0.8vh", width: "96%", bgcolor: "#F2F8FC" }}
          >

            <Autocomplete
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={isCustomerUpdating ? updateExistingCustomer.purchasedBrand : vehicleBrandName}
              options={carBrandNames || []}
              onChange={handleBrandChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle Brands"
                  placeholder="Search brands..."
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <div
                    key={index}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: "#1665c0",
                      color: "white",
                      borderRadius: "20px",
                      padding: "3px 10px",
                    }} // Apply the custom style inline
                  >
                    {option}
                  </div>
                ))
              }
            />
          </FormControl>

          <FormControl
            sx={{
              margin: "0.8vh 0px",
              width: "96%",
              bgcolor: "#F2F8FC",
              marginLeft: "7px",
            }}
          >

            <Autocomplete
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={isCustomerUpdating ? updateExistingCustomer.purchasedModel : vehicleModelName}
              options={
                carModelNames
                  .filter((obj) => {
                    const checkVehicleBrandFunc = () => {
                      if (newLead?.vehicleBrands?.length > 0) {
                        return newLead.vehicleBrands;
                      } else if (updateExistingCustomer?.purchasedBrand?.length > 0) {
                        return updateExistingCustomer.purchasedBrand;
                      } else {
                        return vehicleBrandName;
                      }
                    };

                    const customVehicleBrand = checkVehicleBrandFunc().includes(obj.brandName);
                    return customVehicleBrand;
                  })
                  .map((obj) => obj.ModelName) || []
              }
              onChange={handleModelChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vehicle model"
                  placeholder="Search Model..."
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <div
                    key={index}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: "#1665c0",
                      color: "white",
                      borderRadius: "20px",
                      padding: "3px 10px",
                    }} // Apply the custom style inline
                  >
                    {option}
                  </div>
                ))
              }
            />
          </FormControl>

          <FormControl
            variant="filled"
            sx={{ m: "0.8vh", width: "45%", bgcolor: "#F2F8FC" }}
          >
            <InputLabel id="demo-simple-select-filled-label">
              Insurance Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="insuranceStatus"
              onChange={isCustomerUpdating ? handleUpdateCustomer : handleNewCustomer}
              value={isCustomerUpdating ? updateExistingCustomer.insuranceStatus : newCustomer.insuranceStatus || ''}
            >
              <MenuItem key={0} value={"Valid"}>
                Valid
              </MenuItem>
              <MenuItem key={1} value={"Expired"}>
                Expired
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="filled"
            sx={{ m: "0.8vh", width: "45%", bgcolor: "#F2F8FC" }}
          >
            <InputLabel id="demo-simple-select-filled-label">
              Road Tax Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="taxStatus"
              onChange={isCustomerUpdating ? handleUpdateCustomer : handleNewCustomer}
              value={isCustomerUpdating ? updateExistingCustomer.taxStatus : newCustomer.taxStatus || ''}

            >
              <MenuItem key={0} value={"Valid"}>
                Valid
              </MenuItem>
              <MenuItem key={1} value={"Expired"}>
                Expired
              </MenuItem>
            </Select>
          </FormControl>

          <div
            style={{
              display: "flex",
              marginLeft: "7px",
              marginBottom: "8px",
              flexDirection: "row",
              gap: 25,
              width: "97%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ color: "grey", marginLeft: "5px" }}>
                Purchased Date
              </h4>
              <LocalizationProvider dateAdapter={AdapterLuxon}>

                <StyledDatePicker
                  sx={{ width: "100%" }}
                  name="purchasedDate"
                  value={purchasedDate || getStrToDate(updateExistingCustomer.purchasedDate)}
                  onChange={(newValue) => setPurchasedDate(newValue)}
                  styles={{
                    daySelected: {
                      background: "none", // Remove background color for selected date
                      color: "inherit",   // Use the default text color for selected date
                    },
                  }}
                />
              </LocalizationProvider>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ color: "grey", marginLeft: "5px" }}>
                Insurance Renewal Date
              </h4>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <StyledDatePicker
                  sx={{ width: "100%" }}
                  name="insuranceRenewalDate"
                  value={insuranceRenewalDate || getStrToDate(updateExistingCustomer.insuranceRenewalDate)}
                  onChange={(newValue) => setInsuranceRenewalDate(newValue)}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div
            style={{
              width: "45%",
              display: "flex",
              marginLeft: "7px",
              marginBottom: "8px",
              flexDirection: "row",
              gap: 25,
              width: "97%",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ color: "grey", marginLeft: "5px" }}>
                Tax Renewal Date
              </h4>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <StyledDatePicker
                  sx={{ width: "100%" }}
                  name="taxRenewalDate"
                  value={taxRenewalDate || getStrToDate(updateExistingCustomer.taxRenewalDate)}
                  onChange={(newValue) => setTaxRenewalDate(newValue)}
                />
              </LocalizationProvider>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 style={{ color: "grey", marginLeft: "5px" }}>
                Full Payment Recieved Date
              </h4>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <StyledDatePicker
                  sx={{ width: "100%" }}
                  name="fullPaymentRecievedDate"
                  value={fullPaymentRecievedDate || getStrToDate(updateExistingCustomer.fullPaymentRecievedDate)}
                  onChange={(newValue) => setFullPaymentRecievedDate(newValue)}
                />
              </LocalizationProvider>
            </div>
          </div>

          <FormControl
            variant="filled"
            sx={{ m: "0.8vh", width: "45%", bgcolor: "#F2F8FC" }}
          >
            <InputLabel id="demo-simple-select-filled-label">
              RC Transfer Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="rcTransferStatus"
              onChange={isCustomerUpdating ? handleUpdateCustomer : handleNewCustomer}
              value={isCustomerUpdating ? updateExistingCustomer.rcTransferStatus : newCustomer.rcTransferStatus || ""}
            >
              <MenuItem key={0} value={"Transferred"}>
                Transferred
              </MenuItem>
              <MenuItem key={1} value={"On Process"}>
                On Process
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="filled"
            sx={{ m: "0.8vh", width: "45%", bgcolor: "#F2F8FC" }}
          >
            <InputLabel id="demo-simple-select-filled-label">
              Customer Profile
            </InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="customerProfile"
              onChange={isCustomerUpdating ? handleUpdateCustomer : handleNewCustomer}
              value={isCustomerUpdating ? updateExistingCustomer.customerProfile : newCustomer.customerProfile || ""}
            >
              <MenuItem key={0} value={"Premium"}>
                Premium
              </MenuItem>
              <MenuItem key={1} value={"Average"}>
                Average
              </MenuItem>
              <MenuItem key={1} value={"Bad"}>
                Bad
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ width: "100%", marginTop: "20px" }}
            onClick={isCustomerUpdating ? UpdateCustomer : addNewCustomer}
            disabled={isLeadSaving ? true : false}
          >
            {isCustomerUpdating ? <>
              {isLeadSaving ? <CircularProgress size="1rem" sx={{ marginRight: "10px" }} color="inherit" /> : <></>}
              Update Customer</> : <>
              {isLeadSaving ? <CircularProgress size="1rem" sx={{ marginRight: "10px" }} color="inherit" /> : <></>}
              Add New Customer</>}
          </Button>
        </Box>
      </Modal>



      <Modal
        open={openAddTaskModal}
        onClose={handleAddTaskModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customStyleTask}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{
            color: "white",
            background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
            padding: "15px",
            borderRadius: "10px 10px 0px 0px",
            fontSize: "25px"
          }}>
            Add New Task
          </Typography>


          <div style={{ padding: "20px", background: "#f4f4f4", borderRadius: "10px", maxWidth: "400px", margin: "0 auto" }}>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="filled"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleTaskChange}
              name="title"
            />
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <StyledDatePicker
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                  borderColor:"green",
                  "& .MuiPickersDay-daySelected": {
                    background: "none",
                    color: "inherit",
                  },
                }}
                label="Reminder Date"
                name="remindDate"
                onChange={(newValue) => setNewDate(newValue)}
              />
            </LocalizationProvider>
            <TextField
              id="outlined-basic"
              label="Description"
              multiline
              rows={4}
              variant="filled"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleTaskChange}
              name="desc"
            />
            <Button variant="contained" onClick={addNewTask}>Add</Button>
          </div>


        </Box>
      </Modal>




      {/* Reports Modal */}
      <Modal
        open={openAvanceLeadReportModal}
        onClose={handleAdvanceReportModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={AdvReportStyle} style={{ color: "black" }}>
          <div style={{
            background: "linear-gradient(111.5deg, rgb(20, 100, 196) 0.4%, rgb(33, 152, 214) 100.2%)",
            color: "white", padding: "10px 10px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px"
          }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Leads Report Today
            </Typography>
          </div>
          <div style={{
            margin: "10px",
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            padding: "10px",

          }}>
            <div style={{ marginBottom: "5px", display: "flex", alignItems: "center" }}>
              <span
                style={{
                  background: "linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
                  color: "white",
                  padding: "10px",
                  borderRadius: "10px",
                  width: "50%",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center"
                }}
              >                  <PhoneCallback sx={{ color: "white", marginRight: "10px" }} />
                FollowUps Today: {TodayDueFollowUpCount || 0}</span>

              <span
                style={{
                  background: "linear-gradient(108.4deg, rgb(253, 44, 56) 3.3%, rgb(176, 2, 12) 98.4%)",
                  color: "white",
                  padding: "10px",
                  borderRadius: "10px",
                  width: "50%",
                  display: "flex",
                  alignItems: "center"
                }}
              >                  <ContactPhone sx={{ color: "white", marginRight: "10px" }} />
                Leads Today: {leadsAddedTodayCount || 0}</span>
            </div>

          </div>

          <div style={{
            margin: "10px",
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            padding: "10px"
          }}>
            <FormControl fullWidth>
              <p style={{
                background: "linear-gradient(to right,#434343 0%,black 100%)",
                color: "white", padding: "10px 10px"
              }}>Leads Reports Sales Executive</p>
              <div style={{ display: "flex", width: "100%", marginTop: "10px" }} >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={salesExecutiveName}
                  label="Age"
                  onChange={handleLeadsSalesExecutiveReport}
                  sx={{ flexGrow: "1", marginRight: "1vw" }}
                >
                  <MenuItem value={"Riyaz Ahmed"}>Riyaz Ahmed</MenuItem>
                  <MenuItem value={"Adil Kamal"}>Adil Kamal</MenuItem>
                  <MenuItem value={"Biswadeep Roy"}>Biswadeep Roy</MenuItem>
                  <MenuItem value={"Syed Kamran Hassan"}>Syed Kamran Hassan</MenuItem>
                  <MenuItem value={"Saad Ahmed"}>Saad Ahmed</MenuItem>
                  <MenuItem value={"Saif Ahmed"}>Saif Ahmed</MenuItem>
                  <MenuItem value={"Subham Das"}>Subham Das</MenuItem>
                  <MenuItem value={"Jaspreetraj Singh"}>Jaspreetraj Singh</MenuItem>

                </Select>
                <Button variant="contained" onClick={getSalesExecutiveReportFunc}>Get Data</Button></div>
            </FormControl>
            <div style={{ marginTop: "10px" }}>
              {salesExecutiveleadsReport ? <>
                {salesExecutiveName}
                <div>Total Leads Added : {salesExecutiveleadsReport || 0}</div>
                <div>Total Due Followu Ups : {salesExecutiveDueFollowUpleadsReport || 0}</div>
              </> : ""}


            </div>
          </div>
        </Box>
      </Modal>








      <ToastContainer />
    </>
  );
};



export default Leads;
