import React, { createContext, useState, useEffect, useMemo } from 'react';
import jwt_decode from 'jwt-decode';

export const AuthContext = createContext();
const decodeToken = (token) => {
  return jwt_decode(token);
};

export const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      const decodedToken = decodeToken(token);
      setUserData(decodedToken);

      if (decodedToken.exp < Date.now() / 1000) {
        setIsAuthenticated(false);
        setUserData(null);
        localStorage.removeItem('accessToken');
      } else {
        setIsAuthenticated(true);
      }
    } else {
      setIsAuthenticated(false);
      setUserData(null);
    }
  }, []);

  const setAuthData = (token) => {
    const decodedToken = decodeToken(token);
    setUserData(decodedToken);
    localStorage.setItem('accessToken', token);
    setIsAuthenticated(true);
  };

  const clearAuthData = () => {
    localStorage.removeItem('accessToken');
    localStorage.clear();
    setIsAuthenticated(false);
    setUserDetails(null)
  };

  const contextValue = useMemo(() => {
    return { isAuthenticated, userData,userDetails,setUserDetails, setAuthData, clearAuthData };
  }, [isAuthenticated, userData]);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
