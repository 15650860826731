import { Box, Stack, flexbox, height } from "@mui/system";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Sidebar from "../../Pages/Dashboard/Sidebar";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import "./Styles/TaskReminder.css"
import { Skeleton, TextField } from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ToastContainer, toast } from "react-toastify";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  // border: '2px solid #000',
  boxShadow: 24,

  borderRadius: "10px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"

};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TaskReminder(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <Tasks />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}
const Tasks = () => {
  const [getAllTasks, setgetAllTasks] = React.useState([]);
  const [openAddTaskModal, setOpenAddTaskModal] = React.useState(false);
  const handleAddTaskModalOpen = () => setOpenAddTaskModal(true);
  const handleAddTaskModalClose = () => {
    setOpenAddTaskModal(false)
    setNewDate("")
    setNewTasks([])
  };
  const [newDate, setNewDate] = React.useState("");
  const [isAllTaskLoaded, setisAllTaskLoaded] = React.useState(false);

  const [newTasks, setNewTasks] = React.useState(
    [
      {
        title: "",
        remindDate: "",
        desc: "",
        salesExecutive: ""
      }
    ]
  );

  function handleTaskChange(evt) {
    const value = evt.target.value;
    setNewTasks({
      ...newTasks,
      [evt.target.name]: value,
      remindDate: newDate ? JSON.stringify(newDate.ts) : "",
      salesExecutive: localStorage.getItem('username')
    });
  }





  const addNewTask = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }


    if (
      isInvalid(newTasks.title) ||
      isInvalid(newTasks.remindDate) ||
      isInvalid(newTasks.desc)

    ) {
      toast.error("All fields are mandatory!")
    } else {
      await axios
        .post("/addTask", newTasks, config)
        .then((response) => {

          toast.success("Task Added Successfully!");
          setOpenAddTaskModal(false)
          setNewDate([])
          setNewTasks([])
          getAllTaskFunc()
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.error == "Task Already Exists") {
            toast.error("Task Already Exists");
          } else {
            toast.error("Task Cannot Be Added");
          }
        });
    }
  };


  async function handleDelete(uuid) {

    if (window.confirm("You surely want to delete this task?")) {
      await deleteTask(uuid)
    }      // await deleteUser(Value.row.uid) 
    else {
      await getAllTaskFunc()
    }
  }

  const deleteTask = async (uuid) => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    await axios
      .delete(`/deleteTask/${uuid}`, config)
      .then((response) => {

        toast.success("Task Deleted Successfully!");
        setOpenAddTaskModal(false)
        setNewDate([])
        setNewTasks([])
        getAllTaskFunc()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "Task Already Exists") {
          toast.error("Task Already Exists");
        } else {
          toast.error("Task Cannot Be Deleted");
        }
      });

  };


  const getAllTaskFunc = async () => {
    setisAllTaskLoaded(true)
    const token = localStorage.getItem("accessToken");
    var salesExecutive = localStorage.getItem('username');
    const config = {
      headers: {
        auth: token,
      },
    };
    axios
      .get(`/tasks/${salesExecutive}`, config)
      .then((response) => {
        setgetAllTasks(response.data.count.rows);
        setisAllTaskLoaded(false)
      });
  };
  React.useEffect(() => {
    getAllTaskFunc();
  }, []);



  function convertDataToCalendarEvents(arrayOfObjects) {
    return arrayOfObjects.map((item) => ({
      title: item.title,
      start: new Date(parseInt(item.remindDate)),
      end: new Date(parseInt(item.remindDate)),
      description: item.desc,
      className: 'meeting-event',
      allDay: true, // Set allDay to true to indicate that this is an all-day event
    }));
  }


  // Convert data to calendar events
  const calendarEvents = convertDataToCalendarEvents(getAllTasks);



  return (
    <>
      <Box flex={4} bgcolor="" p={2} ml={"18vw"} mt={1.5}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {/* <Grid xs={12}>
              <Item>xs=8</Item>
            </Grid> */}


            <Grid xs={9}>
              <Item style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "20px", borderRadius: "10px", width: "100%", overflow: "hidden" }}>
                <div style={{ width: "100%", height: "100%" }}>
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    weekends={true} // Set to 'true' to include weekends
                    events={calendarEvents}
                    eventContent={renderEventContent}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    editable={true}
                    dayHeaderFormat={{ weekday: 'long' }} // Display full weekday names
                    slotLabelFormat={{
                      hour: 'numeric',
                      minute: '2-digit',
                      omitZeroMinute: false,
                      meridiem: 'short',
                      hour12: false
                    }}
                    slotDuration={{ days: 1 }} // Display one day per slot
                  />

                </div>
              </Item>

            </Grid>
            <Grid xs={3}>

              <Item sx={{ borderRadius: "10px", margin: 0, padding: 0 }}>
                <div className="events-today-header" >

                  <div style={{fontWeight:"bold",fontFamily:"Kamerik"}}>All Tasks</div>
                  <Button variant="contained" style={{ background: "transparent", boxShadow: "none" }}
                    onClick={handleAddTaskModalOpen}
                  >
                    <AddIcon /> Add Task
                  </Button>
                </div>
                <div className="scroll-tasks">
                  {getAllTasks.length > 0 ?
                    <>{getAllTasks?.map((data, index) => (
                      <div className="event-list" key={index}>
                        <div className="event">
                          <div className="task-title">
                            <h2 className="event-title"> <TaskAltIcon />{data.title}</h2>
                            <DeleteForeverIcon style={{ color: "white", cursor: "pointer" }} onClick={() => { handleDelete(data.uuid) }}
                              onMouseEnter={(e) => {
                                e.currentTarget.style.color = "red"; // Change color to red on hover
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.color = "white"; // Restore the original color on mouse leave
                              }}
                            />

                          </div>
                          <p className="event-details">
                            <DateRangeIcon /> {new Date(parseInt(data.remindDate)).toLocaleDateString()}

                          </p>


                          <p className="event-description">{data.desc}</p>
                        </div>
                      </div>
                    ))}
                    </> :
                    <>
                      {isAllTaskLoaded ? <><div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <Skeleton animation="wave" height={168} width={"80%"} />
                        <Skeleton animation="wave" width={"80%"} />
                        <Skeleton animation="wave" width={"80%"} />
                        <Skeleton animation="wave" height={168} width={"80%"} />
                        <Skeleton animation="wave" width={"80%"} />
                        <Skeleton animation="wave" width={"80%"} />
                      </div></> : <> </>}
                    </>}
                </div>
              </Item>


            </Grid>
          </Grid>
        </Box>
      </Box>



      <Modal
        open={openAddTaskModal}
        onClose={handleAddTaskModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{
            color: "white",
            background: "linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))",
            padding: "15px",
            borderRadius: "10px 10px 0px 0px",
            fontSize: "25px"
          }}>
            Add New Task
          </Typography>


          <div style={{ padding: "20px", background: "#f4f4f4", borderRadius: "10px", maxWidth: "400px", margin: "0 auto" }}>
            <TextField
              id="outlined-basic"
              label="Title"
              variant="filled"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleTaskChange}
              name="title"
            />
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                sx={{
                  width: "100%",
                  marginBottom: "20px",
                  "& .MuiPickersDay-daySelected": {
                    background: "none",
                    color: "inherit",
                  },
                }}
                label="Reminder Date"
                name="remindDate"
                onChange={(newValue) => setNewDate(newValue)}
              />
            </LocalizationProvider>
            <TextField
              id="outlined-basic"
              label="Description"
              multiline
              rows={4}
              variant="filled"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={handleTaskChange}
              name="desc"
            />
            <Button variant="contained" onClick={addNewTask}>Add</Button>
          </div>


        </Box>
      </Modal>







      <ToastContainer />

    </>
  );
}
// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      &nbsp;        &nbsp;


      <i>{eventInfo.event.title}</i>
    </>
  )
}
export default TaskReminder;
