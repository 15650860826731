import { Box, Stack } from "@mui/system";
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DashNav from "../Dashboard/DashNav";
import Sidebar from "../Dashboard/Sidebar";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Rating,
  Select,
  TextField,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { useTheme } from "styled-components";
import axios from "axios";
const carBrandNames = require("../AdditionalPages/VehicleBrands.js")

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  // textAlign: "center",
  color: "black",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function AddLead() {
  return (
    <>
      <DashNav />

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <AddLeadComponent />
      </Stack>
    </>
  );
}

//---- Leads Dynamic Component --------

const AddLeadComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [vehicleBrandName, setvehicleBrandName] = React.useState([]);
  const [vehicleModelName, setvehicleModelName] = React.useState([]);
const [carModelNames, setcarModelNames] = React.useState([])
  const handleBrandChange = (event) => {
    const {
      target: { value },
    } = event;
    setvehicleBrandName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleModelChange = (event) => {
    const {
      target: { value },
    } = event;
    setvehicleModelName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const addLead = () => {
    navigate("/addlead");
  };
  const getAllVehicleModels = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
     axios
      .get("/vehiclemodels", config)
      .then((response) => {
        setcarModelNames(response.data.data);
      });
};
React.useEffect(() => {
getAllVehicleModels();
}, []);
  
  return (
    <>
      <Box flex={4} bgcolor="#0F1319" p={2} ml={"18vw"} mt={8}>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={2}>
            {/* Leads Top Bar */}
            <div>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <div
                  style={{
                    fontSize: "40px",
                    alignItems: "center",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <PersonAddIcon style={{ fontSize: 40 }} /> Add New Lead
                </div>
                <div></div>
                <div>
                  {/* <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search> */}
                </div>
              </Stack>
            </div>

            {/* Shortcut Filter Section */}
            <Stack direction="row" spacing={2} justifyContent="space-between">
              {/* <div>Item 1</div>
              <div>Item 2</div>
              <div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={addLead}
                  startIcon={<AddIcon />}
                >
                  Add New Lead
                </Button>
              </div> */}
            </Stack>

            <div>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid xs={7} sx={{ margin: "2vh" }}>
                    <Item>
                      <h1 style={{ color: "black" }}>Personal Details</h1>
                      <Divider
                        sx={{
                          borderBottomWidth: 2,
                          backgroundColor: "lightgrey",
                          marginBottom: "1vh",
                        }}
                      />

                      <TextField
                        sx={{
                          margin: "0.8vh",
                          width: "40%",
                          bgcolor:"#F2F8FC",
                        }}
                        id="filled-basic"
                        label="Customer Name"
                        variant="filled"
                      />
                      <TextField
                        sx={{
                          margin: "0.8vh",
                          width: "33%",
                          bgcolor:"#F2F8FC",
                        }}
                        id="filled-basic"
                        label="Contact Number"
                        variant="filled"
                      />
                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "20%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        sx={{
                          margin: "0.8vh",
                          width: "47%",
                          bgcolor:"#F2F8FC",
                        }}
                        id="filled-basic"
                        label="Email"
                        variant="filled"
                      />

                      <FormControl
                        variant="filled"
                        sx={{ m: "0.8vh", width: "48%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Sales Executive
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem key={0} value={"Riyaz Ahmed"}>
                            Riyaz Ahmed
                          </MenuItem>
                          <MenuItem key={1} value={"Ahsan Ali"}>
                            Ahsan Ali
                          </MenuItem>
                          <MenuItem key={2} value={"Saif Ahmed"}>
                            Saif Ahmed
                          </MenuItem>
                          <MenuItem key={3} value={"Uzair Nadeem"}>
                            Uzair Nadeem
                          </MenuItem>
                          <MenuItem key={4} value={"Saad Ahmed"}>
                            Saad Ahmed
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        variant="filled"
                        sx={{ m: 1, width: "30%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          State
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem key={0} value={"Andhra Pradesh"}>
                            Andhra Pradesh
                          </MenuItem>
                          <MenuItem key={1} value={"Andhra Pradesh"}>
                            Andhra Pradesh
                          </MenuItem>
                          <MenuItem key={2} value={"Assam"}>
                            Assam
                          </MenuItem>
                          <MenuItem key={3} value={"Bihar"}>
                            Bihar
                          </MenuItem>
                          <MenuItem key={4} value={"Chhattisgarh"}>
                            Chhattisgarh
                          </MenuItem>
                          <MenuItem key={4} value={"Goa"}>
                            Goa
                          </MenuItem>
                          <MenuItem key={4} value={"Gujarat"}>
                            Gujarat
                          </MenuItem>
                          <MenuItem key={4} value={"Haryana"}>
                            Haryana
                          </MenuItem>
                          <MenuItem key={4} value={"Himachal Pradesh"}>
                            Himachal Pradesh
                          </MenuItem>
                          <MenuItem key={4} value={"Jharkhand"}>
                            Jharkhand
                          </MenuItem>
                          <MenuItem key={4} value={"Karnataka"}>
                            Karnataka
                          </MenuItem>
                          <MenuItem key={4} value={"Kerala"}>
                            Kerala
                          </MenuItem>
                          <MenuItem key={4} value={"Madhya Pradesh"}>
                            Madhya Pradesh
                          </MenuItem>
                          <MenuItem key={4} value={"Maharashtra"}>
                            Maharashtra
                          </MenuItem>
                          <MenuItem key={4} value={"Manipur"}>
                            Manipur
                          </MenuItem>
                          <MenuItem key={4} value={"Meghalaya"}>
                            Meghalaya
                          </MenuItem>
                          <MenuItem key={4} value={"Mizoram"}>
                            Mizoram
                          </MenuItem>
                          <MenuItem key={4} value={"Nagaland"}>
                            Nagaland
                          </MenuItem>
                          <MenuItem key={4} value={"Odisha"}>
                            Odisha
                          </MenuItem>
                          <MenuItem key={4} value={"Punjab"}>
                            Punjab
                          </MenuItem>
                          <MenuItem key={4} value={"Rajasthan"}>
                            Rajasthan
                          </MenuItem>
                          <MenuItem key={4} value={"Sikkim"}>
                            Sikkim
                          </MenuItem>
                          <MenuItem key={4} value={"Tamil Nadu"}>
                            Tamil Nadu
                          </MenuItem>
                          <MenuItem key={4} value={"Telangana"}>
                            Telangana
                          </MenuItem>
                          <MenuItem key={4} value={"Tripura"}>
                            Tripura
                          </MenuItem>
                          <MenuItem key={4} value={"Uttarakhand"}>
                            Uttarakhandu
                          </MenuItem>
                          <MenuItem key={4} value={"Uttar Pradesh"}>
                            Uttar Pradesh
                          </MenuItem>
                          <MenuItem key={4} value={"West Bengal"}>
                            West Bengal
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        sx={{
                          margin: "0.8vh",
                          bgcolor:"#F2F8FC",
                          width: "40%",
                        }}
                        id="filled-basic"
                        label="City"
                        variant="filled"
                      />

                      <TextField
                        sx={{
                          margin: "0.8vh",
                          bgcolor:"#F2F8FC",
                          width: "23%",
                        }}
                        id="filled-basic"
                        label="Pin Code"
                        variant="filled"
                      />

                      <TextField
                        sx={{
                          margin: "0.8vh",
                          width: "96.5%",
                          bgcolor:"#F2F8FC",
                          borderRadius: "5px",
                        }}
                        id="filled-basic"
                        label="Address"
                        variant="filled"
                      />
                      
                    <TextField
                        sx={{
                          margin: "0.8vh",
                          width: "96.5%",
                          bgcolor:"#F2F8FC",
                          borderRadius: "5px",
                        }}
                        id="filled-basic"
                        label="Activity Log"
                        variant="filled"
                        multiline
                        rows={8}

                      />
                    </Item>
                  </Grid>
                  <Grid xs={4.2} sx={{ margin: "2vh" }}>
                    <Item>
                      <h1 style={{ color: "black" }}>Lead Details</h1>
                      <Divider
                        sx={{
                          borderBottomWidth: 2,
                          backgroundColor: "lightgrey",
                          marginBottom: "1vh",
                        }}
                      />

                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "40%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Lead Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Hot"}>Hot</MenuItem>
                          <MenuItem value={"Warm"}>Warm</MenuItem>
                          <MenuItem value={"Cold"}>Cold</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "52%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Lead Source
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"OLX"}>OLX</MenuItem>
                          <MenuItem value={"Car Trade"}>Car Trade</MenuItem>
                          <MenuItem value={"Direct Call"}>Car Trade</MenuItem>
                          <MenuItem value={"Social Media"}>
                            Social Media
                          </MenuItem>
                          <MenuItem value={"Referrals"}>Referrals</MenuItem>
                          <MenuItem value={"Facebook"}>Facebook</MenuItem>
                          <MenuItem value={"Whatsapp"}>Whatsapp</MenuItem>
                          <MenuItem value={"Instagram"}>Instagram</MenuItem>
                          <MenuItem value={"Website"}>Website</MenuItem>
                          <MenuItem value={"Events"}>Events</MenuItem>
                          <MenuItem value={"Campaign"}>Events</MenuItem>
                          <MenuItem value={"Showroom Visit"}>
                            Showroom Visit
                          </MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        sx={{ margin: "0.8vh", width: "96%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel
                          variant="filled"
                          id="demo-multiple-chip-label"
                        >
                          Vehicle Brands 
                                       </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={vehicleBrandName}
                          onChange={handleBrandChange}
                          input={<OutlinedInput id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                padding:"1vh 0",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {carBrandNames.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>



                      <FormControl
                        sx={{ margin: "0.8vh", width: "96%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel
                          variant="filled"
                          id="demo-multiple-chip-label"
                        >
                          Vehicle Model 
                                       </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={vehicleModelName}
                          onChange={handleModelChange}
                          input={<OutlinedInput id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                padding:"1vh 0",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {carModelNames.map((value,index) => (
                            <MenuItem key={value.ModelName} value={value.ModelName}>
                              {value.ModelName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>



                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "55%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Budget
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Under 5 Lakhs"}>Under 5 Lakhs</MenuItem>
                          <MenuItem value={"Under 10 Lakhs"}>Under 10 Lakhs</MenuItem>
                          <MenuItem value={"Under 20 Lakhs"}>Under 20 Lakhs</MenuItem>
                          <MenuItem value={"Under 30 Lakhs"}>Under 30 Lakhs</MenuItem>
                          <MenuItem value={"Under 50 Lakhs"}>Under 50 Lakhs</MenuItem>
                          <MenuItem value={"Under 70 Lakhs"}>Under 70 Lakhs</MenuItem>
                          <MenuItem value={"Under 1 Crore"}>Under 1 Crore</MenuItem>
                          <MenuItem value={"Above 1 Crore"}>Above 1 Crore</MenuItem>

                        </Select>
                      </FormControl>

                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "37%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Test Drive
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "46%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Purchase Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Self Bought"}>Self Bought</MenuItem>
                          <MenuItem value={"Finance"}>Finance</MenuItem>
                        </Select>
                      </FormControl>


                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "46%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Trade In
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "46%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Due Followup?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </FormControl>


                      <FormControl
                        variant="filled"
                        sx={{margin: "0.8vh", width: "46%", bgcolor:"#F2F8FC" }}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Car Available?
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Item>
                  </Grid>
                  {/* <Grid xs={7} sx={{ margin: "2vh",marginTop:"-5vh",width:"100%" }}>
                    <Item>
                    <h1 style={{ color: "black" }}>Activity Details</h1>
                      <Divider
                        sx={{
                          borderBottomWidth: 2,
                          backgroundColor: "lightgrey",
                          marginBottom: "1vh",
                        }}
                      />
                    <TextField
                        sx={{
                          margin: "0.8vh",
                          width: "96.5%",
                          bgcolor:"#F2F8FC",
                          borderRadius: "5px",
                        }}
                        id="filled-basic"
                        label="Activity Log"
                        variant="filled"
                        multiline
                        rows={8}

                      />
                    </Item>
                  </Grid> */}
                  <Grid xs={7} sx={{ margin: "2vh" }}>
                    {/* <Item>xs=8</Item> */}
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default AddLead;
