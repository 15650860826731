import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import customerIcon from "../../Images/customers.svg";
import progressFunnel from "../../Images/funnel-icon.svg";
import openLeadIcon from "../../Images/open-lead-icon.svg";
import dueFollowupIcon from "../../Images/miss-call-icon.svg";
import closedLeadsIcon from "../../Images/closedLeads.svg";
import officeVisitIcon from "../../Images/office-visit.svg"
import avgIVRCallsIcon from "../../Images/avgIVRCallsIcons.svg"
import totalIVRCallsIcons from "../../Images/totalCallsIcons.svg";
import totalIVRMissedCallsAvg from "../../Images/ivrMissedCallsIcon.svg"
import callsIcon from "../../Images/callIcon.svg"
import DatamapsIndia from 'react-datamaps-india'

import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";


import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Line } from "react-chartjs-2";
import { Pie } from 'react-chartjs-2';
import { set } from "lodash";

const { DateTime } = require("luxon");

const Item = styled(Paper)(({ theme }) => ({
  padding: "10px",
  textAlign: "center",
  color: "lightGrey",
}));
const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  color: "inherit",
  "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
  {
    borderColor: "grey",
  },
}));
function Reports(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <ReportsComponent />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}
const ReportsComponent = () => {
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [reportsLoader, setReportsLoader] = React.useState(false);
  const [fetchedReports, setFetchedReports] = React.useState([]);
  const [totalLeadsCount, setTotalLeadsCount] = React.useState(0);
  const [openLeadsCount, setOpenLeadsCount] = React.useState(0);
  const [oProgressLeadsCount, setOnProgressLeadsCount] = React.useState(0);
  const [dueFollowUpsLeadsCount, setDueFollowUpsLeadsCount] = React.useState(0);
  const [closedLeadsCount, setClosedLeadsCount] = React.useState(0);
  const [totalOfficeCount, setTotalOfficeCount] = React.useState(0);
  const [averageIVRCallsPerDay, setAverageIVRCallsPerDay] = React.useState(0);
  const [averageIVRAnsweredCallsPerDay, setAverageIVRAnsweredCallsPerDay] = React.useState(0);
  const [averageIVRMissedCallsPerDay, setAverageIVRMissedCallsPerDay] = React.useState(0);
  const [totalIVRCallsCount, setTotalIVRCallsCount] = React.useState(0);
  const [totalGeoChartData, setTotalGeoChartData] = React.useState([]);
  const [sourceWiseLeadsCount, setSourceWiseLeadsCount] = React.useState([]);
  const [salesExecutiveWiseLeadsCount, setsalesExecutiveWiseLeadsCount] = React.useState([]);
  const [salesConversion, setsalesConversion] = React.useState([]);


  //--------- LEADS Source PIE CHART CUSTOMISATION -----------
  function getLeadsWiseAllLabels(data) {
    return data.map(item => item.leadSource);
  }
  const PieChartLabels = getLeadsWiseAllLabels(sourceWiseLeadsCount);
  function getValuesByKey(data, key) {
    return data.map(item => item[key]);
  }

  const PieChartValues = getValuesByKey(sourceWiseLeadsCount, "lead_count");
  const PieChartData = {
    labels: PieChartLabels,
    datasets: [
      {
        data: PieChartValues,
        backgroundColor: [
          'rgba(0, 123, 255, 0.8)',    // Blue
          'rgba(255, 99, 132, 0.8)',   // Red
          'rgba(75, 192, 192, 0.8)',   // Sea Green
          'rgba(255, 206, 86, 0.8)',   // Yellow
          'rgba(153, 102, 255, 0.8)',  // Purple
          'rgba(54, 162, 235, 0.8)',   // Sky Blue
        ],
        borderWidth: 1, // Border width of the slices
        borderColor: '#fff', // Border color of the slices
        hoverBorderColor: '#000', // Border color when hovering over the slices
      },
    ],
  };
  //------------------------------------------------------------


  //--------- salesExecutive Wise Leads  PIE CHART CUSTOMISATION -----------
  function getsalesExeAllLabels(data) {
    return data.map(item => item.salesExecutive);
  }
  const salesExecutivePieChartLabels = getsalesExeAllLabels(salesExecutiveWiseLeadsCount);
  function getSalesExeValuesByKey(data, key) {
    return data.map(item => item[key]);
  }

  const salesExecutiveChartValues = getSalesExeValuesByKey(salesExecutiveWiseLeadsCount, "lead_count");
  const salesExecutiveChartData = {
    labels: salesExecutivePieChartLabels,
    datasets: [
      {
        data: salesExecutiveChartValues,
        backgroundColor: [
          'rgba(0, 123, 255, 0.8)',    // Blue
          'rgba(255, 99, 132, 0.8)',   // Red
          'rgba(75, 192, 192, 0.8)',   // Sea Green
          'rgba(255, 206, 86, 0.8)',   // Yellow
          'rgba(153, 102, 255, 0.8)',  // Purple
          'rgba(54, 162, 235, 0.8)',   // Sky Blue
        ],
        borderWidth: 1, // Border width of the slices
        borderColor: '#fff', // Border color of the slices
        hoverBorderColor: '#000', // Border color when hovering over the slices
      },
    ],
  };
  //------------------------------------------------------------

  const getReport = async () => {
    setReportsLoader(true);
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }

    if (isInvalid(startDate) || isInvalid(endDate)) {
      toast.error("All fields are mandatory!");
      setReportsLoader(false);
    } else {
      const payload = {
        startDate: startDate.toISODate(),
        endDate: endDate.toISODate(),
      };
      await axios
        .post("/getReport", payload, config)
        .then((response) => {
          toast.success("Reports Fetched Successfully!");
          setFetchedReports(response?.data?.result);
          setTotalLeadsCount(response?.data?.result?.totalLeadsCount);
          setOpenLeadsCount(response?.data?.result?.totalOpenLeadsCount);
          setTotalOfficeCount(response?.data?.result?.totalOfficeVisitCount);
          setAverageIVRCallsPerDay(response?.data.result?.getAverageIVRCalls[0]?.average_calls_per_day)
          setAverageIVRAnsweredCallsPerDay(response?.data.result?.getAverageAnsweredCalls[0]?.average_calls_answered)
          setAverageIVRMissedCallsPerDay(response?.data.result?.getAverageMissedCalls[0]?.average_calls_missed)
          setTotalIVRCallsCount(response?.data?.result?.getTotalIVRCallsCount[0]?.total_answered_calls)
          setOnProgressLeadsCount(
            response?.data?.result?.totalOnProgressLeadsCount
          );
          setDueFollowUpsLeadsCount(
            response?.data?.result?.totalDueFollowupsCount?.count
          );
          setTotalGeoChartData(response?.data?.result?.getGeoLeadsDistributonData);
          setSourceWiseLeadsCount(response?.data?.result?.SouceWiseLeadsCount);
          setsalesExecutiveWiseLeadsCount(response?.data?.result?.salesExecutiveWiseLeadCount);
          setsalesConversion(response?.data?.result?.salesConverstionCount);
          setClosedLeadsCount(response?.data?.result?.totalClosedLeadsCount)
          setReportsLoader(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error while getting report");
          setReportsLoader(false);
        });
    }
  };


  //-------- GEO CHART DATA 
  const regionData = {
    "Andaman & Nicobar Island": {
      value: 0,
      title: "nirbhay",
    },
    "Andhra Pradesh": {
      value: 0,
    },
    "Arunanchal Pradesh": {
      value: 0,
    },
    "Assam": {
      value: 0,
    },
    "Bihar": {
      value: 0,
    },
    "Chandigarh": {
      value: 0,
    },
    "Chhattisgarh": {
      value: 0,
    },
    "Delhi": {
      value: 0,
    },
    "Goa": {
      value: 0,
    },
    "Gujarat": {
      value: 0,
    },
    "Haryana": {
      value: 0,
    },
    "Himachal Pradesh": {
      value: 0,
    },
    "Jammu & Kashmir": {
      value: 0,
    },
    "Jharkhand": {
      value: 0,
    },
    "Karnataka": {
      value: 0,
    },
    "Kerala": {
      value: 0,
    },
    "Lakshadweep": {
      value: 0,
    },
    "Madhya Pradesh": {
      value: 0,
    },
    "Maharashtra": {
      value: 0,
    },
    "Manipur": {
      value: 0,
    },
    "Meghalaya": {
      value: 0,
    },
    "Mizoram": {
      value: 0,
    },
    "Nagaland": {
      value: 0,
    },
    "Odisha": {
      value: 0,
    },
    "Puducherry": {
      value: 0,
    },
    "Punjab": {
      value: 0,
    },
    "Rajasthan": {
      value: 0,
      title: "nirbhay",
    },
    "Sikkim": {
      value: 0,
    },
    "Tamil Nadu": {
      value: 0,
    },
    "Telangana": {
      value: 0,
    },
    "Tripura": {
      value: 0,
    },
    "Uttar Pradesh": {
      value: 0,
    },
    "Uttarakhand": {
      value: 0,
    },
    "West Bengal": {
      value: 0,
    },
  };

  function mergeLeadCountWithRegionData(data, regionData) {
    return Object.keys(regionData).reduce((mergedData, state) => {
      const matchingItem = data.find((item) => item.state === state);

      if (matchingItem) {
        mergedData[state] = { ...regionData[state], value: matchingItem.lead_count };
      } else {
        mergedData[state] = { ...regionData[state], value: 0 };
      }

      return mergedData;
    }, {});
  }

  var geoChartMainData = []
  geoChartMainData = mergeLeadCountWithRegionData(totalGeoChartData, regionData)


  //-------- Sales Conversion Chart Data
  function getsalesConvExeAllLabels(data) {
    return data.map(item => item.month);
  }
  const salesConverstionLabels = getsalesConvExeAllLabels(salesConversion);
  function getSalesConversionExeValuesByKey(data, key) {
    return data.map(item => item[key]);
  }

  const salesConversionValues = getSalesConversionExeValuesByKey(salesConversion, "customer_count");
  const data = {
    labels: salesConverstionLabels.reverse(),
    datasets: [
      {
        label: 'Sales Dataset',
        data: salesConversionValues.reverse(),
        borderColor: '#BA1F21',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <Box sx={{ width: "80%" }} p={2} height={"95vh"} ml={"18vw"} mt={0}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div
            style={{
              fontSize: "45px",
              color: "black",
              fontWeight: "bold",
              fontFamily: "Kamerik",
              margin: "15px 0px",
            }}
          >
            Reports
          </div>
        </div>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item>
                <div style={{ display: "flex", gap: 20, alignItems: "" }}>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <StyledDatePicker
                      sx={{
                        width: "40%",
                        marginBottom: "20px",
                        borderColor: "green",
                        "& .MuiPickersDay-daySelected": {
                          background: "none",
                          color: "inherit",
                        },
                      }}
                      label="Start Date"
                      name="remindDate"
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <StyledDatePicker
                      sx={{
                        width: "40%",
                        marginBottom: "20px",
                        borderColor: "green",
                        "& .MuiPickersDay-daySelected": {
                          background: "none",
                          color: "inherit",
                        },
                      }}
                      label="End Date"
                      name="remindDate"
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </LocalizationProvider>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: "55px", width: "20%" }}
                    onClick={() => {
                      getReport();
                    }}
                  >
                    Get Report
                  </Button>
                </div>
              </Item>
            </Grid>
            {/* <Grid item xs={4}>
              <Item></Item>
            </Grid> */}
            <Grid item xs={7}>
              <Item style={{ height: "525px" }}>
                <h2 style={{ color: "black", display: "flex", alignItems: "start", fontWeight: "500" }}>Sales Performance Chart</h2>
               <div>
                {
                  reportsLoader ? (
                    <div style={{ height: "400px" }}>
                      <div style={{ top: "50%", position: "relative" }}>
                        <CircularProgress
                          size="3.5rem"
                          style={{ color: "black", }}
                        />
                      </div>
                    </div>
                  ) : (
                    <Line data={data} options={options} />
                  )
                }
               </div>

              </Item>
            </Grid>
            <Grid item xs={5}>
              <Item>
                <div style={{ position: "relative" }} className="chart-india">
                  {
                    reportsLoader ? (

                      <div style={{ top: "24vh", left: "15vw", position: "absolute" }}>
                        <CircularProgress
                          size="3.5rem"
                          style={{ color: "black", }}
                        />
                      </div>
                    ) : (
                      <DatamapsIndia
                        style={{ postion: "relative", left: "25%" }}
                        regionData={geoChartMainData}
                        hoverComponent={({ value }) => {
                          return (
                            <div style={{ top: "100px" }}>
                              <div>
                                {value.name} {value.value}
                              </div>
                            </div>
                          );
                        }}
                        mapLayout={{
                          legendTitle: "Leads Distribution",
                          startColor: "#fff",
                          endColor: "#005ce6",
                          hoverTitle: "Count",
                          noDataColor: "#fff",
                          borderColor: "#8D8D8D",
                          hoverColor: "blue",
                          hoverBorderColor: "green",
                          height: 10,
                          weight: 30,
                        }}
                      />
                    )
                  }
                </div>
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <div className="feed-info-grid">
                  <Grid
                    className="feed-info-cards feed-info-card-blue"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh" }}
                        src={customerIcon}
                        alt="Progress Leads Icon"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Total Leads</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            totalLeadsCount || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-green"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh" }}
                        src={openLeadIcon}
                        alt="Progress Leads Icon"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Total Open Leads</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            openLeadsCount || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid className="feed-info-cards feed-info-card-red" ml={1.2}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh" }}
                        src={progressFunnel}
                        alt="Progress Leads Icon"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Total On Progress Leads</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            oProgressLeadsCount || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-yellow"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh", color: "white" }}
                        src={closedLeadsIcon}
                        alt="closedLeadsIcon"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Total Closed Leads</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            closedLeadsCount || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-grey"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh" }}
                        src={dueFollowupIcon}
                        alt="Progress Leads Icon"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Total Due Followups</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            dueFollowUpsLeadsCount || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-pink"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh", color: "white" }}
                        src={officeVisitIcon}
                        alt="officeVisitIcon"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Total Office Visits</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            totalOfficeCount || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-leafGreen"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh", color: "white" }}
                        src={avgIVRCallsIcon}
                        alt="avgIVRCallsIcon"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Avg Total Calls/Day</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            averageIVRCallsPerDay || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-brown"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh", color: "white" }}
                        src={totalIVRCallsIcons}
                        alt="totalIVRCallsIcons"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Avg Answered Calls/Day</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            averageIVRAnsweredCallsPerDay || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-reddark"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh", color: "white" }}
                        src={totalIVRMissedCallsAvg}
                        alt="totalIVRMissedCallsAvg"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Avg Missed Calls/Day</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            averageIVRMissedCallsPerDay || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    className="feed-info-cards feed-info-card-blue2"
                    ml={1.2}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <img
                        style={{ height: "7vh", color: "white" }}
                        src={callsIcon}
                        alt="totalIVRMissedCallsAvg"
                      />
                      <div style={{ color: "white", marginLeft: "10px" }}>
                        <h3>Total Calls Count</h3>
                        <h1>
                          {reportsLoader ? (
                            <CircularProgress
                              size="1.5rem"
                              style={{ color: "white" }}
                            />
                          ) : (
                            totalIVRCallsCount || 0
                          )}
                        </h1>
                      </div>
                    </div>
                  </Grid>
                </div>
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <div>
                  <div style={{ width: '100%', margin: 'auto', height: "auto" }}>
                    <div style={{ color: "black", marginBottom: "10px", fontFamily: "Poppins" }}>Leads Source Distribution</div>
                    {reportsLoader ? (
                      <div style={{ height: "400px" }}>
                        <div style={{ top: "50%", position: "relative" }}>
                          <CircularProgress
                            size="3.5rem"
                            style={{ color: "black", }}
                          />
                        </div>
                      </div>
                    ) : (
                      <Pie data={PieChartData} />
                    )

                    }
                  </div>
                </div>
              </Item>
            </Grid>

            <Grid item xs={4}>
              <Item>
                <div>
                  <div style={{ width: '100%', margin: 'auto', height: "auto" }}>
                    <div style={{ color: "black", marginBottom: "10px", fontFamily: "Poppins" }}>Sales Executive Leads Distribution</div>
                    {reportsLoader ? (
                      <div style={{ height: "400px" }}>
                        <div style={{ top: "50%", position: "relative" }}>
                          <CircularProgress
                            size="3.5rem"
                            style={{ color: "black", }}
                          />
                        </div>
                      </div>
                    ) : (
                      <Pie data={salesExecutiveChartData} />
                    )

                    }
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ToastContainer />
    </>
  );
};
export default Reports;
