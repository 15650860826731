import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard.js";
import jwt_decode from "jwt-decode";
import Protected from "./Pages/Protected";
import SignInV2 from "./Pages/SignInV2";
import Leads from "./Pages/Dashboard/Leads.js";
import Customers from "./Pages/Dashboard/Customers.js";
import Feed from "./Pages/Dashboard/Feed.js";
import AddLead from "./Pages/AdditionalPages/AddLead.js";
import VehicleModels from "./Pages/AdditionalPages/VehicleModels.js";
import Users from "./Pages/AdditionalPages/Users.js";
import { AuthContext } from './AuthContext.js';
import StockList from "./Pages/AdditionalPages/StockList.js";
import CallLogs from "./Pages/AdditionalPages/CallLogs.js";
import ChangeLogs from "./Pages/AdditionalPages/ChangeLog.js";
import ClientWaitlist from "./Pages/AdditionalPages/ClientWaitlist.js";
import TaskReminder from "./Pages/AdditionalPages/TaskReminder.js";
import AdminPanel from "./Pages/AdditionalPages/AdminPanel.js";
import CarFacts from "./Pages/AdditionalPages/CarFacts.js";
import ClosingReasons from "./Pages/AdditionalPages/ClosingReasons.js";
import StockInOutHistory from "./Pages/AdditionalPages/StockInOutHistory.js";
import Reports from "./Pages/AdditionalPages/Reports.js";


function AppRoute() {
  const { isAuthenticated, userData, setAuthData, clearAuthData } = React.useContext(AuthContext);
  const jwtVerify = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        throw new Error("Token not found"); // You can customize the error message
      }
  
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.clear();
        alert("Session Expired");
      } else {
        console.log("Valid token");
      }
    } catch (error) {
      // console.error("Error:", error);
      // Handle the error here as needed, for example, you can redirect the user to a login page.
    }
  };
  

  useEffect(() => {
    jwtVerify();
  }, []);

  return (
    <Router>
      <Routes>
      {/* Protected Routes */}
        <Route exact path="/dashboard" element={<Protected  Component={Dashboard} />}/>
        <Route path="/leads" element={<Protected Component={Leads} />} />
        <Route path="/addlead" element={<Protected Component={AddLead} />} />
        <Route path="/VehicleModels" element={<Protected Component={VehicleModels} />} />
        <Route path="/Users" element={<Protected Component={Users} />} />
        <Route path="/Customers" element={<Protected Component={Customers} />} />
        <Route path="/Stocklist" element={<Protected Component={StockList} />} />
        <Route path="/callLogs" element={<Protected Component={CallLogs} />} />
        <Route path="/changeLogs" element={<Protected Component={ChangeLogs} />} />
        <Route path="/clientWaitlist" element={<Protected Component={ClientWaitlist} />} />
        <Route path="/taskReminder" element={<Protected Component={TaskReminder} />} />
        <Route path="/adminPanel" element={<Protected Component={AdminPanel} />} />
        <Route path="/carFacts" element={<Protected Component={CarFacts} />} />
        <Route path="/closingReasons" element={<Protected Component={ClosingReasons} />} />
        <Route path="/stockInOutHistory" element={<Protected Component={StockInOutHistory} />} />
        <Route path="/reports" element={<Protected Component={Reports} />} />


      {/* Public Routes */}
        <Route exact path="/" element={isAuthenticated?<Protected Component={Dashboard} />:<SignInV2 />} />

      </Routes>
    </Router>
  );
}

export default AppRoute;
