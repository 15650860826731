import axios from "axios";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import { Grid } from "@mui/material";
import Paper from '@mui/material/Paper';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function ChangeLog(props) {

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <VersionUpdateChild />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}
const VersionUpdateChild = () => {

    // Datagrid Columns
   
  return (
    <>
      <Box flex={4} style={{borderRadius:"10px"}}  p={2} ml={"18vw"} mt={4}>

      <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
      <Grid item xs={6} md={12}>
         <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start",marginBottom:"4vh"}}>
         <div style={{fontSize:"5rem",fontFamily:"Kamerik",color:"black"}}>Change logs&nbsp;</div>
          <div style={{color:"black"}} >"Discover the evolution of our web app, one update at a time!</div>
         </div>
        </Grid>



        <Grid item xs={6} md={3}>
          <Item sx={{padding:"20px",display:"flex",alignItems:"flex-start",flexDirection:"column"}}>
            <div style={{
            fontSize:"20px",
            fontWeight:"bold",
            fontFamily:"Kamerik",
            color:"black",}}>Features updates: Version 3.0</div>
          <p>September 2023</p>
          </Item>
        </Grid>
        <Grid item xs={6} md={9}>
          <Item sx={{display:"flex",flexDirection:"column",alignItems:"flex-start",padding:"20px"}}>
            <div style={{fontFamily:"Kamerik",fontWeight:"bold",fontSize:"20px",color:"black"}}>Features 🚀</div>
            <div style={{textAlign:"start"}}>
              <li>Cloned Local Database for testing</li>
              <li>Added Open, Closed, Due follow-ups, and client info cards in the feed</li>
              <li>Added interesting Car Trivia Facts</li>
              <li>Added Task Reminder</li>
              <li>Grapgh added for visualizing data for leads, calls, and customer visits in the feed</li>
              <li>Added StockIn&Out Section in AdminPanel </li>
              <li>Added Leads Closing Reasons</li>
              <li>Improved responsiveness for a better user experience</li>
              <li>Added ChangeLogs Page</li>
              <li>Implemented an AdminPanel, centralizing root-level control.</li>

              </div>


              <div style={{fontFamily:"Kamerik",fontWeight:"bold",fontSize:"20px",color:"black",marginTop:"15px"}}>Bugs 🐞</div>
            <div style={{textAlign:"start"}}>
              <li>Add Customer Button replaced by edit after converting customer</li>
              <li>White editing a customer the existing brands data is not displaying in the modal list suggestions</li>
              <li>Disbaled button after adding or updating data- for preventing duplicacy</li>
              <li>Fixed customer convert issue just after creating a new lead</li>
              <li>Fixed Date Picker Highlighted Red</li>

              </div>
          </Item>
        </Grid>




      </Grid>

      
    </Box>
   

    </Box>




    </>
  );
};
export default ChangeLog;
