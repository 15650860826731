import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar,GridToolbarQuickFilter,
  GridLogicOperator,
  GridOverlay,
 } from "@mui/x-data-grid";
 import CallIcon from '@mui/icons-material/Call';
 import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
 import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import AddchartIcon from '@mui/icons-material/Addchart';
import CallReceivedIcon from '@mui/icons-material/CallReceived';




const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius:"10px",
    '& .wrap-text': {
      whiteSpace: 'normal',
    },

  
  border: 0,
  '& .MuiDataGrid-cell:focus': {
   outline:"none"
  },
  '& .MuiDataGrid-toolbarContainer':{
    gap:15,
    padding :"8px 8px 8px !important",
    background:"linear-gradient(to right, #434343 0%, black 100%) !important",
    borderRadius:"10px 10px 0px 0px !important",
  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
    color:"white"
  },
  '& .css-1rtnrqa':{
    color:"white"
  },
  '& .css-68pk0f':{
    border: '1px solid white !important',
    borderRadius: '5px',
    padding:'2px 5px',
  },
  '& .css-zrqpq1':{
    color:'white'
  },
  '& .css-zrqpq1::after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
    border:"1px solid white !important",
    borderRadius:"5px",
    padding:"2px 5px",
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus":{
    outline:"none"
  },
  "& .MuiDataGrid-cell:focus-within":{
    outline:"none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
    outline:"none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
    fontSize:"17px"
  }
  
 
}));

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "white",
  boxShadow: 24,
  color:"Black !important",
  borderRadius:"10px",
  padding:"0px 0px 10px 0px"
};



function ClientWaitlist(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <ClientWaitListTable />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}



const ClientWaitListTable = () => {

    const [stockAlert, setStockAlert] = React.useState(0)
    const [cleanStockList,setCleanStockList]=React.useState([])
    const [isLoading, setisLoading] = React.useState(false)
    const [waitingClient,setWaitingClient]=React.useState([]);
    const [ModalOpen,setModalOpen] = React.useState(false)
    const [isLeadSaving,setIsLeadSaving] = React.useState(false)


    const columns = [
        {
            field: "col0",
            headerName: "Actions",
            flex: 0.5,
            sortable: false,
            filterable: false,
            renderCell: (cellValues) => {
              return (
                <div style={{ display: "flex", gap: 10 }}>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={()=>{
                      setWaitingClient({
                        lead_id:cellValues.row.lead_id,
                        phone:cellValues.row.phone,
                        activityLog:cellValues.row.activityLog,
                        carAvailable:"Yes"
                      });
                      setModalOpen(true)
                    }}

                  >
                    Update
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="error"
                    onClick={()=>{alert("Hey")}}
                  >
                    Delete
                  </Button> */}
                </div>
              );
            },
          },
        {
          field: "customerName",
          sortable: false,
          headerName: "Name",
          flex:1,
        },
        {
            field: "phone",
            sortable: false,
            headerName: "Phone",
            flex:1,
          },
          {
            field: "vehicleBrands",
            sortable: false,
            headerName: "Brand",
            flex:1,
            renderCell: (params) => {
                const valueFormatted = JSON.parse(params.value);
                 return (
                  <>
                 <div style={{overflow:"auto"}}>
                 {valueFormatted.map((value) => (
                  <Chip key={value} label={value} sx={{backgroundColor:"#1a5ea3",color:"white",marginRight:"3px"}} />
                ))}
                 </div>
                  </>
                 )
                
              },
          },
          {
            field: "vehicleModels",
            sortable: false,
            headerName: "Model",
            flex:1,
            renderCell: (params) => {
                const valueFormatted = JSON.parse(params.value);
                 return (
                  <>
                  <div style={{overflow:"auto"}}>
                  {valueFormatted.map((value) => (
                  <Chip key={value} label={value} sx={{backgroundColor:"#1a5ea3",color:"white",marginRight:"3px"}} />
                ))}
                </div>
                  </>
                 )
                
              },
          },
          {
            field: "budget",
            sortable: false,
            headerName: "Budget",
            flex:0.7,
          },
          {
            field: "salesExecutive",
            sortable: false,
            headerName: "Executive",
            flex:0.7,
          },
          {
            field: "activityLog",
            sortable: false,
            headerName: "Activity Log",
            flex:1,
            renderCell: (params) => (
                <div style={{ overflow: "auto", maxHeight: "100px" }}>
                  {params.value}
                </div>
              ),
          },

      ];
      


// --------- Code begins
const extractModels = (stockData) => {
    const resultArray = [];
  
    for (const item of stockData) {
      if (item.models.trim() !== "") {
        const words = item.models.split(" ");
        resultArray.push(...words.map((word) => word.toLowerCase()));
      }
    }
  
    return resultArray;
  };
  
  const getAllCurrentStockList = async () => {
    setisLoading(true)
    const response = await axios.get(
      `https://script.googleusercontent.com/macros/echo?user_content_key=R27rhGtYzdIGon5oxkzY-rppLXWA9_159JDmu2kZSXM-_nnveJhFBIZ2Z9o_310FN1mXQf3aIRUAauyHyUwmP0yXnwaM5CGYm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnHOOD1tvdUBNp_hOhF1i9gbxoLRateUaSIABpS2ZmbVwxz-4ciWij4xw-OYEfsdrcMC9HcUnVa3XaNSXizU3TnqZ1BAInFy88Nz9Jw9Md8uu&lib=MdKhVHcarN4bRqwHHuf7rfO_p5hnRjXr_`
    );
    const models = extractModels(response.data.data);
    return models;
  };
  
  const fetchClientList = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          auth: token,
        },
      };
  
      const models = await getAllCurrentStockList();
      const salesExecutive = localStorage.getItem('fullName');

      const result = await axios.post("/clientWaitList", {roleId:localStorage.getItem('roleId'),Name:salesExecutive ,models }, config);
  
      // Rest of your code
      setStockAlert(result.data.findClientWaitingDataCount)
      setCleanStockList(result)
      setisLoading(false)
    } catch (error) {
      console.error(error);
    }
  };
  



  // ----- Update Client Data Section

  const handleModalClose = () => {
    setModalOpen(false);
   setWaitingClient([])
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setWaitingClient({
      ...waitingClient,
      [evt.target.name]: value,
    });
  }

  const updateWaitingClient = async () => {
    setIsLeadSaving(true);

    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
  
    function isInvalid(value) {
      return value === undefined || value.length === 0;
    }
  
    if (isInvalid(waitingClient.activityLog)) {
      toast.error("Please fill all required fields!");
      setIsLeadSaving(false);
    } else {
      try {
        await axios.put("/updateLead", waitingClient, config);
        toast.success("Lead Updated Successfully!");
        setIsLeadSaving(false);
        setWaitingClient([]);
        setModalOpen(false)
        await fetchClientList(); // Call the fetchData function here
        localStorage.setItem("StockAlert",stockAlert)
      } catch (err) {
        if (err.response.data.error === "Customer Does Not Exists") {
          toast.error("Customer Does Not Exist");
        } else {
          toast.error("Customer Cannot Be Updated");
        }
        setIsLeadSaving(false);
      }
    }
  };
  
  // Define the fetchData function
  const fetchData = async () => {
    try {
      await fetchClientList();
    } catch (error) {
      console.error(error);
    }
  };
  
  React.useEffect(() => {
    fetchData(); // Call fetchData in the useEffect
  }, []);

  return (
    <>
      <Box
        sx={{ width: "80%" }}
        p={2}
        height={"100vh"}
        ml={"18vw"}
        mt={0}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Client Waitlist</div>


        </div>
        <StyledDataGrid
          rows={cleanStockList?.data?.data||[]}
          columns={columns}
          pageSize={50}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
        
          sortModel={[
            {
              field: 'dateTime', // Replace 'timestamp' with the actual field you want to sort by
              sort: 'desc',       // 'desc' for descending order, 'asc' for ascending order
            } 
          ]}
          rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
          sx={{ height: "92%", bgcolor: "white" }}
        />
      </Box>



      <Sidebar stockAlert={stockAlert} />


      <Modal
        open={ModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}         style={{padding:"20px"}}
> 
          <h2 style={{ color: "black", marginBottom: "10px" }}>
            Update Activity
          </h2>
        

          <TextField
                  sx={{
                    margin: "0.8vh",
                    width: "96.5%",
                    bgcolor: "#F2F8FC",
                    borderRadius: "5px",
                  }}
                  id="filled-basic"
                  label="Activity Log"
                  variant="filled"
                  multiline
                  rows={8}
                  name="activityLog"
                  onChange={handleChange}
                  value={waitingClient.activityLog || ""}
                />
          <Box>
          <Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={updateWaitingClient}
              disabled={isLeadSaving?true:false}
            >
              {isLeadSaving?<CircularProgress size="1rem" sx={{marginRight:"10px"}} color="inherit" />:<></>}
              Update Lead
            </Button>
            
          </Box>
        </Box>
      </Modal>



      <ToastContainer />
    </>
  );
};
export default ClientWaitlist;
