import axios from "axios";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import { Box } from "@mui/system";
import { styled } from '@mui/material/styles';
import { Grid } from "@mui/material";
import Paper from '@mui/material/Paper';
import usersIcon from '../../Images/admin-users.png'
import modelsIcon from '../../Images/admin-models.png'
import carInfoIcon from '../../Images/admin-info.png'
import reportsIcon from '../../Images/admin-reports.png'
import closeReason from '../../Images/CloseReasons.png'
import stockInOutHistory from '../../Images/stockInOutHistory.png'

import "./Styles/AdminPanel.css";
import { Navigate, useNavigate } from "react-router-dom";





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function AdminPanel(props) {

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <AdminConsole />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}
const AdminConsole = () => {
    const navigate = useNavigate();

    const customNavigate = (path) => {
        navigate(`/${path}`) 
      };
   
  return (
    <>
      <Box flex={4} style={{borderRadius:"5px"}} bgcolor="#fff" p={0} ml={"18vw"} mt={4}>
    {/* <div 
    style={{
        fontSize:"5vh",
        color:"white",
        background: "radial-gradient(circle at 10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%)",
        borderRadius:"10px 0px 0px 0px",
        padding:"0px 0px 0px 20px",
       
    }}
    >Admin Console</div> */}
                    <div style={{fontSize:"45px",color:"white",fontWeight:"bold",fontFamily:"Kamerik",margin:"0px",padding:"20px 0px 20px 20px",
                    background: "radial-gradient(circle at 10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%)",
}} >Admin Console</div>

      <Box sx={{ flexGrow: 1,padding:"2vh" }}>
      <Grid container spacing={2}>
  <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("Users")
      }} >
        <img src={usersIcon} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Users</b></h3>
        <h4>Add or update users</h4>
      </div>
    </Item>
  </Grid>
  <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("VehicleModels")
      }} >
        <img src={modelsIcon} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Models</b></h3>
        <h4>Manage Vehicle Models</h4>
      </div>
    </Item>
  </Grid>
  <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("carFacts")
      }} >
        <img src={carInfoIcon} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Car Facts</b></h3>
        <h4>Manage Car Facts</h4>
      </div>
    </Item>
  </Grid>
  <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("reports")
      }}>
        <img src={reportsIcon} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Reports</b></h3>
        <h4>Monitor reports across all categories</h4>
      </div>
    </Item>
  </Grid>

  <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("closingReasons")
      }} >
        <img src={closeReason} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Closing Reasons</b></h3>
        <h4>Add or update reasons</h4>
      </div>
    </Item>
  </Grid>


  <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("stockInOutHistory")
      }} >
        <img src={stockInOutHistory} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Stock History</b></h3>
        <h4>Manage Vehicle In & Out History</h4>
      </div>
    </Item>
  </Grid>


  {/* <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("VehicleModels")
      }} >
        <img src={modelsIcon} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Models</b></h3>
        <h4>Manage Vehicle Models</h4>
      </div>
    </Item>
  </Grid>
  <Grid item md={3}>
    <Item className="box">
      <div onClick={()=>{
        customNavigate("carFacts")
      }} >
        <img src={carInfoIcon} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Car Facts</b></h3>
        <h4>Manage Car Facts</h4>
      </div>
    </Item>
  </Grid>
  <Grid item md={3}>
    <Item className="box">
      <div >
        <img src={reportsIcon} style={{height:"6vh"}} alt="Image Description" />
        <h3><b>Reports</b></h3>
        <h4>Monitor reports across all categories</h4>
      </div>
    </Item>
  </Grid> */}





</Grid>






    </Box>
   

    </Box>




    </>
  );
};
export default AdminPanel;
