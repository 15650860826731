import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar,GridToolbarQuickFilter,
  GridLogicOperator,
  GridOverlay,
 } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";

const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius:"10px",
    '& .wrap-text': {
      whiteSpace: 'normal',
    },

  
  border: 0,
  '& .MuiDataGrid-cell:focus': {
   outline:"none"
  },
  '& .MuiDataGrid-toolbarContainer':{
    gap:15,
    padding :"8px 8px 8px !important",
    background:"linear-gradient(to right, #434343 0%, black 100%) !important",
    borderRadius:"10px 10px 0px 0px !important",
  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
    color:"white"
  },
  '& .css-1rtnrqa':{
    color:"white"
  },
  '& .css-68pk0f':{
    border: '1px solid white !important',
    borderRadius: '5px',
    padding:'2px 5px',
  },
  '& .css-zrqpq1':{
    color:'white'
  },
  '& .css-zrqpq1::after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
    border:"1px solid white !important",
    borderRadius:"5px",
    padding:"2px 5px",
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus":{
    outline:"none"
  },
  "& .MuiDataGrid-cell:focus-within":{
    outline:"none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
    outline:"none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
    fontSize:"17px"
  }
  
 
}));


function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid ",
  boxShadow: 24,
  p: 4,
};



function ClosingReasons(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <ReasonsTable />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}


// function CustomLoadingOverlay() {
//   return (
//     <GridOverlay>
//       {/* <Skeleton variant="rect" width="100%" height="100%" /> */}
//     </GridOverlay>
//   );
// }

const ReasonsTable = () => {

    

  const renderCell = (params) => {
    const { field, value } = params;
    if (isLoading) {
      return <Skeleton variant="rec" width={50} height={20} />;
    } else {
      return value;
    }
  };
  // Datagrid Columns 
    const columns = [
        {
          field: "col0",
          headerName: "Actions",
          width: 220,
          sortable: false,
          filterable: false,
          flex:0.25,
          renderCell: (cellValues) => {
            return (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={(event) => {
                    handleDelete(event,cellValues);
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          },
        },

        {
          field: "uuid",
          sortable: false,
          headerName: "UUID",
          width: 150,
          flex:0.5,
        },
        {
            field: "reason",
            sortable: false,
            headerName: "REASON",
            width: 210,
            flex:1
          },
          
        { field: "createdAt", sortable: false, headerName: "CreatedAt", width: 210,flex:0.5,
        valueFormatter: (params) => {
          const valueFormatted = new Date(params.value).toDateString();
          return `${valueFormatted}`;
        }, },
        
      ];
      



      const [open, setOpen] = React.useState(false)

  const [reasons, setReasons] = React.useState([])
  const [isLoading, setisLoading] = React.useState(false)
  const [newReason, setNewReason] = React.useState({});
 







  const handleOpen = () => {
    setNewReason([])
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
  };




  function handleChange(evt) {
    const value = evt.target.value;
    setNewReason({
      ...newReason,
      [evt.target.name]: value,
    });
  }

 
     async function handleDelete(Event,Value){

        if (window.confirm("You surely want to delete this Reason?")) {
          await deleteUser(Value.row.uuid) 
        }      // await deleteUser(Value.row.uid) 
        else{
          await getAllReasons()
        }
     }


   const getAllReasons = async () => {
            setisLoading(true)
            const token = localStorage.getItem("accessToken");
            const config = {
              headers: {
                auth: token,
              },
            };
             axios
              .get("/closingReasons", config)
              .then((response) => {
                setReasons(response.data.data);
                setisLoading(false)
              });
  };
  React.useEffect(() => {
    getAllReasons();
  }, []);

  const addNewReason = async () => {
    setisLoading(true)
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .post("/addLeadClosingReason", newReason, config)
      .then((response) => {

        toast.success("Reason Added Successfully!");
        setOpen(false)
        setisLoading(false)
        getAllReasons()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "Reason Already Exists") {
          toast.error("Reason Already Exists");
          setisLoading(false)
        } else {
          toast.error("Reason Cannot Be Added");
          setisLoading(false)
        }
      });
  };
 

  const deleteUser = async (uuid) => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .delete(`/deleteLeadClosingReason/${uuid}`,  config)
      .then((response) => {
        toast.success("Reason Deleted Successfully!");
        setOpen(false)
        getAllReasons()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "User Already Exists") {
          toast.error("Reason Already Exists");
        } else {
          toast.error("Reason Cannot Be Deleted");
        }
      }); 
  }

  return (
    <>
      <Box
        sx={{ width: "80%" }}
        p={2}
        height={"85vh"}
        ml={"18vw"}
        mt={0}
      >
        <div style={{ display: "flex", justifyContent: "space-between",alignItems:"baseline" }}>
        <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Manage Leads Closing</div>

<div>

          <Button
            variant="contained"
            sx={{ height: "40px", width: "10vw" }}
            onClick={handleOpen}
          >
            Add Reason
          </Button>
</div>
        </div>
        <StyledDataGrid
          rows={reasons}
          columns={columns}
          pageSize={50}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
          rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
          sx={{ height: "90vh", bgcolor: "white" }}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ color: "black", marginBottom: "10px" }}>
           Add Closing Reason
          </h2>


          {/* <TextField
            id="filled-basic"
            label="Lead Id"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="lead_id"
            onChange={handleChange}
          /> */}
          <TextField
            id="filled-basic"
            label="Reason"
            variant="filled"
            multiline
            rows={4}
            sx={{ width: "100%", marginTop: "20px" }}
            name="reason"
            onChange={handleChange}
          />
          <Box>
          <Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={addNewReason}
              disabled={isLoading?true:false}
            >
              Add Reason
            </Button>
            
          </Box>
        </Box>
      </Modal>



    



      <ToastContainer />
    </>
  );
};
export default ClosingReasons;
