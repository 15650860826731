import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "./DashNav";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "./Sidebar";
import Feed from "./Feed";
import Leads from "./Leads";
import ClientWaitlist from "../AdditionalPages/ClientWaitlist";

function Dashboard(props) {
  const navigate = useNavigate();

  const getUsers = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    const userData = axios.get("/users",config
    );
  };

  const logout = async () => {
    localStorage.clear();
    navigate("/");
  };






  return (
    <>

      <Stack direction="row" justifyContent="space-between">
        <Sidebar  />
        <Feed />
        
      </Stack>
    </>
  );
}

export default Dashboard;
