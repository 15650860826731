import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import "./Styles/Signin.css";
import { Button, TextField } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Appbar from "../Components/Appbar/Appbar";
import { AuthContext } from '../AuthContext.js';


function SignInV2() {
  const { isAuthenticated, userData,userDetails,setUserDetails, setAuthData, clearAuthData } = React.useContext(AuthContext);

  const [username, setusername] = useState();
  const [password, setpassword] = useState();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const signIn = async () => {
    setisLoading(true);
    localStorage.clear();
    if (!username) {
      toast.error("Please Enter Valid Username");
    } else if (!password) {
      toast.error("Please Enter Valid Password");
    } else {
      axios
        .post("/login", {
          username: username,
          password: password,
        })
        .then((response) => {
          setisLoading(false);
          toast.success("Logged In Successfully!");

          localStorage.setItem("accessToken", response.data.AccessToken);
          localStorage.setItem("roleId", response.data.roleId);
          localStorage.setItem("profileImg", response.data.profileImg);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("fullName", response.data.fullName);
          const Token = localStorage.getItem('accessToken');
          setTimeout(() => {
            // ------ EXPERIMENTAL LOGIN SECURITY -------
            // setUserDetails({
            //   'accessToken':response.data.AccessToken,
            //   'roleId':response.data.roleId,
            //   'profileImg':response.data.profileImg,
            //   'username':response.data.username
            // });
          setAuthData(Token)
          navigate("/dashboard");
          }, 2000);

        })
        .catch((err) => {
          setisLoading(false);
          toast.error("Wrong Username or Password");
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      signIn();
    }
  };

  return (
    <>
    <div style={{background:"#0F1319",height:"100vh"}} >
    <Appbar/>
      <Box sx={{ mx: "auto", width: 500 }}>
        <Stack sx={{ mt: 5, alignItems: "center" }} spacing={2}>
         
          <h1>
            <span>
              <LoginIcon />{" "}
            </span>
            <Typography variant="h7" sx={{ color: "#fff" }}>
              Login To Get Started
            </Typography>{" "}
          </h1>

          <div className="signIn_fields">
            <hr className="signIn-hr"></hr>
            <h1 className="signin-label-text">Username</h1>
            <TextField
              className="signin-fields"
              id="outlined-basic"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              sx={{ input: { color: "white" } }}
              autoComplete="off"
              onChange={(e) => {
                setusername(e.target.value);
              }}
              style={{
                marginTop: "2px",
                paddingLeft: "10px",
                width: "280px",
              }}
              onKeyPress={(e) => {
                handleKeyPress(e);
              }}
            />
            <h1 className="signin-label-text">Password</h1>
            <TextField
              className="signin-fields"
              id="outlined-basic-2"
              variant="standard"
              type="password"
              sx={{ input: { color: "white" } }}
              InputProps={{
                disableUnderline: true,
              }}
              autoComplete="off"
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              style={{
                marginTop: "2px",
                paddingLeft: "10px",
                width: "280px",
              }}
              onKeyPress={(e) => {
                handleKeyPress(e);
              }}
            />

            <Button
              className="sign-in-btn"
              variant="contained"
              onClick={() => signIn()}
            >
              {isLoading ? (
                <>
                  <CircularProgress
                    style={{
                      color: "white",
                      verticalAlign: "middle",
                      marginRight: "10px",
                      marginBottom: "3px",
                    }}
                    size="1rem"
                  />
                </>
              ) : (
                <></>
              )}
              Sign In
            </Button>
          </div>
        </Stack>
      </Box>

      <ToastContainer />

      </div>
    </>
  );
}

export default SignInV2;
