import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar,GridToolbarQuickFilter,
  GridLogicOperator,
  GridOverlay,
 } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius:"10px",
    '& .wrap-text': {
      whiteSpace: 'normal',
    },

  
  border: 0,
  '& .MuiDataGrid-cell:focus': {
   outline:"none"
  },
  '& .MuiDataGrid-toolbarContainer':{
    gap:15,
    padding :"8px 8px 8px !important",
    background:"linear-gradient(to right, #434343 0%, black 100%) !important",
    borderRadius:"10px 10px 0px 0px !important",
  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
    color:"white"
  },
  '& .css-1rtnrqa':{
    color:"white"
  },
  '& .css-68pk0f':{
    border: '1px solid white !important',
    borderRadius: '5px',
    padding:'2px 5px',
  },
  '& .css-zrqpq1':{
    color:'white'
  },
  '& .css-zrqpq1::after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
    border:"1px solid white !important",
    borderRadius:"5px",
    padding:"2px 5px",
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus":{
    outline:"none"
  },
  "& .MuiDataGrid-cell:focus-within":{
    outline:"none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
    outline:"none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
    fontSize:"17px"
  }
  
 
}));


function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid ",
  boxShadow: 24,
  p: 4,
};



function StockInOutHistory(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <StockHistoryTable />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}


// function CustomLoadingOverlay() {
//   return (
//     <GridOverlay>
//       {/* <Skeleton variant="rect" width="100%" height="100%" /> */}
//     </GridOverlay>
//   );
// }

const StockHistoryTable = () => {

    

  const renderCell = (params) => {
    const { field, value } = params;
    if (isLoading) {
      return <Skeleton variant="rec" width={50} height={20} />;
    } else {
      return value;
    }
  };
  // Datagrid Columns 
    const columns = [
        {
          field: "col0",
          headerName: "Actions",
          width: 220,
          sortable: false,
          filterable: false,
          flex:0.6,
          renderCell: (cellValues) => {
            return (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={(event) => {
                    handleDelete(event,cellValues);
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          },
        },
        // { field: "id", sortable: false, headerName: "id", width: 300,          sortable: false,
        // filterable: false, },
        {
          field: "uuid",
          sortable: false,
          headerName: "UUID",
          width: 150,
          flex:1,
        },
        {
            field: "brand",
            sortable: false,
            headerName: "Brand",
            width: 150,
            flex:1,
          },
        {
            field: "model",
            sortable: false,
            headerName: "Model",
            width: 210,
            flex:1
          },
          { field: "registration", sortable: false, headerName: "Registration", width: 210 ,flex:1},
          { field: "stockInDate", sortable: false, headerName: "StockInDate", width: 210,flex:1,
          valueFormatter: (params) => {
            const milis = parseInt(params.value);
            const valueFormatted = new Date(milis).toDateString();
            return `${valueFormatted}`;
          } },{ field: "stockOutDate", sortable: false, headerName: "StockOutDate", width: 210,flex:1,
          valueFormatter: (params) => {
            const milis = parseInt(params.value);
            const valueFormatted = new Date(milis).toDateString();
            return `${valueFormatted}`;
          } },
        {
          field: "totalStockDuration",
          sortable: false,
          headerName: "Duration (Days)",
          width: 210,
          flex: 1,
          valueGetter: (params) => {
            const stockInDate = params.row.stockInDate;
            const stockOutDate = params.row.stockOutDate;
        
            if (stockInDate && stockOutDate) {
              const stockInMillis = parseInt(stockInDate);
              const stockOutMillis = parseInt(stockOutDate);
              const totalDurationMillis = stockOutMillis - stockInMillis;
              const totalDurationDays = totalDurationMillis / (1000 * 60 * 60 * 24); // Convert milliseconds to days
              return Math.round(totalDurationDays); // Round to the nearest whole number of days
            } else {
              return "";
            }
          },
        },
        
        
      ];
      



      const [open, setOpen] = React.useState(false)

  const [stockHistory, setStockHistory] = React.useState([])
  const [isLoading, setisLoading] = React.useState(false)
  const [newStockHistory, setNewStockHistory] = React.useState({});
  const [newStockInDate, setNewStockInDate] = React.useState("");
  const [newStockOutDate, setNewStockOutDate] = React.useState("");





  const handleOpen = () => {
    setNewStockHistory([])
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
    setNewStockInDate("")
    setNewStockOutDate("")
    setNewStockHistory([])
  };



  function handleChange(evt) {
    const value = evt.target.value;
    setNewStockHistory({
      ...newStockHistory,
      [evt.target.name]: value,
      stockInDate: newStockInDate ? JSON.stringify(newStockInDate.ts) : "",
      stockOutDate: newStockOutDate ? JSON.stringify(newStockOutDate.ts) : "",
    });
  }

 
     async function handleDelete(Event,Value){

        if (window.confirm("You surely want to delete this Reason?")) {
          await deleteStockHistory(Value.row.uuid) 
        }      // await deleteUser(Value.row.uid) 
        else{
          await getAllStockInOutsHistory()
        }
     }


   const getAllStockInOutsHistory = async () => {
            setisLoading(true)
            const token = localStorage.getItem("accessToken");
            const config = {
              headers: {
                auth: token,
              },
            };
             axios
              .get("/stockInOutHistory", config)
              .then((response) => {
                setStockHistory(response.data.data);
                setisLoading(false)
              });
  };
  React.useEffect(() => {
    getAllStockInOutsHistory();
  }, []);

  const addnewStockHistory = async () => {
    setisLoading(true)
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .post("/stockInOutHistory", newStockHistory, config)
      .then((response) => {

        toast.success("Stock History Added Successfully!");
        setOpen(false)
        setNewStockInDate("")
        setNewStockOutDate("")
        setisLoading(false)
        getAllStockInOutsHistory()
      })
      .catch((err) => {
        if (err.response.data.error == "Stock History Already Exists") {
          toast.error("Stock History Already Exists");
          setisLoading(false)
        } else {
          toast.error("Stock History Cannot Be Added");
          setisLoading(false)
        }
      });
  };
 

  const deleteStockHistory = async (uuid) => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .delete(`/deleteStockHistory/${uuid}`,  config)
      .then((response) => {
        toast.success("Stock Deleted Successfully!");
        setOpen(false)
        getAllStockInOutsHistory()
      })
      .catch((err) => {
        if (err.response.data.error == "Stock Already Exists") {
          toast.error("Stock History Already Exists");
        } else {
          toast.error("Stock History Cannot Be Deleted");
        }
      }); 
  }

  return (
    <>
      <Box
        sx={{ width: "80%" }}
        p={2}
        height={"85vh"}
        ml={"18vw"}
        mt={0}
      >
        <div style={{ display: "flex", justifyContent: "space-between",alignItems:"baseline" }}>
        <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Manage Stock History</div>
<div>

          <Button
            variant="contained"
            sx={{ height: "40px", width: "10vw" }}
            onClick={handleOpen}
          >
            Add History
          </Button>
</div>
        </div>
        <StyledDataGrid
          rows={stockHistory}
          columns={columns}
          pageSize={50}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
        
          // initialState={{
          //   filter: {
          //     filterModel: {
          //       items: [],
          //       quickFilterValues: [filterSearchInput],
          //     },
          //   },
          // }}
          rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
          sx={{ height: "90vh", bgcolor: "white" }}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ color: "black", marginBottom: "10px" }}>
           Add Vehicle History
          </h2>

<p style={{color:"black"}}>Select Brand</p>
          <Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  label="Brand Name"
  onChange={handleChange}
  name="brand"
  sx={{width:"100%",color:"black"}}
>
  {carBrandNames.map((value, index) => (
    <MenuItem value={value} key={index}>
      {value}
    </MenuItem>
  ))}
</Select>


          {/* <TextField
            id="filled-basic"
            label="Brand"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="brand"
            onChange={handleChange}
          /> */}
          <TextField
            id="filled-basic"
            label="Model"
            variant="filled"
            sx={{ width: "100%", marginTop: "10px" }}
            name="model"
            onChange={handleChange}
          />



{/* <TextField
            id="filled-basic"
            label="StockInDate"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="stockInDate"
            onChange={handleChange}
          /> */}
 <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  "& .MuiPickersDay-daySelected": {
                    background: "none",
                    color: "inherit",
                  },
                }}
                label="Stock In Date"
                name="stockInDate"
                onChange={(newValue) => setNewStockInDate(newValue)}
              />
            </LocalizationProvider>


            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                sx={{
                  width: "100%",
                  marginTop: "10px",
                  "& .MuiPickersDay-daySelected": {
                    background: "none",
                    color: "inherit",
                  },
                }}
                label="Stock Out Date"
                name="stockOutDate"
                onChange={(newValue) => setNewStockOutDate(newValue)}
              />
            </LocalizationProvider>
            <TextField
            id="filled-basic"
            label="Registration"
            variant="filled"
            sx={{ width: "100%",marginTop: "10px",}}
            name="registration"
            onChange={handleChange}
          />


          <Box>
          <Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={addnewStockHistory}
              disabled={isLoading?true:false}
            >
              Add History
            </Button>
            
          </Box>
        </Box>
      </Modal>



    



      <ToastContainer />
    </>
  );
};
export default StockInOutHistory;
