import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar,GridToolbarQuickFilter,
  GridLogicOperator,
 } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";

const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  borderRadius:"10px",
    '& .wrap-text': {
      whiteSpace: 'normal',
    },

  
  border: 0,
  '& .MuiDataGrid-cell:focus': {
   outline:"none"
  },
  '& .MuiDataGrid-toolbarContainer':{
    gap:15,
    padding :"8px 8px 8px !important",
    background:"linear-gradient(to right, #434343 0%, black 100%) !important",
    borderRadius:"10px 10px 0px 0px !important",
  },
  '& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
    color:"white"
  },
  '& .css-1rtnrqa':{
    color:"white"
  },
  '& .css-68pk0f':{
    border: '1px solid white !important',
    borderRadius: '5px',
    padding:'2px 5px',
  },
  '& .css-zrqpq1':{
    color:'white'
  },
  '& .css-zrqpq1::after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
    borderBottom:"none"
  },
  '& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
  },
  '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
    border:"1px solid white !important",
    borderRadius:"5px",
    padding:"2px 5px",
    boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
  },
  "& .MuiDataGrid-cell:focus":{
    outline:"none"
  },
  "& .MuiDataGrid-cell:focus-within":{
    outline:"none"
  },
  "& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
    outline:"none",
  },
  "& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
    fontSize:"17px"
  }
  
 
}));


function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid ",
  boxShadow: 24,
  p: 4,
};



function VehicleModels(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <CarModels />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}
const CarModels = () => {

    // Datagrid Columns
    const columns = [
        {
          field: "col0",
          headerName: "Actions",
          width: 220,
          sortable: false,
          filterable: false,
          renderCell: (cellValues) => {
            return (
              <div style={{ display: "flex", gap: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    handleEdit(event, cellValues);
                    // alert("Edit Pressed");
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={(event) => {
                    handleDelete(event, cellValues);
                    // alert("Delete  Pressed");
                  }}
                >
                  Delete
                </Button>
              </div>
            );
          },
        },
        { field: "uid", sortable: false, headerName: "UID", width: 300,          sortable: false,
        filterable: false, },
        {
          field: "brandName",
          sortable: false,
          headerName: "Vehicle Brand",
          width: 260,
        },
        {
          field: "ModelName",
          sortable: false,
          headerName: "Vehicle Model",
          width: 270,
        },
        { field: "createdAt", sortable: false, headerName: "CreatedAt", width: 210,
        valueFormatter: (params) => {
          const valueFormatted = new Date(params.value).toDateString();
          return `${valueFormatted}`;
        }, },
        { field: "updatedAt", sortable: false, headerName: "UpdatedAt", width: 210 ,valueFormatter: (params) => {
          const valueFormatted = new Date(params.value).toDateString();
          return `${valueFormatted}`;
        },},
      ];
      





  const [existingVehicleModels, setexistingVehicleModels] = React.useState([]);
  //isUpdating State is used for checking if the modal open is for updating or adding a new one hence changing the button name 
  const [IsUpdating, setIsUpdating] = React.useState(false)
  const [isLoading, setisLoading] = React.useState(false)
  const [newModel, setNewModel] = React.useState({
    brand: "",
    modelname: "",
  });
  const [vehicleBrandName, setvehicleBrandName] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);








  const handleOpen = () => {
    setNewModel([])
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false);
    setIsUpdating(false)
  };

  const handleBrandChange = (event) => {
    const {
      target: { value },
    } = event;
    setvehicleBrandName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    handleChange(event);
  };


  function handleChange(evt) {
    const value = evt.target.value;
    setNewModel({
      ...newModel,
      [evt.target.name]: value,
    });
  }

  function handleEdit(Event,Value){

    setOpen(true)
    setNewModel({
        brand:Value.row.brandName,
        modelname:Value.row.ModelName,
        inputUUID:Value.row.uid
    })
    setIsUpdating(true)
  }

     async function handleDelete(Event,Value){
       
        if (window.confirm("You surely want to delete this Vehicle Model?")) {
          await deleteVehicleModel(Value.row.uid) 
        }      // await deleteVehicleModel(Value.row.uid) 
        else{
          await getAllVehicleModels()
        }
     }


   const getAllVehicleModels = async () => {
            setisLoading(true)
            const token = localStorage.getItem("accessToken");
            const config = {
              headers: {
                auth: token,
              },
            };
             axios
              .get("/vehiclemodels", config)
              .then((response) => {
                setexistingVehicleModels(response.data.data);
                setisLoading(false)
              });
  };
  React.useEffect(() => {
    getAllVehicleModels();
  }, []);

  const addNewVehicleModel = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .post("/addVehicleModel", newModel, config)
      .then((response) => {
        toast.success("Model Added Successfully!");
        setOpen(false)
        setvehicleBrandName([])
        getAllVehicleModels()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "Model Already Exists") {
          toast.error("Model Already Exists");
        } else {
          toast.error("Model Cannot Be Added");
        }
      });
  };
  const updateVehicleModel = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .put("/updateVehicleModel", newModel, config)
      .then((response) => {

        toast.success("Model Updated Successfully!");
        setOpen(false)
        setvehicleBrandName([])
        getAllVehicleModels()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "Model Already Exists") {
          toast.error("Model Already Exists");
        } else {
          toast.error("Model Cannot Be Updated");
        }
      }); 
  }

  const deleteVehicleModel = async (uid) => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };
    await axios
      .delete(`/deleteVehicleModel/${uid}`,  config)
      .then((response) => {
        toast.success("Model Deleted Successfully!");
        setOpen(false)
        setvehicleBrandName([])
        getAllVehicleModels()
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.error == "Model Already Exists") {
          toast.error("Model Already Exists");
        } else {
          toast.error("Model Cannot Be Deleted");
        }
      }); 
  }
  return (
    <>
      <Box
        sx={{ width: "80%" }}
        p={2}
        height={"85vh"}
        ml={"18vw"}
        mt={0}
      >
        <div style={{ display: "flex", justifyContent: "space-between",alignItems:"baseline" }}>
        <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Manage Models</div>

          <Button
            variant="contained"
            sx={{ height: "5vh", width: "10vw"}}
            
            onClick={handleOpen}
          >
            Add Model
          </Button>
        </div>
        <StyledDataGrid
          rows={existingVehicleModels}
          columns={columns}
          pageSize={50}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
          sortModel={[
            {
              field: 'createdAt', // Replace 'timestamp' with the actual field you want to sort by
              sort: 'desc',       // 'desc' for descending order, 'asc' for ascending order
            }
          ]}
          rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
          sx={{ height: "90vh", bgcolor: "white" }}
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2 style={{ color: "black", marginBottom: "10px" }}>
            Add Model
          </h2>
          <FormControl sx={{ width: "100%", bgcolor: "#F2F8FC" }}>
            <InputLabel variant="filled" id="demo-multiple-chip-label">
              Vehicle Brands
            </InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              sx={{ padding: "10px 0 0px 0" }}
              value={newModel.brand||vehicleBrandName||""}
              onChange={handleBrandChange}
              name="brand"
              input={<OutlinedInput id="select-multiple-chip" />}
              MenuProps={MenuProps}
            >
              {carBrandNames.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="filled-basic"
            label="Model Name"
            variant="filled"
            sx={{ width: "100%", marginTop: "20px" }}
            name="modelname"
            onChange={handleChange}
            value={newModel.modelname||""}
          />
          <Box>
          {IsUpdating?<><Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={updateVehicleModel}
            >
              Update Model
            </Button></>:<><Button
              variant="contained"
              sx={{ width: "100%", marginTop: "20px" }}
              onClick={addNewVehicleModel}
            >
              Add Model
            </Button></>}
            
          </Box>
        </Box>
      </Modal>






      <ToastContainer />
    </>
  );
};
export default VehicleModels;
