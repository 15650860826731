import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashNav from "../Dashboard/DashNav.js";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Sidebar from "../Dashboard/Sidebar.js";
import Feed from "../Dashboard/Feed";
import { Box } from "@mui/system";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { DataGrid, GridToolbar,GridToolbarQuickFilter,
  GridLogicOperator,
  GridOverlay,
 } from "@mui/x-data-grid";
 import CallIcon from '@mui/icons-material/Call';
 import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
 import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import AddchartIcon from '@mui/icons-material/Addchart';
import CallReceivedIcon from '@mui/icons-material/CallReceived';




const carBrandNames = require("./VehicleBrands.js");

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  
  '& .wrap-text': {
    whiteSpace: 'normal',
  },


border: 0,
'& .MuiDataGrid-cell:focus': {
 outline:"none"
},
'& .css-1rtnrqa':{
  color:"white"
},
'& .css-68pk0f':{
  border: '1px solid white !important',
  borderRadius: '5px',
  padding:'2px 5px',
},
'& .css-zrqpq1':{
  color:'white'
},
'& .css-zrqpq1::after':{
  borderBottom:"none"
},
'& .css-v4u5dn-MuiInputBase-root-MuiInput-root:after':{
  borderBottom:"none"
},
'& .css-1j9kmqg-MuiDataGrid-toolbarContainer':{
  gap:15,
  padding :"8px 8px 8px",
  background:"linear-gradient(to right, #434343 0%, black 100%)",
},
'& .css-1w6wuh5':{
  padding: '8px !important',
    background: 'linear-gradient(to right, rgb(67, 67, 67) 0%, black 100%) !important',
    borderRadius: '10px 10px 0px 0px !important'
},
'& .css-1knaqv7-MuiButtonBase-root-MuiButton-root':{
  color:"white"
},
'& .css-v4u5dn-MuiInputBase-root-MuiInput-root':{
color:"white"
},
'& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter':{
  border:"1px solid white !important",
  borderRadius:"5px",
  padding:"2px 5px",
  boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"
},
"& .MuiDataGrid-cell:focus":{
  outline:"none"
},
"& .MuiDataGrid-cell:focus-within":{
  outline:"none"
},
"& .MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within":{
  outline:"none",
},
"& .css-1jbbcbn-MuiDataGrid-columnHeaderTitle":{
  fontSize:"17px"
}


}));

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')
        }
      />
    </Box>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "white",
  boxShadow: 24,
  color:"Black !important",
  borderRadius:"10px",
  padding:"0px 0px 10px 0px"
};



function CallLogs(props) {
  const navigate = useNavigate();

  return (
    <>
      {/* <DashNav /> */}

      <Stack direction="row" justifyContent="space-between">
        <Sidebar />
        <CallLogsTable />
        {/* <Leads/> */}
      </Stack>
    </>
  );
}


// function CustomLoadingOverlay() {
//   return (
//     <GridOverlay>
//       {/* <Skeleton variant="rect" width="100%" height="100%" /> */}
//     </GridOverlay>
//   );
// }

const CallLogsTable = () => {

    const [callLogs, setcallLogs] = React.useState([])
    const [isLoading, setisLoading] = React.useState(false)
    const [TotalCallRecieved, setTotalCallRecieved] = React.useState(0)
    const [TotalCallMissed, setTotalCallMissed] = React.useState(0)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
      setOpen(false);
      setSalesExecutiveName('')
      setSalesExecutiveCallsReport('')
      setSalesExecutiveCallsMissedReport('')
    };
    const [salesExecutiveName, setSalesExecutiveName] = React.useState('');
    const [salesExecutiveCallReport, setSalesExecutiveCallsReport] = React.useState('');
    const [salesExecutiveCallMissedReport, setSalesExecutiveCallsMissedReport] = React.useState('');

  const handleCallSalesExecutiveReport = (event) => {
    setSalesExecutiveName(event.target.value);
  };
  const renderCell = (params) => {
    const { field, value } = params;
    if (isLoading) {
      return <Skeleton variant="rec" width={50} height={20} />;
    } else {
      return value;
    }
  };
  // Datagrid Columns 
  const columns = [
    {
      field: 'UUID',
      sortable: false,
      headerName: 'ID',
      flex: 1, // Use flex to make this column responsive
    },
    {
      field: 'callStatus',
      sortable: false,
      headerName: 'STATUS',
      flex: 1, // Use flex to make this column responsive
    },
    {
      field: 'callerId',
      sortable: false,
      headerName: 'CALLER ID',
      flex: 1, // Use flex to make this column responsive
    },
    {
      field: 'operatorName',
      sortable: false,
      headerName: 'SALES EXECUTIVE',
      flex: 1, // Use flex to make this column responsive
    },
    {
      field: 'ivrsCallPath',
      sortable: false,
      headerName: 'CALL RECORDING',
      flex: 2, // Use flex to make this column responsive, give it more space
      renderCell: (params) => (
        <audio controls style={{ width: '100%', height: '70%' }}>
          <source src={params.value} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      ),
    },
    {
      field: 'callDuration',
      sortable: false,
      headerName: 'DURATION',
      flex: 1, // Use flex to make this column responsive
    },
    {
      field: 'dateTime',
      sortable: false,
      headerName: 'DATE TIME',
      flex: 1, // Use flex to make this column responsive
    },
  ];
      
      const getCallLogs = async () => {

        const roleID = localStorage.getItem('roleId');
        if(roleID!="af7c1fe6-d669-414e-b066-e9733f0de7a8"){ // Secret ID for accessing data
          const token = localStorage.getItem("accessToken");
          var salesExecutive = localStorage.getItem('fullName');
        
          const config = {
            headers: {
              auth: token,
            },
          };
           axios
            .get(`/getCallLogs/${salesExecutive}`, config)
            .then((response) => {
                setcallLogs(response.data.data);
                setisLoading(false)
        
            });
        }else{


        setisLoading(true)
        const token = localStorage.getItem("accessToken");
  const config = {
    headers: {
      auth: token,
    },
  };
         axios
          .get("https://flywheelcars.cloud/getCallLogs",config)
          .then((response) => {
            setcallLogs(response.data.data);
            setisLoading(false)

          });
        }
};

const getTodayTotalCallRecieved =async ()=>{
  const roleID = localStorage.getItem('roleId');
if(roleID!="af7c1fe6-d669-414e-b066-e9733f0de7a8"){ // Secrect ID for accessing data
  const token = localStorage.getItem("accessToken");
  var salesExecutive = localStorage.getItem('fullName');
  const config = {
    headers: {
      auth: token,
    },
  };
   axios
    .get(`/getCallsCountTodaySalesExecutive/${salesExecutive}`, config)
    .then((response) => {

      setTotalCallRecieved(response.data.count);

    });
}else{
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: {
      auth: token,
    },
  };
  await axios
    .get("/getCallsCountToday", config)
    .then((response) => {
      setTotalCallRecieved(response.data.count);
    });
}
}



const getTodayTotalCallMissed =async ()=>{
  const roleID = localStorage.getItem('roleId');
if(roleID!="af7c1fe6-d669-414e-b066-e9733f0de7a8"){ // Secrect ID for accessing data
  const token = localStorage.getItem("accessToken");
  var salesExecutive = localStorage.getItem('fullName');
  const config = {
    headers: {
      auth: token,
    },
  };
   axios
    .get(`/getCallsCountMissedTodaySalesExecutive/${salesExecutive}`, config)
    .then((response) => {

      setTotalCallMissed(response.data.count);

    });
}else{
  const token = localStorage.getItem("accessToken");
  const config = {
    headers: {
      auth: token,
    },
  };
  await axios
    .get("/getCallsCountMissedToday", config)
    .then((response) => {
      setTotalCallMissed(response.data.count);
    });
}
}

const getSalesExecutiveReportFunc = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        auth: token,
      },
    };

    const callsCountPromise = axios.get(`/getCallsCountTodaySalesExecutive/${salesExecutiveName}`, config);
    const callsMissedCountPromise = axios.get(`/getCallsCountMissedTodaySalesExecutive/${salesExecutiveName}`, config);

    const [callsResponse, callsMissedResponse] = await Promise.all([callsCountPromise, callsMissedCountPromise]);

    const callsCount = callsResponse.data.count;
    const callsMissedCount = callsMissedResponse.data.count;

    setSalesExecutiveCallsReport(callsCount);
    setSalesExecutiveCallsMissedReport(callsMissedCount);
  } catch (error) {
    // Handle error here
    console.error("Error fetching data:", error);
  }
};


      React.useEffect(() => {
        getCallLogs();
        getTodayTotalCallRecieved();
        getTodayTotalCallMissed();
      }, []);




  return (
    <>
      <Box
        sx={{ width: "80%" }}
        p={2}
        height={"100vh"}
        ml={"18vw"}
        mt={0}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{fontSize:"45px",color:"black",fontWeight:"bold",fontFamily:"Kamerik",margin:"15px 0px"}} >Calls History</div>


        </div>
        <div>
<Stack direction="row" spacing={2} justifyContent="space-between">
              <div style={{ display: "flex", flexDirection: "row", gap: 40 }}>
                <div style={{ display: "flex", gap: 10, color: "#0F1319" }}>
                  <CallIcon sx={{ color: "#6d9a13" }} />
                  Total Calls Recieved: {TotalCallRecieved}
                </div>
                <div style={{ display: "flex", gap: 10, color: "#0F1319" }}>
                  <PhoneMissedIcon sx={{ color: "#8B008B" }} />
                  Total Calls Missed: {TotalCallMissed}
                </div>
              </div>

              <div>
                {localStorage.getItem('roleId')!="af7c1fe6-d669-414e-b066-e9733f0de7a8"?'':<><Button
                  variant="contained"
                  color="warning"
                  onClick={handleOpen}
                >
                  Advance Report
                </Button></>}
                
              </div>
            </Stack>

</div>
        <StyledDataGrid
          rows={callLogs}
          columns={columns}
          pageSize={50}
          disableSelectionOnClick
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 }
            },
          }}
        
          sortModel={[
            {
              field: 'dateTime', // Replace 'timestamp' with the actual field you want to sort by
              sort: 'desc',       // 'desc' for descending order, 'asc' for ascending order
            } 
          ]}
          rowsPerPageOptions={[10, 25, 50, 100]} // Include 100 as an option
          sx={{ height: "92%", bgcolor: "white" }}
        />
      </Box>



 
<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style} style={{color:"black"}}>
        <div style={{
          background:"linear-gradient(111.5deg, rgb(20, 100, 196) 0.4%, rgb(33, 152, 214) 100.2%)",
          color:"white",padding:"10px 10px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px"}}>
        <Typography id="modal-modal-title" variant="h6" component="h2"> 
            IVR Report Today
          </Typography>
        </div>
          <div style={{
            margin:"10px",
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            padding:"10px",
            
          }}>
           <div style={{marginBottom:"5px",display:"flex",alignItems:"center"}}>
           <span
            style={{background:"linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))",
            color:"white",
            padding:"10px",
            borderRadius:"10px",
            width:"50%",
            marginRight:"10px",
            display:"flex",
            alignItems:"center"
          }}
            >                  <CallReceivedIcon sx={{ color: "white" }} />
            Total Calls Today: {TotalCallRecieved||0}</span>

            <span
            style={{background:"linear-gradient(108.4deg, rgb(253, 44, 56) 3.3%, rgb(176, 2, 12) 98.4%)",
            color:"white",
            padding:"10px",
            borderRadius:"10px",
            width:"50%",
            display:"flex",
            alignItems:"center"            
          }}
            >                  <PhoneMissedIcon sx={{ color: "white" }} />
            Total Missed Today: {TotalCallMissed||0}</span>
           </div>
            
          </div>

          <div style={{
            margin:"10px",
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            padding:"10px"
          }}>
             <FormControl fullWidth>
              <p style={{ background:"linear-gradient(to right,#434343 0%,black 100%)",
          color:"white",padding:"10px 10px"}}>Calls Reports Sales Executive</p>
       <div style={{display:"flex",width:"100%",marginTop:"10px"}} >
         <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={salesExecutiveName}
          label="Age"
          onChange={handleCallSalesExecutiveReport}
          sx={{flexGrow:"1",marginRight:"1vw"}}
        >
          <MenuItem value={"Riyaz Ahmed"}>Riyaz Ahmed</MenuItem>
          <MenuItem value={"Ahsan Ali"}>Ahsan Ali</MenuItem>
          <MenuItem value={"Saad Ahmed"}>Saad Ahmed</MenuItem>
          <MenuItem value={"Saif Ahmed"}>Saif Ahmed</MenuItem>

        </Select>
        <Button variant="contained" onClick={getSalesExecutiveReportFunc}>Get Data</Button></div>
      </FormControl>
            <div style={{marginTop:"10px"}}>
              {salesExecutiveCallReport?<>
              {salesExecutiveName}
              <div>Total Calls Recieved : {salesExecutiveCallReport||0}</div>
              <div>Total Calls Missed : {salesExecutiveCallMissedReport||0}</div>

              </>:""}
              
              
            </div>
          </div>
        </Box>
      </Modal>


      <ToastContainer />
    </>
  );
};
export default CallLogs;
